import { parseColor } from "../../../utils/paint/Color";
import { generateTextBoundsShape } from "./shapeGenerators/textBoundsShapeGenerator";
import { parseOverprints } from "../../helpers/Paint";
import { parseMM } from "../../../utils/unitHelper";
export function generateBackgroundShape({ lineInfos, position, cdifSpec, transform, colorPalette, }) {
    var _a, _b, _c;
    let backgroundShape;
    switch (cdifSpec.shape.type) {
        case "textBounds":
            backgroundShape = generateTextBoundsShape({ lineInfos, position, cdifSpec: cdifSpec.shape, transform });
            break;
        default:
            throw new Error(`shape type '${cdifSpec.shape.type}' not supported for text backgrounds`);
    }
    if (backgroundShape) {
        backgroundShape.fill = parseColor(cdifSpec.color, colorPalette, cdifSpec.opacity);
        backgroundShape.overprints = parseOverprints(cdifSpec.overprints, colorPalette);
        if (cdifSpec.stroke) {
            backgroundShape.stroke = {
                width: parseMM(cdifSpec.stroke.thickness),
                lineJoin: (_a = cdifSpec.stroke.lineJoin) !== null && _a !== void 0 ? _a : "round",
                lineCap: (_b = cdifSpec.stroke.lineCap) !== null && _b !== void 0 ? _b : "round",
                dashArray: (_c = cdifSpec.stroke.dashPattern) === null || _c === void 0 ? void 0 : _c.segments.map((segment) => parseMM(segment.length)),
            };
            if (cdifSpec.stroke.color) {
                backgroundShape.stroke.fill = parseColor(cdifSpec.stroke.color, colorPalette, cdifSpec.opacity);
            }
            if (cdifSpec.stroke.overprints) {
                backgroundShape.stroke.overprints = parseOverprints(cdifSpec.overprints, colorPalette);
            }
        }
    }
    return backgroundShape;
}
