var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { boundingBoxFromPath } from "../../utils/boundingBox";
import { Matrix } from "../../utils/math/matrix";
import { parsePathData } from "../../utils/parsePathData";
import { parseMM, toMM, toRadians } from "../../utils/unitHelper";
import { buildItemTransform, transformBoundingBox } from "./Transform";
import { buildInteractiveRequest, interpretTextArea } from "../text/RTextTranslation";
import { initRText } from "../text/initRText";
import svgpath from "svgpath";
/**
 *
 * @param item The clippable item
 * @param parentBounds bounding box of the clippable item if the origin = item, else the item's parent bounds.
 * @param parentTransform transform of the clippable item, contains transforms such as that from rotationAngle.
 * @returns
 */
export function getClip(item, parentBounds, parentTransform, fontRepositoryUrl) {
    return __awaiter(this, void 0, void 0, function* () {
        var _a, _b, _c, _d;
        if (item.clipping === undefined) {
            return undefined;
        }
        const clipping = item.clipping;
        const position = clipping.position;
        const isRelativeToItem = item.clipping.specification.origin === "item";
        const { svgPath, unit } = yield getClipPaths(item.clipping, fontRepositoryUrl);
        const unitScale = toMM(1, unit);
        const boundingBox = boundingBoxFromPath({ path: svgPath });
        const boundingBoxCenter = { x: boundingBox.left + boundingBox.width / 2, y: boundingBox.top + boundingBox.height / 2 };
        const scaleX = (_a = position === null || position === void 0 ? void 0 : position.scaleX) !== null && _a !== void 0 ? _a : 1;
        const scaleY = (_b = position === null || position === void 0 ? void 0 : position.scaleY) !== null && _b !== void 0 ? _b : 1;
        const matrix = Matrix.multiply(Matrix.multiply(Matrix.translate(-boundingBoxCenter.x, -boundingBoxCenter.y), new Matrix(unitScale * scaleX, 0, 0, unitScale * scaleY, 0, 0)), Matrix.translate(boundingBoxCenter.x + parseMM((_c = position === null || position === void 0 ? void 0 : position.x) !== null && _c !== void 0 ? _c : "0mm"), boundingBoxCenter.y + parseMM((_d = position === null || position === void 0 ? void 0 : position.y) !== null && _d !== void 0 ? _d : "0mm")));
        let transform = Matrix.multiply(matrix, buildItemTransform({ transforms: clipping.specification.transforms, bounds: transformBoundingBox(boundingBox, matrix), container: parentBounds }));
        // Only apply the clippable item's transforms if this clip is relative to the item
        if (parentTransform && isRelativeToItem) {
            transform = Matrix.multiply(transform, parentTransform);
        }
        // translate by the clippable item's parent bounds' x/y if the origin = panel, since technically the clip is relative to the
        // parent context of the clippable item.
        if (!isRelativeToItem) {
            transform = Matrix.multiply(transform, Matrix.translate(parentBounds.left, parentBounds.top));
        }
        return {
            boundingBox: transformBoundingBox(boundingBox, transform),
            transform,
            path: svgPath.toString(),
            isRelativeToItem: isRelativeToItem,
            usesViewbox: false,
        };
    });
}
export function getClipWithViewbox(clip, position, parentTransform, fontRepositoryUrl) {
    return __awaiter(this, void 0, void 0, function* () {
        const { svgPath } = yield getClipPaths(clip, fontRepositoryUrl);
        const boundingBox = boundingBoxFromPath({ path: svgPath });
        let transform = Matrix.identity();
        transform = Matrix.multiply(transform, getClipViewboxTransform(position, clip.viewBox));
        transform = Matrix.multiply(transform, parentTransform);
        return {
            boundingBox: transformBoundingBox(boundingBox, transform),
            transform,
            path: svgPath.toString(),
            isRelativeToItem: true,
            usesViewbox: true,
        };
    });
}
export function getClipViewboxTransform(position, viewBox) {
    const viewBoxNum = viewBox.split(" ").map(parseFloat);
    let transform = Matrix.identity();
    transform = Matrix.multiply(transform, Matrix.translate(-viewBoxNum[0], -viewBoxNum[1]));
    transform = Matrix.multiply(transform, Matrix.scale(position.width / viewBoxNum[2], position.height / viewBoxNum[3]));
    transform = Matrix.multiply(transform, Matrix.translate(position.left, position.top));
    return transform;
}
export function getClipPaths(clip, fontRepositoryUrl) {
    return __awaiter(this, void 0, void 0, function* () {
        var _a;
        let unit = "";
        let svgPath;
        if (clip.specification.type === "svgPathData") {
            // This should never default to mm unless there is a viewbox defined on the clip, which is currently not a supported feature
            unit = (_a = clip.specification.unit) !== null && _a !== void 0 ? _a : "mm";
            [svgPath] = parsePathData({
                pathData: clip.specification.data,
                pixelSize: 1,
                svgPathDataUnit: unit,
                closeBehavior: "always",
                scaleX: 1,
                scaleY: 1,
            });
        }
        else {
            if (!fontRepositoryUrl) {
                throw Error("A fontRepositoryUrl is required for clipping with text.");
            }
            const textAreaPaths = yield getTextAreaClipPaths(clip.specification.textArea, fontRepositoryUrl);
            unit = "mm";
            [svgPath] = parsePathData({
                pathData: textAreaPaths,
                pixelSize: 1,
                svgPathDataUnit: unit,
                closeBehavior: "always",
                scaleX: 1,
                scaleY: 1,
            });
        }
        return { svgPath, unit };
    });
}
/**
 * Gets the outlines of the text area. Note this does not support text along a path yet, since there is no need
 * @param textArea
 * @param fontRepositoryUrl
 * @returns The concatenated SVG paths as a single string
 */
function getTextAreaClipPaths(textArea, fontRepositoryUrl) {
    return __awaiter(this, void 0, void 0, function* () {
        var _a, _b;
        // Applying all the functionality around list handling
        const rtext = yield initRText(false);
        const { textBlocks } = interpretTextArea(textArea, "print")
            // Remove the bew line characters in case of text along a path, and then remove blocks if empty.
            .reduce((outputItc, itc) => {
            outputItc.isNewParagraphTextBlock.push(itc.isNewParagraphTextBlock);
            outputItc.listOrdinals.push(itc.listOrdinal);
            outputItc.textBlocks.push(itc.textBlock);
            outputItc.textFields.push(itc.textField);
            return outputItc;
        }, { listOrdinals: [], textFields: [], textBlocks: [], isNewParagraphTextBlock: [] });
        const positionX = parseMM(textArea.position.x);
        const positionY = parseMM(textArea.position.y);
        const rtextRequest = buildInteractiveRequest(textArea, undefined, textBlocks, "print", fontRepositoryUrl);
        const rtextResponse = yield rtext.process(rtextRequest);
        const resultCollection = rtextResponse.to_obj();
        if (!resultCollection.results || resultCollection.results.length !== 1) {
            throw Error("Expected 1 result from rtext");
        }
        const resultFonts = resultCollection.fonts;
        const result = resultCollection.results[0];
        let totalPath = "";
        for (const glyphRun of (_a = result.glyphRuns) !== null && _a !== void 0 ? _a : []) {
            const font = resultFonts[(_b = glyphRun.fontIndex) !== null && _b !== void 0 ? _b : 0];
            const { fontSize, baseline } = glyphRun;
            for (const glyph of glyphRun.glyphs) {
                const { xOffset: offsetX, yOffset: offsetY } = glyph;
                const fontScalar = fontSize / font.unitsPerEm;
                let transform;
                if (glyphRun.orientation === "vertical") {
                    // Apply baseline to X instead of Y
                    transform = new Matrix(fontScalar, 0, 0, -fontScalar, (offsetX !== null && offsetX !== void 0 ? offsetX : 0) + positionX + baseline, (offsetY !== null && offsetY !== void 0 ? offsetY : 0) + positionY);
                }
                else {
                    transform = new Matrix(fontScalar, 0, 0, -fontScalar, (offsetX !== null && offsetX !== void 0 ? offsetX : 0) + positionX, (offsetY !== null && offsetY !== void 0 ? offsetY : 0) + positionY + baseline);
                }
                transform = Matrix.multiply(Matrix.rotation(toRadians(-glyphRun.glyphPivoting)), transform);
                let glyphPaths = font.glyphs[glyph.index].paths;
                glyphPaths = svgpath(glyphPaths).matrix([transform.a, transform.b, transform.c, transform.d, transform.x, transform.y]).toString();
                totalPath += glyphPaths;
            }
        }
        return totalPath;
    });
}
