import { parseMM } from "../../utils/unitHelper";
import { createChildFontInformation, getListOrdinal } from "./ListOrdinals";
import { cloneDeep } from "lodash";
export const NEW_LINE = "\n";
export const CARRIAGE_RETURN = "\r";
export const BASE_INDENT_MULTIPLIER = 1.5;
export function interpretTextArea(textArea, flavor) {
    var _a;
    const interpretedContent = [];
    let previousType = undefined;
    const normalizedTextArea = normalizeTextArea(textArea);
    for (const textContent of normalizedTextArea.content) {
        const parentFont = {
            family: textContent.fontFamily,
            style: textContent.fontStyle,
            size: textContent.fontSize,
            color: textContent.color,
            stroke: textContent.stroke,
            decorations: textContent.decorations,
        };
        const contentType = (_a = textContent.type) !== null && _a !== void 0 ? _a : "inline";
        if (previousType !== undefined && interpretedContent.length > 0) {
            const previousInterpretedContent = interpretedContent[interpretedContent.length - 1];
            // There must be a new line between lists and inline content, or pairs of lists
            if (previousType === "inline" && contentType === "list") {
                previousInterpretedContent.textBlock.content += NEW_LINE;
            }
            else if (previousType === "list" && contentType === "inline") {
                previousInterpretedContent.textBlock.content += NEW_LINE;
            }
            else if (previousType === "list" && contentType === "list") {
                previousInterpretedContent.textBlock.content += NEW_LINE;
            }
        }
        switch (contentType) {
            case "inline": {
                const textField = textContent;
                const casedTextField = Object.assign(Object.assign({}, textField), { content: applyCasing(textField.content, textField.casing) });
                interpretedContent.push({
                    listOrdinal: false,
                    isNewParagraphTextBlock: false,
                    textField: Object.assign(Object.assign({}, casedTextField), { type: "inline" }),
                    textBlock: buildTextBlockRequest(casedTextField, flavor, textArea.textPath !== undefined),
                });
                previousType = "inline";
                break;
            }
            case "list": {
                if (textContent.content === undefined) {
                    throw Error("Lists must contain text content!");
                }
                if (typeof textContent.content === "string") {
                    throw Error("Lists cannot contain string content, only list content is supported!");
                }
                if (textArea.textPath === undefined) {
                    interpretedContent.push(...interpretList(textContent.content, parentFont, flavor));
                }
                else {
                    interpretedContent.push(...interpretListForTextPath(textContent.content, parentFont, flavor));
                }
                previousType = "list";
                break;
            }
            default:
                // eslint-disable-next-line
                // @ts-ignore
                throw Error(`Text content has invalid type: ${textContent.type}!`);
        }
    }
    return interpretedContent;
}
export function buildInteractiveRequest(textArea, pieceWisePath, textBlocks, fontFlavor, fontRepositoryUrl, textOptions) {
    var _a, _b;
    const { horizontalAlignment, verticalAlignment, blockFlowDirection, resizingOptions } = textArea;
    let { textOrientation, blockProgressionDirection } = translateFlowDirection(blockFlowDirection); // eslint-disable-line
    let engineWidth;
    let engineHeight;
    let paragraphAlignment = "near";
    let textAlignment = translateTextAlignment(horizontalAlignment);
    const resizing = translateResizeOptions(resizingOptions, textArea);
    let preventWrapping = (resizingOptions === null || resizingOptions === void 0 ? void 0 : resizingOptions.wrapping) === "prevent";
    if (pieceWisePath !== undefined) {
        if (textOrientation !== "horizontal") {
            throw new Error("Vertical text alignment not supported for text along a path!");
        }
        engineWidth = pieceWisePath.length;
        blockProgressionDirection = "forward";
        // werapping is disabled for text along a path
        preventWrapping = true;
        if (((_a = textArea.textPath) === null || _a === void 0 ? void 0 : _a.startOffset) !== undefined) {
            textAlignment = "leading";
            engineWidth = pieceWisePath.length * (1.0 - textArea.textPath.startOffset);
        }
    }
    else {
        engineWidth = parseTextDimension(textArea.position.width);
        engineHeight = parseTextDimension(textArea.position.height);
        paragraphAlignment = translateParagraphAlignment(verticalAlignment);
    }
    const rTextArea = {
        width: engineWidth,
        height: engineHeight,
        textAlignment: textAlignment,
        paragraphAlignment: paragraphAlignment,
        textOrientation,
        blockProgressionDirection,
        fontRepositoryUrl: fontRepositoryUrl,
        fontFlavor,
        textBlocks,
        resizing: resizing,
        textJustification: (_b = textArea.textJustification) !== null && _b !== void 0 ? _b : "none",
        textWrapping: preventWrapping ? "none" : "normal",
    };
    const request = {
        textArea: rTextArea,
        requestGlyphRuns: true,
        requestMeasurements: true,
        requestOutlines: "true",
        requestCaretPositions: true,
        requestLineInfo: true,
        requestGlyphBounds: true,
        requestTextBoundaries: textOptions === null || textOptions === void 0 ? void 0 : textOptions.requestTextBoundaries,
    };
    return {
        version: 1,
        requests: [request],
        requestFontUrls: false,
        requestFontMetrics: true,
    };
}
export function translateResizeOptions(options, textArea) {
    var _a, _b, _c;
    if (options === undefined) {
        return null;
    }
    const { minFontSize, maxFontSize } = getMinAndMaxFontSizes(textArea);
    let minimumFontSize = 0;
    let maximumFontSize = null;
    if (options.fit === "expandToFill") {
        minimumFontSize = minFontSize === Number.MAX_VALUE ? 0 : minFontSize;
    }
    else if (options.fit === "shrinkToFit") {
        maximumFontSize = maxFontSize === Number.MIN_VALUE ? null : maxFontSize;
    }
    const resizing = {
        boundsType: ((_a = options.bounds) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === "blackbox" ? "blackBox" : "text",
        minimumFontSize,
        maximumFontSize,
    };
    const ruleMinFontSize = (_b = options.rules) === null || _b === void 0 ? void 0 : _b.minimumFontSize;
    const ruleMaxFontSize = (_c = options.rules) === null || _c === void 0 ? void 0 : _c.maximumFontSize;
    if (ruleMinFontSize !== undefined || ruleMaxFontSize !== undefined) {
        resizing.minimumFontSize = ruleMinFontSize !== undefined ? parseMM(ruleMinFontSize !== null && ruleMinFontSize !== void 0 ? ruleMinFontSize : "0pt") : 0;
        resizing.maximumFontSize = ruleMaxFontSize !== undefined ? parseMM(ruleMaxFontSize !== null && ruleMaxFontSize !== void 0 ? ruleMaxFontSize : "0pt") : null;
    }
    return resizing;
}
export function translateFontStyle(style) {
    if (style === undefined) {
        return "normal";
    }
    const normalizedStyle = style.toLowerCase();
    const italic = normalizedStyle.includes("italic");
    const bold = normalizedStyle.includes("bold");
    const strikeout = normalizedStyle.includes("strikeout");
    const underline = normalizedStyle.includes("underline");
    const normal = normalizedStyle.includes("normal");
    if (italic && bold) {
        return "bold,italic";
    }
    if (bold) {
        return "bold";
    }
    if (italic) {
        return "italic";
    }
    // Default to normal if only a strike out underline is defined
    if (strikeout || underline) {
        return "normal";
    }
    if (normal) {
        return "normal";
    }
    throw Error(`Could not parse font style: ${style}!`);
}
export function translateTextAlignment(alignment) {
    switch (alignment === null || alignment === void 0 ? void 0 : alignment.toLowerCase()) {
        case "left":
            return "leading";
        case "right":
            return "trailing";
        case "center":
            return "center";
        default:
            return "center";
    }
}
export function translateParagraphAlignment(alignment) {
    switch (alignment === null || alignment === void 0 ? void 0 : alignment.toLowerCase()) {
        case "top":
            return "near";
        case "middle":
            return "center";
        case "bottom":
            return "far";
        default:
            return "center";
    }
}
export function translateFlowDirection(direction) {
    switch (direction === null || direction === void 0 ? void 0 : direction.toLocaleLowerCase()) {
        case "horizontal-tb":
            return { textOrientation: "horizontal", blockProgressionDirection: "forward" };
        case "vertical-lr":
            return { textOrientation: "vertical", blockProgressionDirection: "forward" };
        case "vertical-rl":
            return { textOrientation: "vertical", blockProgressionDirection: "reverse" };
        default:
            return { textOrientation: "horizontal", blockProgressionDirection: "forward" };
    }
}
export function containsUrl(input) {
    if (input && input.startsWith("http")) {
        return true;
    }
    return false;
}
function applyCasing(input, casing) {
    // TODO update cimdoctypes enum for casing to be camel case
    switch (casing === null || casing === void 0 ? void 0 : casing.toLowerCase()) {
        default:
        case "default":
            return input;
        case "lowercase":
            return input.toLowerCase();
        case "uppercase":
            return input.toUpperCase();
    }
}
// Parses a dimension of the text area. Zero should be treated as undefined for the text engine.
function parseTextDimension(dimension) {
    return parseMM(dimension) || undefined;
}
function normalizeTextArea(textArea) {
    const textAreaWithContent = textArea;
    // If the text area doesn't have content, check if its a text field
    if (!textAreaWithContent.content) {
        const textAreaWithFields = textArea;
        if (!textAreaWithFields.textFields) {
            throw new Error("A text area must have content or fields!");
        }
        return {
            id: textArea.id,
            position: textArea.position,
            horizontalAlignment: textArea.horizontalAlignment,
            verticalAlignment: textArea.verticalAlignment,
            curveAlignment: textArea.curveAlignment,
            blockFlowDirection: textArea.blockFlowDirection,
            textOrientation: textArea.textOrientation,
            rotationAngle: textArea.rotationAngle,
            viewBox: textArea.viewBox,
            curves: textArea.curves,
            glyphRunOverrides: textArea.glyphRunOverrides,
            resizingOptions: textArea.resizingOptions,
            metadata: textArea.metadata,
            content: textAreaWithFields.textFields,
        };
    }
    return textAreaWithContent;
}
function initializeOrdinalsPerDepth(listContents) {
    let maxDepth = 0;
    for (const listContent of listContents) {
        if (listContent.depth === undefined) {
            maxDepth = Math.max(maxDepth, 1);
        }
        else {
            maxDepth = Math.max(maxDepth, listContent.depth);
        }
    }
    const ordinalsPerDepth = [];
    for (let i = 0; i < maxDepth; i++) {
        ordinalsPerDepth.push(0);
    }
    return ordinalsPerDepth;
}
function buildTextBlockRequest(textField, flavor, path, indent) {
    const { fontFamily, fontSize, lineHeight, letterspacing } = textField;
    const openTypeFeatures = {};
    if (letterspacing !== undefined || path) {
        openTypeFeatures.liga = 0;
    }
    const isUrl = containsUrl(fontFamily);
    const request = {
        fontSize: parseMM(fontSize),
        content: Array.isArray(textField.content) ? textField.content.join("") : textField.content,
        whitespaceStripping: "none",
        lineHeight,
        letterSpacing: letterspacing,
        openTypeFeatures,
        fontReferences: [
            {
                url: isUrl ? fontFamily : undefined,
                fontFamily: !isUrl ? fontFamily : undefined,
                fontStyle: !isUrl ? translateFontStyle(textField.fontStyle) : undefined,
                fontFlavor: flavor,
            },
        ],
    };
    // Add an optional indent
    if (indent !== undefined) {
        request.paragraphCharacteristics = {
            indent: parseMM(indent),
            paragraphIndent: 0.0,
        };
    }
    return request;
}
function getMinAndMaxFontSizes(textArea) {
    var _a;
    let minFontSize = Number.MAX_VALUE;
    let maxFontSize = Number.MIN_VALUE;
    if (textArea === undefined) {
        return { minFontSize, maxFontSize };
    }
    const textAreaWithContent = textArea;
    if (textAreaWithContent.content) {
        for (const content of textAreaWithContent.content) {
            const { minFontSize: min, maxFontSize: max } = getContentMinAndMaxFontSizes(content);
            minFontSize = Math.min(minFontSize, min);
            maxFontSize = Math.max(maxFontSize, max);
        }
    }
    else {
        const textAreaWithFields = textArea;
        for (const textField of (_a = textAreaWithFields.textFields) !== null && _a !== void 0 ? _a : []) {
            minFontSize = Math.min(minFontSize, parseMM(textField.fontSize));
            maxFontSize = Math.max(maxFontSize, parseMM(textField.fontSize));
        }
    }
    return { minFontSize, maxFontSize };
}
function getContentMinAndMaxFontSizes(content) {
    var _a, _b, _c, _d;
    let minFontSize = Number.MAX_VALUE;
    let maxFontSize = Number.MIN_VALUE;
    if (!content.type || content.type === "inline") {
        minFontSize = Math.min(minFontSize, parseMM((_a = content.fontSize) !== null && _a !== void 0 ? _a : "0pt"));
        maxFontSize = Math.max(maxFontSize, parseMM((_b = content.fontSize) !== null && _b !== void 0 ? _b : "0pt"));
    }
    else if (content.type === "list") {
        for (const listContent of content.content) {
            minFontSize = Math.min(minFontSize, parseMM((_c = listContent.fontSize) !== null && _c !== void 0 ? _c : "0pt"));
            maxFontSize = Math.max(maxFontSize, parseMM((_d = listContent.fontSize) !== null && _d !== void 0 ? _d : "0pt"));
            for (const textFieldContent of listContent.content) {
                const { minFontSize: tfMin, maxFontSize: tfMax } = getContentMinAndMaxFontSizes(textFieldContent);
                minFontSize = Math.min(minFontSize, tfMin);
                maxFontSize = Math.max(maxFontSize, tfMax);
            }
        }
    }
    return { minFontSize, maxFontSize };
}
function interpretList(listContents, parentFont, flavor) {
    var _a, _b, _c, _d, _e, _f, _g;
    const interpretedContent = [];
    const ordinalsPerDepth = initializeOrdinalsPerDepth(listContents);
    for (let contentIndex = 0; contentIndex < listContents.length; contentIndex++) {
        const listItem = listContents[contentIndex];
        if (listItem.content === undefined || listItem.content.length === 0) {
            throw Error("List items must have at least one text field!");
        }
        const currentDepth = listItem.depth || 1;
        // Get the ordinal character and white space content
        const listContent = getListOrdinal(listItem, parentFont, ordinalsPerDepth, currentDepth);
        const fontSizeString = (_a = listItem.fontSize) !== null && _a !== void 0 ? _a : parentFont.size;
        if (fontSizeString === undefined) {
            throw Error("Cannot determine list font");
        }
        const indentFontSize = parseMM(fontSizeString);
        // Add user content on the list
        for (let itemIndex = 0; itemIndex < listItem.content.length; itemIndex++) {
            const itemTextField = listItem.content[itemIndex];
            let offset = undefined;
            let content = itemTextField.content;
            const contents = content.split(/\r\n|\r|\n/);
            for (let i = 0; i < contents.length; i++) {
                let textBlockContent = contents[i];
                const nextCharacter = content[textBlockContent.length];
                const nextToNextCharacter = content[textBlockContent.length + 1];
                if (nextCharacter === CARRIAGE_RETURN && nextToNextCharacter === NEW_LINE) {
                    textBlockContent += CARRIAGE_RETURN + NEW_LINE;
                }
                else if (nextCharacter === CARRIAGE_RETURN) {
                    textBlockContent += CARRIAGE_RETURN;
                }
                else if (nextCharacter === NEW_LINE) {
                    textBlockContent += NEW_LINE;
                }
                if (textBlockContent === "" && itemIndex < listItem.content.length - 1) {
                    continue;
                }
                content = content.slice(textBlockContent.length);
                if (i > 0 ||
                    (i === 0 &&
                        listItem.content[itemIndex - 1] &&
                        (listItem.content[itemIndex - 1].content.endsWith(NEW_LINE) || listItem.content[itemIndex - 1].content.endsWith(CARRIAGE_RETURN)))) {
                    offset = `${indentFontSize * BASE_INDENT_MULTIPLIER * currentDepth} mm`;
                }
                else
                    offset = undefined;
                const childFontInfo = createChildFontInformation(listItem, parentFont);
                const fontFamily = (_b = itemTextField.fontFamily) !== null && _b !== void 0 ? _b : childFontInfo.family;
                const fontStyle = (_c = itemTextField.fontStyle) !== null && _c !== void 0 ? _c : childFontInfo.style;
                const fontSize = (_d = itemTextField.fontSize) !== null && _d !== void 0 ? _d : childFontInfo.size;
                const color = (_e = itemTextField.color) !== null && _e !== void 0 ? _e : childFontInfo.color;
                const stroke = (_f = itemTextField.stroke) !== null && _f !== void 0 ? _f : childFontInfo.stroke;
                const decorations = (_g = itemTextField.decorations) !== null && _g !== void 0 ? _g : childFontInfo.decorations;
                if (!fontFamily || !fontStyle || !fontSize || !color) {
                    throw Error("Cannot determine list font style and color");
                }
                // The text field can override the parent fonts properties
                const textField = {
                    id: "list item",
                    content: applyCasing(textBlockContent, itemTextField.casing),
                    fontFamily,
                    fontStyle,
                    fontSize,
                    color,
                    stroke,
                    decorations,
                };
                const textBlock = buildTextBlockRequest(textField, flavor, false, offset);
                listContent.push({
                    listOrdinal: false,
                    isNewParagraphTextBlock: i > 0,
                    textField,
                    textBlock,
                });
            }
        }
        // Ensure that list content ends with a new line
        if (contentIndex !== listContents.length - 1 && listContent.length > 0) {
            const lastContent = listContent[listContent.length - 1];
            lastContent.textField.content += NEW_LINE;
            lastContent.textBlock.content += NEW_LINE;
        }
        interpretedContent.push(...listContent);
    }
    return interpretedContent;
}
/**
 * Custom list interpretation for text along a path with text lists. The main difference between this and interpretList() is that all
 * new lines, whether explicit in content or from implicit list behavior are converted to single spaces. The instructions service has more commentary.
 * @param listContents
 * @param parentFont
 * @param flavor
 * @returns
 */
function interpretListForTextPath(listContents, parentFont, flavor) {
    var _a, _b, _c, _d, _e, _f;
    const interpretedContent = [];
    for (let i = 0; i < listContents.length; i++) {
        const listItem = listContents[i];
        const childFontInfo = createChildFontInformation(listItem, parentFont);
        for (let j = 0; j < listItem.content.length; j++) {
            const listItemContent = splitListContentIntoLines(listItem.content[j]);
            for (const inlineContent of listItemContent) {
                inlineContent.type = "inline";
                const textBlockContent = inlineContent.content;
                const fontFamily = (_a = inlineContent.fontFamily) !== null && _a !== void 0 ? _a : childFontInfo.family;
                const fontStyle = (_b = inlineContent.fontStyle) !== null && _b !== void 0 ? _b : childFontInfo.style;
                const fontSize = (_c = inlineContent.fontSize) !== null && _c !== void 0 ? _c : childFontInfo.size;
                const color = (_d = inlineContent.color) !== null && _d !== void 0 ? _d : childFontInfo.color;
                const stroke = (_e = inlineContent.stroke) !== null && _e !== void 0 ? _e : childFontInfo.stroke;
                const decorations = (_f = inlineContent.decorations) !== null && _f !== void 0 ? _f : childFontInfo.decorations;
                if (!fontFamily || !fontStyle || !fontSize || !color) {
                    throw Error("Cannot determine list font style and color");
                }
                const textField = {
                    content: applyCasing(textBlockContent, listItem.casing),
                    fontFamily,
                    fontStyle,
                    fontSize,
                    color,
                    stroke,
                    decorations,
                };
                const textBlock = buildTextBlockRequest(textField, flavor, true);
                interpretedContent.push({
                    listOrdinal: false,
                    isNewParagraphTextBlock: false,
                    textField,
                    textBlock,
                });
            }
        }
        if (i !== listContents.length - 1) {
            const lastContent = interpretedContent[interpretedContent.length - 1];
            lastContent.textField.content += " ";
            lastContent.textBlock.content += " ";
        }
    }
    return interpretedContent;
}
function splitListContentIntoLines(textFieldContent) {
    const result = [];
    const splitContent = textFieldContent.content.split(/\r\n|\r|\n/);
    for (let i = 0; i < splitContent.length; i++) {
        const clonedContent = cloneDeep(textFieldContent);
        clonedContent.content = splitContent[i];
        if (i < splitContent.length - 1) {
            clonedContent.content += " ";
        }
        result.push(clonedContent);
    }
    return result;
}
