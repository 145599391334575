import { Matrix, inflate, parseMM } from "../../../..";
import cloneDeep from "lodash.clonedeep";
import { polygon } from "@turf/helpers";
import { Line } from "../../../../utils/math/geometry";
import { Vector2 } from "../../../../utils/math/Vector2";
import polygonUnion from "@turf/union";
export function generateTextBoundsShape({ lineInfos, position, cdifSpec, transform, }) {
    let lineInfosClone = cloneDeep(lineInfos);
    lineInfosClone = trimZeroWidthLineInfos(lineInfosClone);
    lineInfosClone = normalizeZeroWidthLineInfos(lineInfosClone);
    lineInfosClone = inflateTextBounds(lineInfosClone, cdifSpec.spread);
    lineInfosClone = mergeTextBounds(lineInfosClone, 0.05);
    // Means no characters exist inside text area
    if (lineInfosClone.length == 0) {
        return undefined;
    }
    let union;
    union = undefined;
    lineInfosClone.forEach((li) => {
        var _a;
        li.textBounds.x += parseMM(position.x);
        li.textBounds.y += parseMM(position.y);
        const rectPoly = polygon([
            [
                [li.textBounds.x, li.textBounds.y],
                [li.textBounds.x + li.textBounds.width, li.textBounds.y],
                [li.textBounds.x + li.textBounds.width, li.textBounds.y + li.textBounds.height],
                [li.textBounds.x, li.textBounds.y + li.textBounds.height],
                [li.textBounds.x, li.textBounds.y],
            ],
        ]);
        if (union === undefined) {
            union = rectPoly;
        }
        else {
            union = (_a = polygonUnion(union, rectPoly)) !== null && _a !== void 0 ? _a : undefined;
        }
    });
    const svgPath = union
        ? applyBorderRadius(convertPolygonToLines(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        union.geometry.coordinates[0]), lineInfosClone, cdifSpec.roundness)
        : "";
    return {
        path: svgPath,
        transform: transform !== null && transform !== void 0 ? transform : Matrix.identity(),
    };
}
function trimZeroWidthLineInfos(lineInfos) {
    if (lineInfos === null || lineInfos.length === 0) {
        return lineInfos;
    }
    let l = 0;
    let r = lineInfos.length - 1;
    while (l < lineInfos.length && lineInfos[l].textBounds.width === 0) {
        l++;
    }
    while (r >= l && lineInfos[r].textBounds.width === 0) {
        r--;
    }
    if (l > r) {
        return [];
    }
    return lineInfos.slice(l, r + 1);
}
function normalizeZeroWidthLineInfos(lineInfos) {
    if (lineInfos === null || lineInfos.length === 0) {
        return lineInfos;
    }
    const result = [];
    for (let i = 0; i < lineInfos.length; i++) {
        const lineInfo = cloneDeep(lineInfos[i]);
        let minimumWidthLineInfo = lineInfo;
        let minimumWidth = Number.MAX_VALUE;
        // If there are no characters in this line, it has a zero width. In this case, the width of the bounds will
        // take the value of the smallest nonzero width out of all the line infos
        if (lineInfo.textBounds.width === 0) {
            for (let l = i - 1; l >= 0; l--) {
                if (lineInfos[l].textBounds.width != 0 && lineInfos[l].textBounds.width < minimumWidth) {
                    minimumWidth = Math.min(minimumWidth, lineInfos[l].textBounds.width);
                    minimumWidthLineInfo = lineInfos[l];
                    break;
                }
            }
            for (let r = i + 1; r < lineInfos.length; r++) {
                if (lineInfos[r].textBounds.width != 0 && lineInfos[r].textBounds.width < minimumWidth) {
                    minimumWidth = Math.min(minimumWidth, lineInfos[r].textBounds.width);
                    minimumWidthLineInfo = lineInfos[r];
                    break;
                }
            }
            if (minimumWidth != Number.MAX_VALUE) {
                lineInfo.textBounds.width = minimumWidth;
                lineInfo.textBounds.x = minimumWidthLineInfo.textBounds.x;
            }
        }
        result.push(lineInfo);
    }
    return result;
}
function inflateTextBounds(lineInfos, spread) {
    if (lineInfos === null || lineInfos.length == 0) {
        return lineInfos;
    }
    const result = [];
    let maximumHeight = 0;
    lineInfos.forEach((li) => {
        maximumHeight = Math.max(maximumHeight, li.textBounds.height);
    });
    const inflateX = spread.x * maximumHeight;
    const inflateY = spread.y * maximumHeight;
    lineInfos.forEach((li) => {
        const liClone = cloneDeep(li);
        liClone.textBounds = inflate(liClone.textBounds, inflateX, inflateY);
        result.push(liClone);
    });
    return result;
}
function mergeTextBounds(lineInfos, thresholdPercentage) {
    const result = cloneDeep(lineInfos);
    // There is probably a more efficient way to do this
    for (let i = 0; i < lineInfos.length; i++) {
        let l = i - 1;
        let r = i + 1;
        const current = cloneDeep(lineInfos[i].textBounds);
        while (l >= 0 || r < lineInfos.length) {
            if (l >= 0 && mergeXCoordinates(current, lineInfos[l].textBounds, thresholdPercentage)) {
                l--;
            }
            else if (r < lineInfos.length && mergeXCoordinates(current, lineInfos[r].textBounds, thresholdPercentage)) {
                r++;
            }
            else {
                break;
            }
        }
        result[i].textBounds = current;
    }
    return result;
}
function mergeXCoordinates(current, neighbor, thresholdPercentage) {
    const currentLeft = current.x;
    let currentRight = current.x + current.width;
    const neighborLeft = neighbor.x;
    const neighborRight = neighbor.x + neighbor.width;
    const threshold = thresholdPercentage * (current.width + neighbor.width);
    let changed = false;
    if (Math.abs(currentLeft - neighborLeft) < threshold) {
        current.x = Math.min(currentLeft, neighborLeft);
        current.width = currentRight - current.x;
        changed = true;
    }
    if (Math.abs(currentRight - neighborRight) < threshold) {
        currentRight = Math.max(currentRight, neighborRight);
        current.width = currentRight - current.x;
        changed = true;
    }
    return changed;
}
function applyBorderRadius(path, lineInfos, roundness) {
    let borderRadius = Number.MAX_VALUE;
    // Max border radius is 50% of the line height. roundness is a value between [0, 1]
    let percent = roundness / 2.0;
    percent = clamp(percent, 0, 0.5);
    if (percent > 0.5) {
        percent = 0.5;
    }
    if (percent <= 0) {
        return applyBorderRadiusToPath(path, 0);
    }
    lineInfos.forEach((li) => {
        borderRadius = Math.min(borderRadius, li.textBounds.height * percent);
    });
    return applyBorderRadiusToPath(path, borderRadius);
}
function applyBorderRadiusToPath(path, borderRadius) {
    const pathSegments = [];
    let lastPoint = { x: 0, y: 0 };
    let stashedPoint = { x: 0, y: 0 };
    let firstPathIsTruncated = false;
    for (let i = 0; i < path.length; i++) {
        const previousLine = path[unsignedModulo(i - 1, path.length)];
        const currentLine = path[i];
        const radius = Math.min(Math.min(borderRadius, previousLine.length() / 2), currentLine.length() / 2);
        let previousVec = toVector2(previousLine.end, previousLine.start);
        let currentVec = toVector2(currentLine.start, currentLine.end);
        // Normalize the vectors
        previousVec = previousVec.normalize();
        currentVec = currentVec.normalize();
        const crossProduct = clamp(currentVec.crossProduct(previousVec), -1, 1);
        // If cross product is 0 that means the angle between the vectors is 180 degrees or 0,
        // and no border radius needs to be applied
        if (Math.abs(crossProduct) <= 0.001) {
            pathSegments.push(`L${previousLine.end.x} ${previousLine.end.y}`);
            pathSegments.push(`L${currentLine.end.x} ${currentLine.end.y}`);
            lastPoint = currentLine.end;
        }
        else {
            // If border radius needs to be applied, the last line added must be truncated. Remove the line here since the truncated
            // line is being added in this block
            if (pathSegments.length > 0) {
                pathSegments.pop();
            }
            if (i === 0) {
                firstPathIsTruncated = true;
            }
            // The angle between the two vectors. May be negative
            const vectorAngle = (Math.asin(crossProduct) * 180) / Math.PI;
            const opposite = radius;
            const hypotenuse = opposite / Math.sin(((Math.abs(vectorAngle) / 2) * Math.PI) / 180);
            // This is the quantity we're interested in; which is how much we'll subtract from the line to fit the circle/ellipse
            const adjacent = Math.sqrt(Math.pow(hypotenuse, 2) - Math.pow(opposite, 2));
            let truncatedPreviousLine = previousLine.truncate(adjacent, true);
            let truncatedCurrentLine = currentLine.truncate(adjacent, false);
            let arcRadiusX = radius;
            let arcRadiusY = radius;
            if (radius < borderRadius) {
                const augmentedRadius = radius + (borderRadius - radius) / 2;
                // If the augmented radius is much greater than the original radius, don't force an elliptical arc
                if (previousLine.length() != currentLine.length() && augmentedRadius / radius < 2) {
                    let isVertical;
                    let actualAugmentedRadius;
                    if (previousLine.length() > currentLine.length()) {
                        actualAugmentedRadius = Math.min(augmentedRadius, previousLine.length());
                        truncatedPreviousLine = previousLine.truncate(actualAugmentedRadius, true);
                        isVertical = previousLine.isVertical();
                    }
                    else {
                        actualAugmentedRadius = Math.min(augmentedRadius, currentLine.length());
                        truncatedCurrentLine = currentLine.truncate(actualAugmentedRadius, false);
                        isVertical = currentLine.isVertical();
                    }
                    if (isVertical) {
                        arcRadiusY = actualAugmentedRadius;
                    }
                    else {
                        arcRadiusX = actualAugmentedRadius;
                    }
                }
            }
            const sweepFlag = crossProduct > 0 ? 1 : 0;
            pathSegments.push(`L${truncatedPreviousLine.end.x} ${truncatedPreviousLine.end.y}`);
            pathSegments.push(`A${arcRadiusX} ${arcRadiusY} 0 0 ${sweepFlag} ${truncatedCurrentLine.start.x} ${truncatedCurrentLine.start.y}`);
            pathSegments.push(`L${truncatedCurrentLine.end.x} ${truncatedCurrentLine.end.y}`);
            stashedPoint = { x: truncatedCurrentLine.start.x, y: truncatedCurrentLine.start.y };
            lastPoint = truncatedCurrentLine.end;
        }
    }
    if (firstPathIsTruncated) {
        pathSegments.pop();
        lastPoint = stashedPoint;
    }
    pathSegments.splice(0, 0, `M${lastPoint.x} ${lastPoint.y}`);
    return pathSegments.join("");
}
// Note that the last and first point in coordinates are the same, so don't count the last point
function convertPolygonToLines(coordinates) {
    const lines = [];
    for (let i = 1; i < coordinates.length; i++) {
        const lastPoint = coordinates[i - 1];
        const currentPoint = coordinates[i];
        const line = new Line({ x: lastPoint[0], y: lastPoint[1] }, { x: currentPoint[0], y: currentPoint[1] });
        lines.push(line);
    }
    return lines;
}
function unsignedModulo(x, m) {
    return ((x % m) + m) % m;
}
function toVector2(p1, p2) {
    return new Vector2(p2.x - p1.x, p2.y - p1.y);
}
function clamp(num, min, max) {
    return num < min ? min : num > max ? max : num;
}
