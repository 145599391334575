/**
 * Hierarchical CimDoc definitions abstracted into a tree node. Each node has a pointer to its parent definition (null if root)
 */
export default class CimDocDefinitionTreeNode {
    constructor(definition, parent) {
        this.id = `${Math.random()}`;
        this.definition = definition;
        this.parent = parent;
    }
    /**
     * Creates and returns a CimDoc definition tree node as a child to this node.
     * @param definition
     * @returns the new child node, with this node as its parent
     */
    createChildNode(definition) {
        return new CimDocDefinitionTreeNode(definition, this);
    }
    getFilterRecursive(filterName) {
        var _a, _b;
        if (((_b = (_a = this.definition) === null || _a === void 0 ? void 0 : _a.filters) === null || _b === void 0 ? void 0 : _b[filterName]) !== undefined) {
            return this.definition.filters[filterName];
        }
        if (this.parent !== undefined) {
            return this.parent.getFilterRecursive(filterName);
        }
        return undefined;
    }
    getPaintRecursive(paintName) {
        var _a, _b;
        if (((_b = (_a = this.definition) === null || _a === void 0 ? void 0 : _a.paints) === null || _b === void 0 ? void 0 : _b[paintName]) !== undefined) {
            return this.definition.paints[paintName];
        }
        if (this.parent !== undefined) {
            return this.parent.getPaintRecursive(paintName);
        }
        return undefined;
    }
    getPanelRecursive(panelName) {
        var _a, _b;
        if (((_b = (_a = this.definition) === null || _a === void 0 ? void 0 : _a.panels) === null || _b === void 0 ? void 0 : _b[panelName]) !== undefined) {
            return this.definition.panels[panelName];
        }
        if (this.parent !== undefined) {
            return this.parent.getPanelRecursive(panelName);
        }
        return undefined;
    }
}
