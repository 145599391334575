import { TileAugmenter } from "./TileAugmenter";
import { parseMM } from "../../unitHelper";
export class StackAugmenter extends TileAugmenter {
    constructor(tile, definitionPanel) {
        super(tile, definitionPanel);
        this.alternateSize = tile.alternateSize;
        this.stackBorder = tile.stackBorder ? parseMM(tile.stackBorder) : 0;
        this.tileWidth = 2 * (this.defpanelWidth + 2 * this.stackBorder);
        this.tileHeight = 2 * (this.defpanelHeight + 2 * this.stackBorder);
    }
    /// Stack Tile will be created as follows
    /// |<border>------pattern-1--------------------<border>|<border>------pattern-1-<alternate?>------------<border>|
    /// |<border>
    /// |<border>
    /// |<border>------pattern-1-<alternate?>-------<border>|<border>------pattern-1-------------------------<border>|
    augmentTilePatterns() {
        const patternWidth = this.defpanelWidth;
        const patternHeight = this.defpanelHeight;
        const pattern1Position = this.createBounds(this.stackBorder, this.stackBorder, patternWidth, patternHeight);
        this.augmentPattern(pattern1Position);
        const threeBorderOffset = 3 * this.stackBorder;
        if (this.alternateSize) {
            const xOffset2 = 1.25 * patternWidth + threeBorderOffset;
            const yOffset2 = 0.25 * patternHeight + this.stackBorder;
            const pattern2Position = this.createBounds(xOffset2, yOffset2, patternWidth / 2, patternHeight / 2);
            this.augmentPattern(pattern2Position, 0.5);
            const xOffset3 = 0.25 * patternWidth + this.stackBorder;
            const yOffset3 = 1.25 * patternHeight + threeBorderOffset;
            const pattern3Position = this.createBounds(xOffset3, yOffset3, patternWidth / 2, patternHeight / 2);
            this.augmentPattern(pattern3Position, 0.5);
        }
        else {
            const pattern2Position = this.createBounds(patternWidth + threeBorderOffset, this.stackBorder, patternWidth, patternHeight);
            this.augmentPattern(pattern2Position);
            const pattern3Position = this.createBounds(this.stackBorder, patternHeight + threeBorderOffset, patternWidth, patternHeight);
            this.augmentPattern(pattern3Position);
        }
        const pattern4Position = this.createBounds(patternWidth + threeBorderOffset, patternHeight + threeBorderOffset, patternWidth, patternHeight);
        this.augmentPattern(pattern4Position);
    }
    getTileWidth() {
        return this.tileWidth;
    }
    getTileHeight() {
        return this.tileHeight;
    }
}
