var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { buildTransform } from "../../layout/helpers/Transform";
// Set position of items to 0,0 and rotationAngle to 0 for sending to the server for a preview
export function getItemForPanel(_a) {
    return __awaiter(this, arguments, void 0, function* ({ itemInfo }) {
        if (itemInfo.itemType === "ornament" || itemInfo.itemType === "video") {
            throw Error(`Fallback is currently not supported on ornaments and videos.`);
        }
        const item = itemInfo.item;
        // This is a very rough estimate to determine how much the fallback image will be scaled. It is not meant to be a perfect approximation.
        let scaleFactor = 1;
        const transform = buildTransform({
            bounds: { left: 0, top: 0, width: 0, height: 0 }, // doesn't matter
            scale: item.scale,
            matrixTransform: item.transform,
            itemTransforms: item.transforms,
        });
        scaleFactor = Math.max(Math.abs(transform.a), Math.abs(transform.b), Math.abs(transform.c), Math.abs(transform.d));
        if (itemInfo.itemType === "textArea") {
            const textArea = item;
            return {
                originalClipping: textArea.clipping,
                scaleFactor,
                itemToAdd: {
                    textAreas: [
                        Object.assign(Object.assign({}, textArea), { position: Object.assign(Object.assign({}, textArea.position), { x: "0mm", y: "0mm" }), scale: undefined, skew: undefined, rotationAngle: undefined, clipping: undefined, transforms: undefined, transform: undefined }),
                    ],
                },
            };
        }
        else if (itemInfo.itemType === "itemReference") {
            const itemRef = item;
            return {
                scaleFactor,
                itemToAdd: {
                    itemReferences: [
                        Object.assign(Object.assign({}, itemRef), { position: Object.assign(Object.assign({}, itemRef.position), { x: "0mm", y: "0mm" }), scale: undefined, rotationAngle: undefined, transforms: undefined, transform: undefined }),
                    ],
                },
            };
        }
        else if (itemInfo.itemType === "subpanel") {
            const subpanel = item;
            return {
                scaleFactor,
                itemToAdd: {
                    subpanels: [
                        Object.assign(Object.assign({}, subpanel), { position: {
                                x: "0mm",
                                y: "0mm",
                            }, scale: undefined, skew: undefined, rotationAngle: undefined, transforms: undefined, transform: undefined }),
                    ],
                },
            };
        }
        else if (itemInfo.itemType === "image") {
            const image = item;
            return {
                originalClipping: image.clipping,
                scaleFactor,
                itemToAdd: {
                    images: [
                        Object.assign(Object.assign({}, image), { position: Object.assign(Object.assign({}, image.position), { x: "0mm", y: "0mm" }), scale: undefined, skew: undefined, rotationAngle: undefined, clipping: undefined, transforms: undefined, transform: undefined }),
                    ],
                },
            };
        }
        // shapes
        else {
            const shape = item;
            if (shape.type !== "line") {
                return {
                    originalPosition: shape.position,
                    scaleFactor,
                    itemToAdd: {
                        shapes: [
                            Object.assign(Object.assign({}, shape), { 
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                position: Object.assign(Object.assign({}, shape.position), { x: "0mm", y: "0mm" }), 
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                rotationAngle: undefined, scale: undefined, skew: undefined, transforms: undefined, transform: undefined }),
                        ],
                    },
                };
            }
            // line item
            return {
                scaleFactor,
                itemToAdd: {
                    shapes: [
                        Object.assign(Object.assign({}, shape), { scale: undefined, rotationAngle: undefined, skew: undefined, transforms: undefined, transform: undefined }),
                    ],
                },
            };
        }
    });
}
