import { getNumberAndUnitValue } from "./unitHelper";
const getNumericValue = (value, unitLength) => value.substring(0, value.length - unitLength);
const mapCurveToSvgPath = (curve, N) => {
    const operation = curve.operation.toLowerCase();
    if (operation === "curveto") {
        return `C ${N(curve.controlPoint1.x)} ${N(curve.controlPoint1.y)}, ${N(curve.controlPoint2.x)} ${N(curve.controlPoint2.y)}, ${N(curve.position.x)} ${N(curve.position.y)}`;
    }
    if (operation === "lineto") {
        return `L ${N(curve.position.x)} ${N(curve.position.y)}`;
    }
    if (operation === "moveto") {
        return `M ${N(curve.position.x)} ${N(curve.position.y)}`;
    }
    if (operation === "quadto") {
        return `Q ${N(curve.controlPoint1.x)} ${N(curve.controlPoint1.y)}, ${N(curve.position.x)} ${N(curve.position.y)}`;
    }
    if (operation === "close") {
        return "Z";
    }
    return "";
};
export function curveLegacyToCurve({ curve }) {
    if (curve.svgPathData) {
        return curve;
    }
    const [, unitValue] = getNumberAndUnitValue(curve.curves[0].position.x);
    const N = (v) => getNumericValue(v, unitValue.length);
    const pathData = curve.curves.map((c) => mapCurveToSvgPath(c, N)).join("");
    return {
        id: curve.id,
        position: curve.position,
        type: curve.type,
        closeBehavior: curve.closeBehavior,
        color: curve.color,
        clipping: curve.clipping,
        metadata: curve.metadata,
        overprints: curve.overprints,
        rotationAngle: curve.rotationAngle,
        viewBox: curve.viewBox,
        svgPathDataUnit: unitValue,
        windingType: curve.windingType,
        zIndex: curve.zIndex,
        svgPathData: pathData,
        stroke: curve.stroke,
    };
}
