var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { boundingBoxToPreviewBox, computeBoundsFromPosition } from "../../utils/boundingBox";
import { parseMS } from "../../utils/timeUnitHelper";
import { getClip } from "../helpers/Clip";
import { buildTransform } from "../helpers/Transform";
function getCrop(image) {
    if (image.cropFractions) {
        return {
            bottom: parseFloat(image.cropFractions.bottom),
            top: parseFloat(image.cropFractions.top),
            left: parseFloat(image.cropFractions.left),
            right: parseFloat(image.cropFractions.right),
        };
    }
    return undefined;
}
export function playableVideoLayout(_a) {
    return __awaiter(this, arguments, void 0, function* ({ video, parentBounds, experimentalOptions, }) {
        var _b, _c, _d;
        const boundingBox = computeBoundsFromPosition({ position: video.position });
        const previewBox = boundingBoxToPreviewBox({ boundingBox, position: video.position });
        const transform = buildTransform({ bounds: boundingBox, rotationAngle: video.rotationAngle, itemTransforms: video.transforms, translateToBounds: true });
        const renderingOperation = {
            type: "drawVideo",
            transform,
            crop: getCrop(video),
            clip: yield getClip(video, parentBounds),
            opacityMultiplier: (_b = video.opacityMultiplier) !== null && _b !== void 0 ? _b : 1,
            startTimeMs: parseMS((_c = video.startTime) !== null && _c !== void 0 ? _c : "0s"),
            segments: (_d = video.segments) === null || _d === void 0 ? void 0 : _d.map((segment) => ({
                startTimeMs: segment.startTime ? parseMS(segment.startTime) : undefined,
                endTimeMs: segment.endTime ? parseMS(segment.endTime) : undefined,
            })),
        };
        if (!(experimentalOptions === null || experimentalOptions === void 0 ? void 0 : experimentalOptions.minimizeOutput)) {
            const videoResponse = yield fetch(video.sourceUrl);
            const videoBlob = yield videoResponse.blob();
            renderingOperation.video = videoBlob;
        }
        return {
            id: video.id,
            status: { mode: "local" },
            measurementData: {
                boundingBox,
                previewBox,
                layoutBox: previewBox,
            },
            renderingOperation,
        };
    });
}
