var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { parseMM } from "./utils/unitHelper";
import { selectItemFromSurface } from "./selectors/selectItemFromSurface";
import { validateDocument, validateSurface } from "./layout/document/Validation";
import { parseTechnology } from "./layout/helpers/Technology";
import { timestamp } from "./utils/time";
import { log } from "./utils/log";
import { layoutItem } from "./layout/layoutItem";
import { validateItem } from "./layout/validateItem";
import { getFilters } from "./utils/paint/colorMode";
import { fallbackItem } from "./fallback/Item";
import { selectSurface } from "./selectors/selectSurface";
import { sanitizeOverprint } from "./utils/paint/Color";
import CimDocDefinitionTreeNode from "./utils/CimDocDefinitionTreeNode";
// import { AudioLayout } from "./layout/Models";
// import { getAudio } from "./layout/audio/Layout";
function selectorToString(selector) {
    if (selector.type === "page") {
        return `page ${selector.number}`;
    }
    return `${selector.type} ${selector.id}`;
}
export function layout(input) {
    return __awaiter(this, void 0, void 0, function* () {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        const start = timestamp((_b = (_a = input.debugOptions) === null || _a === void 0 ? void 0 : _a.timers) !== null && _b !== void 0 ? _b : false);
        const { selector, document } = input;
        log({ message: `calling layout function for: ${selectorToString(selector)}`, enabled: (_d = (_c = input.debugOptions) === null || _c === void 0 ? void 0 : _c.log) !== null && _d !== void 0 ? _d : false, objects: input });
        const surface = yield selectSurface({ selector, document });
        const documentValidation = validateDocument({ document });
        const surfaceValidation = validateSurface({ surface });
        if (documentValidation.status === "fail") {
            throw new Error(`documentValidation failed ${documentValidation.error}`);
        }
        if (surfaceValidation.status === "fail") {
            throw new Error(`surfaceValidation failed ${surfaceValidation.error}`);
        }
        log({ message: `surface created for: ${selectorToString(selector)}`, enabled: (_f = (_e = input.debugOptions) === null || _e === void 0 ? void 0 : _e.log) !== null && _f !== void 0 ? _f : false, objects: surface });
        // TODO: rename previewtype "document" to "panel"?
        const previewType = selector.type === "item" ? "item" : "document";
        const overprints = (_h = (_g = input.overprints) === null || _g === void 0 ? void 0 : _g.map((o) => { var _a; return (_a = sanitizeOverprint(o)) !== null && _a !== void 0 ? _a : "n/a"; })) !== null && _h !== void 0 ? _h : [];
        // Reusable function to use for subpanels
        const validateAndLayout = createValidateAndLayout(Object.assign(Object.assign({}, input), { overprints, surface, previewType }));
        // let audioPromise: Promise<AudioLayout | undefined>;
        // if (input.videoOptions?.enableVideo && input.videoOptions?.mode === "video") {
        //   audioPromise = getAudio({ surface });
        // } else {
        //   audioPromise = Promise.resolve(undefined);
        // }
        const elementsPromise = validateAndLayout({ surfaceOrSubpanel: surface, selectorFromInput: selector });
        return Object.assign({ layoutType: previewType, boundingBox: {
                left: 0,
                top: 0,
                width: parseMM(surface.width),
                height: parseMM(surface.height),
            }, elements: yield elementsPromise, 
            // audio: await audioPromise,
            filters: getFilters(surface) }, (start ? { debugInfo: { timers: { total: timestamp(true) - start } } } : {}));
    });
}
// Binds a validateAndLayout function to the given arguments
// validateAndLayout can call itself to layout the items within subpanels
function createValidateAndLayout(input) {
    var _a, _b;
    const { document, pixelSize, debugOptions, experimentalOptions, forceFallback, overprints, textOptions, imageOptions, surface, videoOptions, referrer, previewType, } = input;
    const decoTech = parseTechnology(surface.decorationTechnology);
    const parentBounds = { left: 0, top: 0, width: parseMM(surface.width), height: parseMM(surface.height) };
    const { colorPalette, definitions } = document.document;
    const definitionTreeNode = new CimDocDefinitionTreeNode(definitions, undefined);
    const enableLog = (_a = debugOptions === null || debugOptions === void 0 ? void 0 : debugOptions.log) !== null && _a !== void 0 ? _a : false;
    const fontRepositoryUrl = document.fontRepositoryUrl;
    const trackTime = (_b = debugOptions === null || debugOptions === void 0 ? void 0 : debugOptions.timers) !== null && _b !== void 0 ? _b : false;
    const validateAndLayout = (_a) => __awaiter(this, [_a], void 0, function* ({ surfaceOrSubpanel, selectorFromInput, definitionTreeNodeOverride, previewTypeOverride, }) {
        // Grouping all necessary data for easier usage
        const itemInfos = (selectorFromInput === null || selectorFromInput === void 0 ? void 0 : selectorFromInput.type) === "item"
            ? [Object.assign({ depth: 0 }, selectItemFromSurface({ id: selectorFromInput.id, surface: surfaceOrSubpanel }))]
            : getAllOrderableItemInfo({ surface: surfaceOrSubpanel });
        // Validate all items
        const itemValidators = itemInfos.map((itemInfo) => {
            return { itemInfo, validation: validateItem({ itemInfo, decoTech, experimentalOptions, textOptions }) };
        });
        // Map all items to layoutElements using client side rendering or falling back to server
        return (yield Promise.all(itemValidators.map((_a) => __awaiter(this, [_a], void 0, function* ({ itemInfo, validation }) {
            var _b;
            let error = forceFallback ? "forced fallback" : validation.error;
            if (!forceFallback && validation.status === "pass") {
                try {
                    // Client side layout
                    // await to make try catch work
                    return yield layoutItem({
                        decoTech,
                        enableLog,
                        fontRepositoryUrl,
                        itemInfo,
                        parentBounds,
                        trackTime,
                        pixelSize,
                        textOptions,
                        imageOptions,
                        validateAndLayout, // used for subpanels
                        experimentalOptions,
                        videoOptions,
                        overprints,
                        definitionTreeNode: definitionTreeNodeOverride !== null && definitionTreeNodeOverride !== void 0 ? definitionTreeNodeOverride : definitionTreeNode,
                        referrer,
                        previewType: previewTypeOverride !== null && previewTypeOverride !== void 0 ? previewTypeOverride : previewType,
                        colorPalette,
                    });
                }
                catch (e) {
                    error = (_b = e === null || e === void 0 ? void 0 : e.message) !== null && _b !== void 0 ? _b : e;
                }
            }
            // Don't fallback to serverside for ornament
            if (itemInfo.itemType === "ornament") {
                throw new Error(error);
            }
            // Server side layout (fetching image)
            return yield fallbackItem({
                decoTech,
                enableLog,
                fontRepositoryUrl,
                itemInfo,
                parentBounds,
                pixelSize,
                trackTime,
                error,
                overprints,
                previewType: previewTypeOverride !== null && previewTypeOverride !== void 0 ? previewTypeOverride : previewType,
                definitionTreeNode: definitionTreeNodeOverride !== null && definitionTreeNodeOverride !== void 0 ? definitionTreeNodeOverride : definitionTreeNode,
                referrer,
                colorPalette,
            });
        }))))
            .filter((x) => {
            // filter undefined, like skipped ornaments
            return !!x;
        })
            .sort((a, b) => a.depth - b.depth)
            .map((orderedElement) => orderedElement.value);
    });
    return validateAndLayout;
}
function getItemInfo(designElement, depth) {
    switch (designElement.itemType) {
        case "shape":
            return { itemType: "shape", item: designElement, depth };
        case "image":
            return { itemType: "image", item: designElement, depth };
        case "textArea":
            return { itemType: "textArea", item: designElement, depth };
        case "itemReference":
            return { itemType: "itemReference", item: designElement, depth };
        case "subpanel":
            return { itemType: "subpanel", item: designElement, depth };
        case "video":
            return { itemType: "video", item: designElement, depth };
        default:
            throw `Unknown itemType`;
    }
}
// Grouping all necessary data for easier usage
function getAllOrderableItemInfo({ surface }) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const basicItems = [
        ...(((_a = surface.images) === null || _a === void 0 ? void 0 : _a.map((image) => {
            var _a;
            return { depth: (_a = image.zIndex) !== null && _a !== void 0 ? _a : 0, item: image, itemType: "image" };
        })) || []),
        ...(((_b = surface.shapes) === null || _b === void 0 ? void 0 : _b.map((shape) => {
            var _a;
            return { depth: (_a = shape.zIndex) !== null && _a !== void 0 ? _a : 0, item: shape, itemType: "shape" };
        })) || []),
        ...(((_c = surface.textAreas) === null || _c === void 0 ? void 0 : _c.map((textArea) => {
            var _a;
            return { depth: (_a = textArea.zIndex) !== null && _a !== void 0 ? _a : 0, item: textArea, itemType: "textArea" };
        })) || []),
        ...(((_d = surface.itemReferences) === null || _d === void 0 ? void 0 : _d.map((itemRef) => {
            var _a;
            return { depth: (_a = itemRef.zIndex) !== null && _a !== void 0 ? _a : 0, item: itemRef, itemType: "itemReference" };
        })) || []),
        ...(((_e = surface.subpanels) === null || _e === void 0 ? void 0 : _e.map((subpanel) => {
            var _a;
            return { depth: (_a = subpanel.zIndex) !== null && _a !== void 0 ? _a : 0, item: subpanel, itemType: "subpanel" };
        })) || []),
        ...(((_f = surface.videos) === null || _f === void 0 ? void 0 : _f.map((video) => {
            var _a;
            return { depth: (_a = video.zIndex) !== null && _a !== void 0 ? _a : 0, item: video, itemType: "video" };
        })) || []),
    ];
    if (surface.background || surface.foreground) {
        const depthValues = basicItems.map((item) => item.depth);
        const minDepth = depthValues.length === 0 ? 0 : Math.min(...depthValues);
        const maxDepth = depthValues.length === 0 ? 0 : Math.max(...depthValues);
        if ((_g = surface.background) === null || _g === void 0 ? void 0 : _g.items) {
            let offset = -1;
            for (let i = surface.background.items.length - 1; i >= 0; i--) {
                const itemInfo = getItemInfo(surface.background.items[i], minDepth + offset);
                basicItems.push(itemInfo);
                offset--;
            }
        }
        if ((_h = surface.foreground) === null || _h === void 0 ? void 0 : _h.items) {
            let offset = 1;
            for (let i = 0; i < surface.foreground.items.length; i++) {
                const itemInfo = getItemInfo(surface.foreground.items[i], maxDepth + offset);
                basicItems.push(itemInfo);
                offset++;
            }
        }
    }
    if (surface.ornaments) {
        // add ornaments last
        surface.ornaments.forEach((ornament) => {
            basicItems.push({
                depth: Infinity,
                item: ornament,
                itemType: "ornament",
            });
        });
    }
    return basicItems;
}
