import { parseMM } from "../../utils/unitHelper";
export function translateLineJoin(stroke) {
    switch (stroke.lineJoin) {
        case "bevel":
            return "bevel";
        case "mitre":
            return "mitre";
        case "round":
            return "round";
        case undefined:
            return "round";
        default:
            throw Error(`Unknown stroke line join: ${stroke.lineJoin}`);
    }
}
export function translateLineCap(stroke) {
    switch (stroke.lineCap) {
        case "round":
            return "round";
        case "butt":
            return "butt";
        case "square":
            return "square";
        case undefined:
            return "round";
        default:
            throw Error(`Unknown stroke line cap: ${stroke.lineCap}`);
    }
}
export function translateDashArray(stroke) {
    var _a, _b;
    return (_b = (_a = stroke.dashPattern) === null || _a === void 0 ? void 0 : _a.segments.map((dashSegment) => parseMM(dashSegment.length))) !== null && _b !== void 0 ? _b : [];
}
