import { selectItemFromSurface } from "./selectItemFromSurface";
// Should we export this since we need it a lot?
// This should eventually be in a CimDoc SDK.
function getDesignSurfaces(document) {
    var _a, _b;
    return (_b = (_a = document.document.surfaces) !== null && _a !== void 0 ? _a : document.document.panels) !== null && _b !== void 0 ? _b : [];
}
export function selectPanel({ document, id }) {
    const panels = getDesignSurfaces(document);
    const surface = panels.find((panel) => panel.id === id);
    if (surface === undefined) {
        throw Error(`No panel found for id ${id}`);
    }
    return surface;
}
export function selectPanelByPage({ document, page }) {
    const panels = getDesignSurfaces(document);
    if (page > panels.length) {
        throw Error(`No panel found for page ${page}`);
    }
    return panels[page - 1];
}
export function selectPanelByNameOrLocation({ document, nameOrLocation }) {
    const panels = getDesignSurfaces(document);
    const surface = panels.find((panel) => panel.name.toLowerCase() === nameOrLocation.toLowerCase());
    if (surface !== undefined) {
        return surface;
    }
    const surface2 = panels.find((panel) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const location = panel.location;
        return location.length > 0 && location.toLowerCase() === nameOrLocation.toLowerCase();
    });
    if (surface2 !== undefined) {
        return surface2;
    }
    throw Error(`No panel found for name or location ${nameOrLocation}`);
}
export function selectPanelByItemId({ document, itemId }) {
    const panels = getDesignSurfaces(document);
    const surface = panels.find((panel) => {
        try {
            selectItemFromSurface({ id: itemId, surface: panel });
            return true;
        }
        catch (_a) {
            return false;
        }
    });
    if (surface === undefined) {
        throw Error(`No panel found with an item of id ${itemId}`);
    }
    return surface;
}
