var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { fetchWithNetworkCache } from "../../cache/network";
import { subpanelLayout } from "../subpanel/Layout";
import { Matrix } from "../../utils/math/matrix";
import { parseMM } from "../../utils/unitHelper";
import { replaceColors } from "./replaceColors";
import { boundingBoxFromPath, computeBoundsFromPosition } from "../../utils/boundingBox";
import { buildTransform } from "../helpers/Transform";
import CimDocDefinitionTreeNode from "../../utils/CimDocDefinitionTreeNode";
import { getClip } from "../helpers/Clip";
import { getMeasurementData } from "../measurements/measurementData";
import { parsePathData } from "../../utils/parsePathData";
export function getItemReferenceType(itemReference) {
    if (itemReference.url.indexOf("items.documents.cimpress.io/v1/vector") > 0)
        return "vector";
    if (itemReference.url.indexOf("items.documents.cimpress.io/v1/tileimage") > 0)
        return "tileimage";
    return "unknown";
}
export function itemReferenceLayout(_a) {
    return __awaiter(this, arguments, void 0, function* ({ itemReference, parentBounds, previewType, validateAndLayout, colorPalette, definitionTreeNode, fontRepositoryUrl, }) {
        var _b;
        const itemRefType = getItemReferenceType(itemReference);
        if (itemRefType === "vector") {
            const { subpanelUrl, colorOverrides, cropFractions } = itemReference.data;
            // Fetch subpanel
            const { cachedSubpanel, cachedLayoutElements } = yield fetchWithNetworkCache({
                url: subpanelUrl,
                responseResolver: (response) => __awaiter(this, void 0, void 0, function* () {
                    const subpanel = yield response.json();
                    try {
                        if (subpanel.definitions !== undefined) {
                            if (definitionTreeNode !== undefined) {
                                const childNode = definitionTreeNode.createChildNode(subpanel.definitions);
                                definitionTreeNode = childNode;
                            }
                            else {
                                definitionTreeNode = new CimDocDefinitionTreeNode(subpanel.definitions, undefined);
                            }
                        }
                        const layoutElements = yield validateAndLayout({
                            surfaceOrSubpanel: subpanel,
                            definitionTreeNodeOverride: definitionTreeNode,
                            previewTypeOverride: "document",
                        });
                        return { cachedSubpanel: subpanel, cachedLayoutElements: layoutElements };
                    }
                    catch (_a) {
                        // Something is not supported, return empty value to throw later but cache the result
                        return { cachedLayoutElements: [], cachedSubpanel: {} };
                    }
                }),
            });
            if (cachedLayoutElements.length === 0) {
                throw new Error("Something not supported in returned subpabel");
            }
            const layoutElements = replaceColors(cachedLayoutElements, colorOverrides, colorPalette);
            // Layout as subpanel
            const layoutResultsSubpanel = yield subpanelLayout({
                subpanel: cachedSubpanel,
                layoutElements,
                parentBounds,
                previewType,
                options: {
                    definitionTreeNode,
                },
            });
            return yield getSubpanelLayoutResult(itemReference, cropFractions, layoutResultsSubpanel, previewType, parentBounds, fontRepositoryUrl);
        }
        if (itemRefType === "tileimage") {
            const { image } = itemReference.data;
            image.position.x = "0mm";
            image.position.y = "0mm";
            const subpanel = {
                id: "subpanel",
                position: {
                    x: "0mm",
                    y: "0mm",
                },
                definitions: {
                    paints: {
                        tileImagePaint: {
                            type: "pattern",
                            definedPanelName: "tileImagePanel",
                        },
                    },
                    panels: {
                        tileImagePanel: {
                            id: "definitionPanelId",
                            name: "definitionPanel",
                            width: image.position.width,
                            height: image.position.height,
                            images: [image],
                            decorationTechnology: (_b = itemReference.decorationTechnology) !== null && _b !== void 0 ? _b : "print",
                        },
                    },
                },
                shapes: [
                    {
                        id: "shape",
                        type: "rectangle",
                        position: {
                            x: "0mm",
                            y: "0mm",
                            width: itemReference.position.width,
                            height: itemReference.position.height,
                        },
                        color: "paint(tileImagePaint)",
                    },
                ],
            };
            if (subpanel.definitions !== undefined) {
                if (definitionTreeNode !== undefined) {
                    const childNode = definitionTreeNode.createChildNode(subpanel.definitions);
                    definitionTreeNode = childNode;
                }
                else {
                    definitionTreeNode = new CimDocDefinitionTreeNode(subpanel.definitions, undefined);
                }
            }
            const layoutElements = yield validateAndLayout({
                surfaceOrSubpanel: subpanel,
                definitionTreeNodeOverride: definitionTreeNode,
                previewTypeOverride: "document",
            });
            const layoutResultsSubpanel = yield subpanelLayout({
                subpanel: subpanel,
                layoutElements,
                parentBounds,
                previewType,
                options: {
                    definitionTreeNode,
                },
            });
            return yield getSubpanelLayoutResult(itemReference, undefined, layoutResultsSubpanel, previewType, parentBounds, fontRepositoryUrl);
        }
        throw new Error(`Item reference type ${itemReference.type} not supported!`);
    });
}
function getSubpanelLayoutResult(itemReference, cropFractions, layoutResultsSubpanel, previewType, parentBounds, fontRepositoryUrl) {
    return __awaiter(this, void 0, void 0, function* () {
        var _a, _b, _c, _d, _e, _f, _g;
        try {
            // Use bounding box of the itemreference position
            const boundingBox = computeBoundsFromPosition({ position: itemReference.position });
            const originalScaleTransform = itemReference.scale;
            let positioningTransform = Matrix.identity();
            let transform = Matrix.identity();
            // Scale subpanel into itemreference position dimensions
            const scaleMatrix = Matrix.scale(parseMM(itemReference.position.width) / layoutResultsSubpanel.measurementData.boundingBox.width, parseMM(itemReference.position.height) / layoutResultsSubpanel.measurementData.boundingBox.height);
            positioningTransform = Matrix.multiply(positioningTransform, scaleMatrix);
            if (cropFractions !== undefined) {
                positioningTransform = Matrix.multiply(positioningTransform, getCropFractionsTransform(cropFractions, itemReference.position));
            }
            positioningTransform = Matrix.multiply(positioningTransform, Matrix.translate(boundingBox.left, boundingBox.top));
            transform = Matrix.multiply(transform, buildTransform({
                bounds: boundingBox,
                scale: itemReference.scale,
                rotationAngle: itemReference.rotationAngle,
                itemTransforms: itemReference.transforms,
                matrixTransform: itemReference.transform,
            }));
            const measurementData = getMeasurementData({
                itemType: "itemReference",
                boundingBox: boundingBox,
                tightBounds: boundingBox,
                transform,
                scaleTransform: originalScaleTransform,
            });
            if (previewType === "item") {
                transform = measurementData.itemPreviewTransform;
            }
            let clip;
            if (itemReference.clipping && itemReference.clipping.specification.type === "svgPathData") {
                // Remove the scale matrix from the clip, it's not needed
                const clipTransform = buildTransform({
                    bounds: boundingBox,
                    rotationAngle: itemReference.rotationAngle,
                    itemTransforms: itemReference.transforms,
                    translateToBounds: true,
                });
                const unit = (_a = itemReference.clipping.specification.unit) !== null && _a !== void 0 ? _a : "mm";
                const [svgPath] = parsePathData({
                    pathData: (_b = itemReference.clipping.specification.data) !== null && _b !== void 0 ? _b : "",
                    pixelSize: 1,
                    svgPathDataUnit: unit,
                });
                const clipBounds = boundingBoxFromPath({
                    path: svgPath,
                });
                const clipMeasurements = getMeasurementData({
                    itemType: "itemReference",
                    boundingBox: clipBounds,
                    tightBounds: clipBounds,
                    transform: clipTransform,
                });
                clip = yield getClip(itemReference, parentBounds, previewType === "item" ? clipMeasurements.itemPreviewTransform : clipTransform, fontRepositoryUrl);
            }
            const totalTransform = Matrix.multiply(positioningTransform, transform);
            return {
                id: itemReference.id,
                measurementData: {
                    boundingBox: (_c = clip === null || clip === void 0 ? void 0 : clip.boundingBox) !== null && _c !== void 0 ? _c : measurementData.measurementData.boundingBox,
                    previewBox: (_d = clip === null || clip === void 0 ? void 0 : clip.boundingBox) !== null && _d !== void 0 ? _d : measurementData.measurementData.previewBox,
                    layoutBox: (_e = clip === null || clip === void 0 ? void 0 : clip.boundingBox) !== null && _e !== void 0 ? _e : measurementData.measurementData.layoutBox,
                },
                renderingOperation: {
                    type: "group",
                    contents: [layoutResultsSubpanel],
                    transform: totalTransform,
                    opacityMultiplier: (_f = itemReference.opacityMultiplier) !== null && _f !== void 0 ? _f : 1,
                    clip,
                    crop: cropFractions !== undefined ? getCropFractionsClip(measurementData.measurementData.boundingBox, transform) : undefined,
                },
                status: {
                    mode: "local",
                },
            };
        }
        catch (ex) {
            if (ex instanceof Error) {
                const error = (_g = ex.name) !== null && _g !== void 0 ? _g : "Unknown";
                throw new Error(`Failed with error: ${error} to handle item reference type ${itemReference.type}`);
            }
            else {
                throw new Error(`Failed with an unknown error to handle item reference type ${itemReference.type}`);
            }
        }
    });
}
function getCropFractionsTransform(cropFractions, position) {
    const positionBoundingBox = {
        left: parseMM(position.x),
        top: parseMM(position.y),
        width: parseMM(position.width),
        height: parseMM(position.height),
    };
    const cropLeft = parseFloat(cropFractions.left) * positionBoundingBox.width;
    const cropTop = parseFloat(cropFractions.top) * positionBoundingBox.height;
    const cropWidth = (1 - parseFloat(cropFractions.right) - parseFloat(cropFractions.left)) * positionBoundingBox.width;
    const cropHeight = (1 - parseFloat(cropFractions.bottom) - parseFloat(cropFractions.top)) * positionBoundingBox.height;
    const cropBoundingBox = {
        left: cropLeft,
        top: cropTop,
        width: cropWidth,
        height: cropHeight,
    };
    let transform = Matrix.identity();
    // Remember we assume that the item ref is at (0, 0) and buildTransform() will translate the item to its position
    transform = Matrix.multiply(transform, Matrix.translate(-cropBoundingBox.left, -cropBoundingBox.top));
    transform = Matrix.multiply(transform, Matrix.scale(positionBoundingBox.width / cropBoundingBox.width, positionBoundingBox.height / cropBoundingBox.height));
    return transform;
}
function getCropFractionsClip(bounds, transform) {
    return {
        path: `M${bounds.left} ${bounds.top} L${bounds.left + bounds.width} ${bounds.top} L${bounds.left + bounds.width} ${bounds.top + bounds.height} L${bounds.left} ${bounds.top + bounds.height}Z`,
        transform,
        boundingBox: bounds,
        isRelativeToItem: false,
        usesViewbox: false,
    };
}
