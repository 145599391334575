import { Matrix } from "../../utils/math/matrix";
import { getTransformedRotatedBoundingBox } from "../../utils/boundingBox";
import { Easings } from "./Easings";
import { parseMM } from "../../utils/unitHelper";
import { parseMS } from "../../utils/timeUnitHelper";
// TODO: It would be useful if the bounding box could be adjusted to support images showing the best resolution
// Maybe there will be other issues?
function applyToRange(easing, timePercent, startValue, endValue) {
    const percent = Easings[easing](timePercent);
    return startValue + percent * (endValue - startValue);
}
function applyMatrixOp(matrix, center, timePercent, op) {
    const easing = op.easing;
    switch (op.type) {
        case "translateX": {
            const value = applyToRange(easing, timePercent, parseMM(op.startValue), parseMM(op.endValue));
            return { matrix: Matrix.multiply(matrix, Matrix.translate(value, 0)), center: { x: center.x + value, y: center.y } };
        }
        case "translateY": {
            const value = applyToRange(easing, timePercent, parseMM(op.startValue), parseMM(op.endValue));
            return { matrix: Matrix.multiply(matrix, Matrix.translate(0, value)), center: { x: center.x, y: center.y + value } };
        }
        case "rotate": {
            const value = applyToRange(easing, timePercent, op.startValue, op.endValue);
            return { matrix: Matrix.multiply(matrix, Matrix.rotateAboutPoint((value * Math.PI) / 180, center.x, center.y)), center };
        }
        case "scaleX": {
            const value = applyToRange(easing, timePercent, op.startValue, op.endValue);
            return { matrix: Matrix.multiply(matrix, Matrix.scaleAboutPoint(value, 1, center)), center };
        }
        case "scaleY": {
            const value = applyToRange(easing, timePercent, op.startValue, op.endValue);
            return { matrix: Matrix.multiply(matrix, Matrix.scaleAboutPoint(1, value, center)), center };
        }
        case "scale": {
            const value = applyToRange(easing, timePercent, op.startValue, op.endValue);
            return { matrix: Matrix.multiply(matrix, Matrix.scaleAboutPoint(value, value, center)), center };
        }
    }
}
function applyAnimation(timeInMs, layout, layoutElement, animation) {
    var _a, _b, _c, _d, _e;
    const offsetInMs = timeInMs - parseMS((_a = animation.startTime) !== null && _a !== void 0 ? _a : "0ms");
    let sequenceItem = (_b = animation.sequence) === null || _b === void 0 ? void 0 : _b.find((i) => { var _a, _b; return offsetInMs >= parseMS((_a = i.offsetToStartTime) !== null && _a !== void 0 ? _a : "0ms") && offsetInMs <= parseMS((_b = i.offsetToStartTime) !== null && _b !== void 0 ? _b : "0ms") + parseMS(i.duration); });
    if (!sequenceItem) {
        sequenceItem = (_c = animation.sequence) === null || _c === void 0 ? void 0 : _c[0];
        if (sequenceItem && offsetInMs >= parseMS((_d = sequenceItem.offsetToStartTime) !== null && _d !== void 0 ? _d : "0ms")) {
            sequenceItem = animation.sequence[animation.sequence.length - 1];
        }
    }
    if (sequenceItem) {
        const timePercent = Math.max(Math.min((offsetInMs - parseMS((_e = sequenceItem.offsetToStartTime) !== null && _e !== void 0 ? _e : "0ms")) / parseMS(sequenceItem.duration), 1), 0);
        const opacityMultiplier = sequenceItem.operations.reduce((opacity, op) => (op.type === "opacity" ? opacity * applyToRange(op.easing, timePercent, op.startValue, op.endValue) : opacity), 1);
        const transformedPreviewBox = getTransformedRotatedBoundingBox(layoutElement.measurementData.previewBox);
        const { matrix } = sequenceItem.operations.reduce(({ matrix, center }, op) => (op.type !== "opacity" ? applyMatrixOp(matrix, center, timePercent, op) : { matrix, center }), {
            matrix: Matrix.identity(),
            center: {
                x: transformedPreviewBox.left + transformedPreviewBox.width / 2,
                y: transformedPreviewBox.top + transformedPreviewBox.height / 2,
            },
        });
        return {
            type: "group",
            contents: [Object.assign(Object.assign({}, layoutElement), { renderingOperation: layout })],
            opacityMultiplier: opacityMultiplier,
            transform: matrix,
        };
    }
    return layout;
}
export function generateAnimationFunction(item, parentBounds, layoutElement) {
    const animations = item.animations;
    if (animations === null || animations === void 0 ? void 0 : animations.length) {
        return (timeInMs) => {
            return animations.reduce((prev, curr) => applyAnimation(timeInMs, prev, layoutElement, curr), layoutElement.renderingOperation);
        };
    }
    return undefined;
}
