// Doc on colors: https://cimpress-support.atlassian.net/wiki/spaces/CI/pages/189628717/Colors+CDIF
import { parseColorForRender, parseColor as colorConverterParseColor, convertParsedColorToRenderColor, } from "@mcp-artwork/color-converter";
const RGBA_REGEX = /^rgba\(([0-9]{1,3}),\s*([0-9]{1,3}),\s*([0-9]{1,3}),\s*([0-9]{1,3})\)$/;
const PALETTE_REGEX = /(^palette\((.*)\))$/;
export const regExMatch = (value, matcher) => {
    const result = value.match(matcher);
    if (!result) {
        throw new Error(`Regex error for value ${value} with matcher ${matcher}`);
    }
    return result;
};
// Support for legacy premium finishes
export function sanitizeOverprint(overprint) {
    if (!overprint)
        return undefined;
    if (overprint.toLowerCase() === "foil") {
        return "Metallic";
    }
    if (overprint.toLowerCase() === "gloss") {
        return "RaisedInk";
    }
    return overprint;
}
export function parseColor(rawValue, colorPalette, alphaMultiplier) {
    if (rawValue.match(PALETTE_REGEX)) {
        if (colorPalette) {
            return parsePaletteColor(rawValue, colorPalette);
        }
        else {
            throw new Error("colorPalette is undefined");
        }
    }
    let result;
    // If there's an alpha multiplier try to apply that
    if (alphaMultiplier !== undefined) {
        const layoutColor = colorConverterParseColor(rawValue);
        if (layoutColor === undefined) {
            throw new TypeError(`Could not determine format for color: ${rawValue}`);
        }
        if (layoutColor.colorSpace === "rgb" || (layoutColor === null || layoutColor === void 0 ? void 0 : layoutColor.colorSpace) === "cmyk") {
            if (layoutColor.colorSpace == "rgb") {
                const colorRGB = layoutColor;
                const rawColor = colorRGB.value;
                if (rawColor.a === undefined) {
                    rawColor.a = alphaMultiplier * 255;
                }
                else {
                    rawColor.a = alphaMultiplier * rawColor.a;
                }
                result = convertParsedColorToRenderColor(rawValue, colorRGB);
            }
            else {
                // would be cmyk to get here
                const colorCMYK = layoutColor;
                const rawColor = colorCMYK.value;
                if (rawColor.a === undefined) {
                    rawColor.a = alphaMultiplier * 100;
                }
                else {
                    rawColor.a = alphaMultiplier * rawColor.a;
                }
                result = convertParsedColorToRenderColor(rawValue, colorCMYK);
            }
        }
    }
    else {
        result = parseColorForRender(rawValue);
    }
    if (result === undefined) {
        throw new TypeError(`Could not determine format for color: ${rawValue}`);
    }
    return result;
}
const parsePaletteColor = (rawValue, colorPalette) => {
    const [, , paletteColorName] = regExMatch(rawValue, PALETTE_REGEX);
    const paletteColor = colorPalette.palette[paletteColorName];
    if (!paletteColor) {
        throw new Error(`Palette color not found for ${rawValue} in colorPalette`);
    }
    return {
        type: "color",
        name: paletteColorName,
        display: parseColor(paletteColor.color, undefined).display,
        originalValue: rawValue,
    };
};
export const parseRgbaColorValues = (rgbaColor) => {
    if (RGBA_REGEX.test(rgbaColor)) {
        const [r, g, b, a] = regExMatch(rgbaColor, /[0-9]{1,4}/g);
        return { r: parseInt(r), g: parseInt(g), b: parseInt(b), a: parseFloat(a) };
    }
};
