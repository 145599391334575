var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { fetchHTMLImageElement } from "../utils/api/fetchImage";
const networkCache = new Map();
export const getNetworkCache = () => networkCache;
class InnerFetchError extends Error {
    constructor(message, status) {
        super(message);
        this.status = status;
        Object.setPrototypeOf(this, InnerFetchError.prototype);
    }
}
const innerFetch = (_a) => __awaiter(void 0, [_a], void 0, function* ({ url, fetchAsImage, init, remainingRetries, }) {
    var _b;
    const retry = () => __awaiter(void 0, void 0, void 0, function* () {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                innerFetch({ url, fetchAsImage, init, remainingRetries: remainingRetries - 1 })
                    .then(resolve)
                    .catch(reject);
            }, 100);
        });
    });
    if (fetchAsImage) {
        try {
            return fetchHTMLImageElement({ url });
        }
        catch (_c) {
            if (remainingRetries > 0) {
                return retry();
            }
            throw new Error(`Error while fetching image ${url}`);
        }
    }
    try {
        const response = yield fetch(url, init);
        if (!response.ok) {
            let message = "";
            try {
                // Don't call .text on image url
                if (!response.url.startsWith("https://rendering.documents.cimpress.io/v1/fusion/images:process?imageUri=") &&
                    !((_b = response.headers.get("Content-Type")) === null || _b === void 0 ? void 0 : _b.startsWith("image/"))) {
                    message = yield response.text();
                }
            }
            catch (_d) {
                // do nothing
            }
            throw new InnerFetchError(`Error while fetching ${url}. Response status ${response.status}. ${message}`, response.status);
        }
        return response;
    }
    catch (e) {
        if (remainingRetries > 0) {
            // Don't retry 4XX errors except 404
            const status = e.status;
            if (!(status >= 400 && status < 500 && status !== 404)) {
                return retry();
            }
        }
        throw e;
    }
});
export function fetchWithNetworkCache(_a) {
    return __awaiter(this, arguments, void 0, function* ({ url, init, fetchOptions, responseResolver, cacheOptions }) {
        var _b, _c, _d;
        if ((init === null || init === void 0 ? void 0 : init.method) && init.method !== "GET" && !(init.body instanceof FormData)) {
            // The body is not formData. Skip caching for now.
            // TODO: when this happens fix it so it will be cached
            const value = innerFetch({ url, fetchAsImage: (_b = fetchOptions === null || fetchOptions === void 0 ? void 0 : fetchOptions.fetchAsImage) !== null && _b !== void 0 ? _b : false, init, remainingRetries: 1 }).then((r) => __awaiter(this, void 0, void 0, function* () {
                return responseResolver ? yield responseResolver(r) : r;
            }));
            return value;
        }
        let cacheKey = url;
        if ((init === null || init === void 0 ? void 0 : init.body) instanceof FormData) {
            // Append the body of a non GET request to the cache key
            // For example: POST for larger documents
            for (const [key, value] of init.body.entries()) {
                cacheKey += ` ${key}-${value}`;
            }
        }
        if (!networkCache.has(cacheKey)) {
            const value = innerFetch({ url, fetchAsImage: (_c = fetchOptions === null || fetchOptions === void 0 ? void 0 : fetchOptions.fetchAsImage) !== null && _c !== void 0 ? _c : false, init, remainingRetries: 1 }).then((r) => __awaiter(this, void 0, void 0, function* () {
                return responseResolver ? yield responseResolver(r) : r;
            }));
            networkCache.set(cacheKey, { value, readFromCache: -1, important: (_d = cacheOptions === null || cacheOptions === void 0 ? void 0 : cacheOptions.important) !== null && _d !== void 0 ? _d : false });
            // Remove failed Promises from cache
            value.catch((e) => {
                // Keep all 4XX errors in cache except 404
                const status = e.status;
                if (!(status >= 400 && status < 500 && status !== 404)) {
                    networkCache.delete(cacheKey);
                }
            });
        }
        const cache = networkCache.get(cacheKey);
        networkCache.set(cacheKey, Object.assign(Object.assign({}, cache), { readFromCache: cache.readFromCache + 1, lastRead: Date.now() }));
        return cache.value;
    });
}
