import { distance, Matrix, transformBoundingBox } from "@rendering/plasma";
import { applyClip } from "./Clip";
import { getDestinationAndSourceDimensions } from "./dimensions/ImageDestinationAndSourceDimensions";
import { runFilters } from "./Filter";
import { releaseCanvasses } from "../utils/releaseCanvasses";
// Because the matrix passed in may rotate or skew the image,
// we approximate the size of the image we want based on the average diagonal length of the bounds,
// with the current transform applied.
const findClosestSizedImage = ({ layout, bounds, matrix, entry, }) => {
    let cropScalarX = 1;
    let cropScalarY = 1;
    if (layout.crop) {
        cropScalarX = 1 / (1 - (layout.crop.left + layout.crop.right));
        cropScalarY = 1 / (1 - (layout.crop.top + layout.crop.bottom));
    }
    // Inflate the bounds by the crop scalar to ensure the higher quality
    // base image is picked when cropping is used.
    const boundsRight = bounds.left + bounds.width * cropScalarX;
    const boundsBottom = bounds.top + bounds.height * cropScalarY;
    // Transform the image bounds to apply changes like rotation/skew
    const pointUL = matrix.transformPoint({ x: bounds.left, y: bounds.top });
    const pointBR = matrix.transformPoint({ x: boundsRight, y: boundsBottom });
    const pointUR = matrix.transformPoint({ x: boundsRight, y: bounds.top });
    const pointBL = matrix.transformPoint({ x: bounds.left, y: boundsBottom });
    // Set target length to be the max of the diagonals
    const leftDiagonal = distance(pointBR, pointUL);
    const rightDiagonal = distance(pointBL, pointUR);
    const targetLength = Math.max(leftDiagonal, rightDiagonal);
    // Start with the highest resolution image by default because it's possible
    // the resolution might be so low that it doesn't satisfy the first check below.
    let targetImage = entry.images[0];
    // Images come in order of highest to lowest resolution. The target image should be the lowest resolution
    // that exceeds the target length.
    for (const image of entry.images) {
        const currentLength = Math.sqrt(image.width * image.width + image.height * image.height);
        if (currentLength > targetLength) {
            targetImage = image;
        }
    }
    return targetImage;
};
export function paintImage({ context, layout, bounds, overprint, cacheCanvas, cacheContext }) {
    context.save();
    try {
        applyClip({ context, clip: layout.clip });
        context.globalAlpha = context.globalAlpha * layout.opacityMultiplier;
        // Scale the operation matrix
        const transform = layout.transform;
        const overprintEntry = layout.images.find((imageEntry) => imageEntry.overprint === overprint);
        if (!overprintEntry) {
            throw Error("Missing overprint entry");
        }
        const img = findClosestSizedImage({ layout, matrix: context.getTransform(), bounds, entry: overprintEntry });
        // Apply matrix transform
        context.transform(transform.a, transform.b, transform.c, transform.d, transform.x, transform.y);
        const { sx, sy, sw, sh, dx, dy, dw, dh } = getDestinationAndSourceDimensions({ image: img, bounds, crop: layout.crop });
        // Apply optional filters
        if (overprintEntry.filters && overprintEntry.filters.length > 0) {
            // Loads the image from the cached canvas (GPU -> GPU memory copy)
            loadImage(img, cacheCanvas, cacheContext);
            // Draw the processed image to the input canvas
            const filteredResult = runFilters(overprintEntry.filters, cacheCanvas);
            context.drawImage(filteredResult, sx, sy, sw, sh, dx, dy, dw, dh);
            releaseCanvasses([filteredResult]);
        }
        else {
            context.drawImage(img, sx, sy, sw, sh, dx, dy, dw, dh);
        }
    }
    finally {
        context.restore();
    }
}
// Draws a server side fallback image preview at its exact dimensions to reduce blurriness.
export function paintSSRImage({ context, layout, overprint }) {
    var _a;
    const overprintEntry = layout.images.find((imageEntry) => imageEntry.overprint === overprint);
    const boundingBox = layout.boundingBox;
    if (!overprintEntry) {
        throw Error("Missing overprint entry");
    }
    const image = overprintEntry.images[0];
    let imageWidth = image.width;
    let imageHeight = image.height;
    // This scale factor is mainly for clipping, since the clips that were attached to the item preview must also be scaled appropriately. We can
    // assume the scaling preserves aspect ratio.
    const scaleFactor = image.width / boundingBox.width;
    let left = 0;
    let top = 0;
    // We need to draw the image to its actual position if there is a panel-relative clip, since the clip should be at the origin
    // and not the item.
    if (layout.clip !== undefined) {
        left = scaleFactor * boundingBox.left;
        top = scaleFactor * boundingBox.top;
        if (layout.clip.isRelativeToItem) {
            context.transform(1, 0, 0, 1, -layout.clip.boundingBox.left * scaleFactor, -layout.clip.boundingBox.top * scaleFactor);
        }
    }
    applyClip({ context, clip: layout.clip });
    // All transforms are stripped from the item before it is sent to the server for fallback. If an item is scaled,
    // we request an appropriately scaled item preview from the rendering service. For item previews, we need to apply just
    // the skew (unsupported) and scale components of the transform, after getting the item back from the server.
    if ((_a = layout.SSRDeferredTransformData) === null || _a === void 0 ? void 0 : _a.scale) {
        // Revert the scale factor for the item preview. This is so that we could scale the item with the original scale transform
        imageWidth /= layout.SSRDeferredTransformData.itemPreviewScaleFactor;
        imageHeight /= layout.SSRDeferredTransformData.itemPreviewScaleFactor;
        const imageBounds = {
            left: 0,
            top: 0,
            width: imageWidth,
            height: imageHeight,
        };
        // Scale the item preview, but make sure to align it to the origin
        let scale = Matrix.scale(layout.SSRDeferredTransformData.scale.x, layout.SSRDeferredTransformData.scale.y);
        const scaledImageBounds = transformBoundingBox(imageBounds, scale);
        scale = Matrix.multiply(scale, Matrix.translate(-scaledImageBounds.left, -scaledImageBounds.top));
        context.transform(scale.a, scale.b, scale.c, scale.d, scale.x, scale.y);
    }
    context.drawImage(image, left, top, imageWidth, imageHeight);
}
// Draws the bitmap to a temporary cache. HtmlCanvas most likely has this bitmap in GPU memory already.
// This is done to avoid having to make the webGL pull the image from CPU memory
function loadImage(bitmap, cacheCanvas, cacheContext) {
    cacheCanvas.width = bitmap.width;
    cacheCanvas.height = bitmap.height;
    cacheContext.clearRect(0, 0, bitmap.width, bitmap.height);
    cacheContext.drawImage(bitmap, 0, 0, bitmap.width, bitmap.height);
}
