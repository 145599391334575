export class Line {
    constructor(start, end) {
        this.start = start;
        this.end = end;
    }
    isVertical() {
        return Math.abs(this.start.x - this.end.x) <= Line.EPSILON;
    }
    length() {
        return Math.sqrt(Math.pow(this.end.x - this.start.x, 2) + Math.pow(this.end.y - this.start.y, 2));
    }
    getPoint(t) {
        const differenceVector = {
            x: this.end.x - this.start.x,
            y: this.end.y - this.start.y,
        };
        return {
            x: this.start.x + t * differenceVector.x,
            y: this.start.y + t * differenceVector.y,
        };
    }
    truncate(amount, fromEnd) {
        const ratio = amount / this.length();
        if (ratio > 1) {
            throw new Error("Can't truncate this line");
        }
        if (fromEnd) {
            return new Line(this.start, this.getPoint(1 - ratio));
        }
        return new Line(this.getPoint(ratio), this.end);
    }
}
Line.EPSILON = 0.001;
export function distance(pt0, pt1) {
    return Math.sqrt((pt1.x - pt0.x) * (pt1.x - pt0.x) + (pt1.y - pt0.y) * (pt1.y - pt0.y));
}
