import { produce } from "immer";
import { parseColor } from "../../utils/paint/Color";
export const replaceColors = (layoutElements, colorOverrides, colorPalette) => {
    const parsedColorOverrides = colorOverrides.map((colorOverride) => {
        const keys = Object.keys(colorOverride);
        return keys.reduce((previous, currentKey) => {
            previous[currentKey] = parseColor(colorOverride[currentKey], colorPalette);
            return previous;
        }, {});
    });
    const returnV = produce(layoutElements, (draft) => {
        draft.forEach((layoutElement) => {
            if (layoutElement.renderingOperation.type === "drawPaths") {
                layoutElement.renderingOperation.paths.forEach((path) => {
                    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
                    if (((_a = path.fill) === null || _a === void 0 ? void 0 : _a.type) === "color") {
                        const originalValue = path.fill.originalValue;
                        const foundOverride = parsedColorOverrides.find((parsedColorOverride) => parsedColorOverride[originalValue]);
                        if (foundOverride) {
                            path.fill = foundOverride[originalValue];
                        }
                    }
                    if (((_b = path.fill) === null || _b === void 0 ? void 0 : _b.type) === "linearGradient" || ((_c = path.fill) === null || _c === void 0 ? void 0 : _c.type) === "radialGradient") {
                        for (let i = 0; i < path.fill.stops.length; i++) {
                            const originalValue = path.fill.stops[i].color.originalValue;
                            const foundOverride = parsedColorOverrides.find((parsedColorOverride) => parsedColorOverride[originalValue]);
                            if (foundOverride) {
                                path.fill.stops[i].color = foundOverride[originalValue];
                            }
                        }
                    }
                    if (((_e = (_d = path.stroke) === null || _d === void 0 ? void 0 : _d.fill) === null || _e === void 0 ? void 0 : _e.type) === "color") {
                        const originalValue = path.stroke.fill.originalValue;
                        const foundOverride = parsedColorOverrides.find((parsedColorOverride) => parsedColorOverride[originalValue]);
                        if (foundOverride) {
                            path.stroke.fill = foundOverride[originalValue];
                        }
                    }
                    if (((_g = (_f = path.stroke) === null || _f === void 0 ? void 0 : _f.fill) === null || _g === void 0 ? void 0 : _g.type) === "linearGradient" || ((_j = (_h = path.stroke) === null || _h === void 0 ? void 0 : _h.fill) === null || _j === void 0 ? void 0 : _j.type) === "radialGradient") {
                        for (let i = 0; i < path.stroke.fill.stops.length; i++) {
                            const originalValue = path.stroke.fill.stops[i].color.originalValue;
                            const foundOverride = parsedColorOverrides.find((parsedColorOverride) => parsedColorOverride[originalValue]);
                            if (foundOverride) {
                                path.stroke.fill.stops[i].color = foundOverride[originalValue];
                            }
                        }
                    }
                });
            }
        });
    });
    return returnV;
};
