var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { fetchWithNetworkCache } from "./cache/network";
import { initRText } from "./layout/text/initRText";
import { preFetch as colorPreFetch, setFetchMethod } from "@mcp-artwork/color-converter";
export const preFetch = (preFetchOptions) => __awaiter(void 0, void 0, void 0, function* () {
    if (!(preFetchOptions === null || preFetchOptions === void 0 ? void 0 : preFetchOptions.skipRText)) {
        yield initRText(false);
    }
    setColorConverterFetchMethod();
    yield colorPreFetch();
});
export function setColorConverterFetchMethod() {
    setFetchMethod(({ url, init, responseResolver }) => fetchWithNetworkCache({ url, init, responseResolver: (res) => responseResolver(res), cacheOptions: { important: true } }));
}
