import { CimpressDocument } from "@mcp-artwork/cimdoc-types-v2";
import { DebugOptions, ExperimentalOptions, Filter, TextOptions, VideoOptions } from "@rendering/plasma";
import { useState, useMemo, useEffect, useRef } from "react";
import { fusion, FusionReturn } from "../fusion/fusion";
import { OnPaintArgs } from "../types/OnPaintArgs";
import { Dimension } from "../utils/getPixelSize";
import { createRequestNumberGenerator } from "../utils/requestNumberGenerator";
import { createGetCanvas, GetCanvasArgs } from "../utils/getCanvas";
import { SelectorWithOverprints } from "../types/SelectorWithOverprints";

type UseFusionArgs = {
  cimDoc: CimpressDocument;
  dimension: Dimension;
  textOptions: TextOptions;
  onError: (e: unknown) => void;
  onPaint: (args: OnPaintArgs) => void;
  selectors: SelectorWithOverprints[];
  getCanvas?: (args: GetCanvasArgs) => HTMLCanvasElement;
  filters?: Filter[];
  debugOptions?: DebugOptions;
  experimentalOptions?: ExperimentalOptions;
  videoOptions?: VideoOptions;
  referrer: string;
};

export function useFusion({
  cimDoc,
  dimension,
  onError,
  onPaint,
  textOptions,
  selectors,
  getCanvas,
  filters,
  debugOptions,
  experimentalOptions,
  videoOptions,
  referrer,
}: UseFusionArgs): {
  loading: boolean;
} {
  const [loading, setLoading] = useState<boolean>(false);
  const layoutRequestNumberGenerator = useMemo(() => createRequestNumberGenerator(), []);
  const getCanvasDefault = useMemo(() => createGetCanvas(), []);
  const canvassesRef = useRef<Set<HTMLCanvasElement>>(new Set<HTMLCanvasElement>());

  useEffect(() => {
    return () => {
      // Reset canvasses to release memory on umount
      // https://pqina.nl/blog/canvas-area-exceeds-the-maximum-limit/
      // Width and height has to be 1, not 0 for this to work
      // eslint-disable-next-line react-hooks/exhaustive-deps
      canvassesRef.current.forEach((canvas) => {
        canvas.width = 1;
        canvas.height = 1;
        const ctx = canvas.getContext("2d");
        if (ctx) {
          ctx.clearRect(0, 0, 1, 1);
        }
      });
    };
  }, []);

  useEffect(() => {
    if (selectors.length === 0) {
      return;
    }

    const animationFrame = window.requestAnimationFrame(async () => {
      let results: FusionReturn | undefined;
      try {
        setLoading(true);
        results = await fusion({
          cimDoc,
          dimension,
          layoutRequestNumberGenerator,
          selectors,
          textOptions,
          getCanvas: getCanvas ?? getCanvasDefault,
          filters,
          debugOptions,
          experimentalOptions,
          videoOptions,
          referrer,
        });
      } catch (e) {
        onError(e);
      }

      // finally
      if (results) {
        // Save all canvasses used to the ref to clean them up later
        results.forEach(({ canvas, paintResultsOverprints }) => {
          canvassesRef.current.add(canvas);
          paintResultsOverprints?.forEach((overPrintResult) => {
            canvassesRef.current.add(overPrintResult.canvas);
          });
        });

        // results can be null when an other fusion request cancelled the previous one
        onPaint(results);
        setLoading(false);
      }
    });

    return () => {
      window.cancelAnimationFrame(animationFrame);
    };
  }, [
    cimDoc,
    dimension,
    textOptions,
    onError,
    onPaint,
    layoutRequestNumberGenerator,
    selectors,
    getCanvas,
    filters,
    debugOptions,
    experimentalOptions,
    getCanvasDefault,
    videoOptions,
    referrer,
  ]);

  return { loading };
}
