import { TileAugmenter } from "./TileAugmenter";
import { parseMM } from "../../unitHelper";
export class HalfbrickAugmenter extends TileAugmenter {
    constructor(tile, definitionPanel) {
        super(tile, definitionPanel);
        this.tileXoffset = tile.xOffset ? parseMM(tile.xOffset) : 0;
        this.tileYoffset = tile.yOffset ? parseMM(tile.yOffset) : 0;
        this.tileWidth = this.defpanelWidth + 2 * this.tileXoffset;
        this.tileHeight = 2 * (this.defpanelHeight + 2 * this.tileYoffset);
    }
    /// Half brick Tile will be created as follows
    /// | yoffset
    /// | xoffset |--------------------pattern-1---------------| xoffset |
    /// | yoffset
    /// | yoffset
    /// | half-patttern-2------| xoffset | xoffset |------half-pattern-3 |
    /// | yoffset
    augmentTilePatterns() {
        const patternWidth = this.defpanelWidth;
        const patternHeight = this.defpanelHeight;
        const pattern1Position = this.createBounds(this.tileXoffset, this.tileYoffset, patternWidth, patternHeight);
        this.augmentPattern(pattern1Position);
        const pattern2Position = this.createBounds(-patternWidth / 2, 3 * this.tileYoffset + patternHeight, patternWidth, patternHeight);
        this.augmentPattern(pattern2Position);
        const pattern3Position = this.createBounds(2 * this.tileXoffset + patternWidth / 2, 3 * this.tileYoffset + patternHeight, patternWidth, patternHeight);
        this.augmentPattern(pattern3Position);
    }
    getTileWidth() {
        return this.tileWidth;
    }
    getTileHeight() {
        return this.tileHeight;
    }
}
