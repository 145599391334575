import memoize from "lodash.memoize";
import { colorMatrixShader, hslShader, triangleBlurShader, vertexShader } from "./shaders/source";
import { bindTexture, buildProgram, executeShader, flushProgram, initializeProgram, loadCanvas } from "./shaders/utils";
// WebGL canvas for performing image filters
const getImageFilterCanvas = memoize(() => {
    var _a;
    const filterCanvas = document.createElement("canvas");
    filterCanvas.id = `fusion-image-filter-${Math.random()}`; // Add random to make sure the id is unique
    const glCtx = (_a = filterCanvas.getContext("webgl", { premultipliedAlpha: false })) !== null && _a !== void 0 ? _a : filterCanvas.getContext("experimental-webgl", { premultipliedAlpha: false });
    if (!glCtx) {
        throw new Error("WebGL not supported");
    }
    return { filterCanvas, glCtx: glCtx };
});
export function runFilters(filters, canvas) {
    const { filterCanvas, glCtx } = getImageFilterCanvas();
    const programs = loadShaders(glCtx);
    // Update the filter
    filterCanvas.width = canvas.width;
    filterCanvas.height = canvas.height;
    loadCanvas(glCtx, canvas);
    for (const filter of filters) {
        const program = programs[filter.type];
        const f1Uniforms = {};
        const v4fUniforms = {};
        if (filter.type === "colorMatrix") {
            v4fUniforms["r"] = filter.matrix[0];
            v4fUniforms["g"] = filter.matrix[1];
            v4fUniforms["b"] = filter.matrix[2];
            v4fUniforms["a"] = filter.matrix[3];
            v4fUniforms["t"] = filter.matrix[4];
        }
        else if (filter.type === "hslFilter") {
            f1Uniforms["hMult"] = filter.hueMultiplier;
            f1Uniforms["hOffset"] = filter.hueOffset;
            f1Uniforms["sMult"] = filter.saturationMultiplier;
            f1Uniforms["sOffset"] = filter.saturationOffset;
            f1Uniforms["lMult"] = filter.lightnessMultiplier;
            f1Uniforms["lOffset"] = filter.lightnessOffset;
        }
        else if (filter.type === "triangleBlur") {
            f1Uniforms["deltaX"] = filter.deltaX;
            f1Uniforms["deltaY"] = filter.deltaY;
        }
        else {
            throw Error("Unknown color filter!");
        }
        // Set the target program shader
        glCtx.useProgram(program);
        // Size the effect canvas to the current image
        const { vertexBuffer, fragmentBuffer } = initializeProgram(glCtx, program, canvas.width, canvas.height, f1Uniforms, v4fUniforms);
        // Runs the shader
        executeShader(glCtx);
        // Clear any buffer memory used by the shader
        flushProgram(glCtx, vertexBuffer, fragmentBuffer);
        // Chain filters together if there are more than one
        if (filters.length > 1) {
            loadCanvas(glCtx, filterCanvas);
        }
    }
    return filterCanvas;
}
const loadShaders = memoize((glCtx) => {
    // Bind a texture to the webgl context
    bindTexture(glCtx);
    const programs = {};
    programs["colorMatrix"] = buildProgram(glCtx, vertexShader, colorMatrixShader);
    programs["hslFilter"] = buildProgram(glCtx, vertexShader, hslShader);
    programs["triangleBlur"] = buildProgram(glCtx, vertexShader, triangleBlurShader);
    return programs;
}, () => {
    return "1"; // always cache
});
