import { TileAugmenter } from "./TileAugmenter";
import { HexagonCalculator } from "./HexagonCalculator";
import { parseMM } from "../../unitHelper";
import { HexagonSvgPathCreator } from "./SvgPathCreator";
export class HexagonTileAugmenter extends TileAugmenter {
    constructor(tile, definitionPanel) {
        super(tile, definitionPanel);
        // at least small space to make pattern visible
        this.defaultTileOffset = 1;
        this.tileOffset = tile.offset ? parseMM(tile.offset) : this.defaultTileOffset;
        this.side = this.calculateSide();
        this.hexWidth = this.getHexWidth(this.side);
        this.hexHeight = this.getHexHeight(this.side);
        this.tileWidth = this.hexWidth + this.side + 2 * this.tileOffset;
        this.tileHeight = this.hexHeight + this.tileOffset;
    }
    calculateSide() {
        const widthSide = this.defpanelWidth / 2;
        const heightSide = this.defpanelHeight / HexagonTileAugmenter.SquareRoot3;
        return widthSide < heightSide ? widthSide : heightSide;
    }
    getHexHeight(side) {
        // h = 2 a cos30 = sqrt(3) a
        return side * HexagonTileAugmenter.SquareRoot3;
    }
    getHexWidth(side) {
        return 2 * side;
    }
    /// Regular Hexagon Tile without seam will be created as follows
    augmentTilePatterns() {
        const hexagon = new HexagonCalculator(this.side, this.tileOffset);
        hexagon.getCenterPoints().forEach((hexCenter) => {
            const hexBound = this.createHexagonBoundBox(hexCenter.x, hexCenter.y, this.hexWidth, this.hexHeight);
            this.augmentPattern(hexBound, 1, undefined, new HexagonSvgPathCreator(hexCenter.x, hexCenter.y, this.hexWidth, this.hexHeight));
        });
    }
    createHexagonBoundBox(cX, cY, width, height) {
        const boundingBox = {
            left: cX - width / 2,
            top: cY - height / 2,
            width: width,
            height: height,
        };
        return boundingBox;
    }
    getTileWidth() {
        return this.tileWidth;
    }
    getTileHeight() {
        return this.tileHeight;
    }
}
// regular hexagon
HexagonTileAugmenter.SquareRoot3 = Math.sqrt(3);
