var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { maximum } from "../../utils/boundingBox";
import { Matrix } from "../../utils/math/matrix";
import { parseMM, toRadians } from "../../utils/unitHelper";
import { getClip } from "../helpers/Clip";
import { buildTransform, transformBoundingBox } from "../helpers/Transform";
import { getMeasurementData } from "../measurements/measurementData";
export function subpanelLayout(_a) {
    return __awaiter(this, arguments, void 0, function* ({ subpanel, layoutElements, parentBounds, previewType, }) {
        var _b, _c, _d, _e;
        const originalScaleTransform = subpanel.scale;
        // Use parentbounds for empty subpanels
        const boundingBox = getBoundingBox(subpanel, layoutElements, parentBounds, true);
        const untranslatedBoundingBox = getBoundingBox(subpanel, layoutElements, parentBounds, false);
        let transform = Matrix.translate(parseMM(subpanel.position.x), parseMM(subpanel.position.y));
        transform = Matrix.multiply(transform, buildTransform({
            bounds: boundingBox,
            skew: subpanel.skew,
            scale: subpanel.scale,
            rotationAngle: subpanel.rotationAngle,
            matrixTransform: subpanel.transform,
            itemTransforms: subpanel.transforms,
        }));
        const measurementDataResponse = getMeasurementData({
            boundingBox: untranslatedBoundingBox,
            tightBounds: untranslatedBoundingBox,
            transform: transform,
            scaleTransform: originalScaleTransform,
            itemType: "subpanel",
        });
        const clipPath = yield getClip(subpanel, parentBounds, transform);
        if (previewType === "item") {
            transform = measurementDataResponse.itemPreviewTransform;
        }
        return {
            id: subpanel.id,
            measurementData: {
                layoutBox: (_b = clipPath === null || clipPath === void 0 ? void 0 : clipPath.boundingBox) !== null && _b !== void 0 ? _b : measurementDataResponse.measurementData.layoutBox,
                previewBox: (_c = clipPath === null || clipPath === void 0 ? void 0 : clipPath.boundingBox) !== null && _c !== void 0 ? _c : measurementDataResponse.measurementData.previewBox,
                boundingBox: (_d = clipPath === null || clipPath === void 0 ? void 0 : clipPath.boundingBox) !== null && _d !== void 0 ? _d : measurementDataResponse.measurementData.boundingBox,
            },
            renderingOperation: {
                type: "group",
                contents: layoutElements,
                transform: transform,
                clip: clipPath,
                opacityMultiplier: (_e = subpanel.opacityMultiplier) !== null && _e !== void 0 ? _e : 1,
            },
            status: {
                mode: "local",
            },
        };
    });
}
function getTransformedPreviewBox(element) {
    var _a;
    const measurementData = element.measurementData;
    const previewBox = measurementData.previewBox;
    const transform = Matrix.rotateAboutCenter(toRadians((_a = previewBox.rotation) !== null && _a !== void 0 ? _a : "0"), previewBox);
    return transformBoundingBox(previewBox, transform);
}
/**
 * Gets the bounding box of the subpanel by aggregating subpanel's items.
 * @param subpanel
 * @param layoutElements
 * @param parentBounds
 * @param includePosition
 * @returns
 */
function getBoundingBox(subpanel, layoutElements, parentBounds, includePosition) {
    const boundingBox = layoutElements.length > 0 ? maximum(layoutElements.map((element) => getTransformedPreviewBox(element))) : parentBounds;
    if (includePosition) {
        return Object.assign(Object.assign({}, boundingBox), { left: boundingBox.left + parseMM(subpanel.position.x), top: boundingBox.top + parseMM(subpanel.position.y) });
    }
    else {
        return boundingBox;
    }
}
