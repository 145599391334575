var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import svgpath from "svgpath";
import { computeBoundsFromPosition } from "../../utils/boundingBox";
import { parseMM } from "../../utils/unitHelper";
import { getClip } from "../helpers/Clip";
import { parseFill, parseOverprints } from "../helpers/Paint";
import { getLayoutStroke } from "../helpers/Stroke";
import { buildTransform } from "../helpers/Transform";
import { getMeasurementData } from "../measurements/measurementData";
const ellipseToPath = ({ x, y, width, height }) => {
    const cx = width / 2;
    const cy = height / 2;
    const rx = width / 2;
    const ry = height / 2;
    const baseEllipse = `M${cx - rx},${cy}a${rx},${ry} 0 1,0 ${rx * 2},0a${rx},${ry} 0 1,0 -${rx * 2},0 Z`;
    const translatedEllipse = svgpath(baseEllipse).translate(x, y);
    return translatedEllipse.toString();
};
export function ellipseLayout(_a) {
    return __awaiter(this, arguments, void 0, function* ({ ellipse, parentBounds, options, previewType, }) {
        var _b, _c, _d, _e, _f, _g;
        const strokePadding = parseMM((_c = (_b = ellipse.stroke) === null || _b === void 0 ? void 0 : _b.thickness) !== null && _c !== void 0 ? _c : "0mm") / 2;
        const originalScaleTransform = ellipse.scale;
        const originalBoundingBox = computeBoundsFromPosition({ position: ellipse.position, strokePadding });
        const itemPreviewTransform = buildTransform({
            bounds: originalBoundingBox,
            skew: ellipse.skew,
            scale: ellipse.scale,
            rotationAngle: ellipse.rotationAngle,
            matrixTransform: ellipse.transform,
            itemTransforms: ellipse.transforms,
        });
        const bounds = computeBoundsFromPosition({ position: ellipse.position, strokePadding });
        let transform = buildTransform({
            bounds: bounds,
            skew: ellipse.skew,
            scale: ellipse.scale,
            rotationAngle: ellipse.rotationAngle,
            matrixTransform: ellipse.transform,
            itemTransforms: ellipse.transforms,
        });
        const clipTransform = buildTransform({ bounds, rotationAngle: ellipse.rotationAngle, itemTransforms: ellipse.transforms, translateToBounds: true });
        const clipPath = yield getClip(ellipse, parentBounds, clipTransform);
        const measurementDataResponse = getMeasurementData({
            boundingBox: bounds,
            tightBounds: bounds,
            transform: itemPreviewTransform,
            scaleTransform: originalScaleTransform,
            itemType: "shape",
        });
        if (previewType === "item") {
            transform = measurementDataResponse.itemPreviewTransform;
        }
        const renderingOperation = {
            type: "drawPaths",
            clip: clipPath,
            paths: [
                {
                    path: ellipseToPath({
                        x: parseMM(ellipse.position.x),
                        y: parseMM(ellipse.position.y),
                        width: parseMM(ellipse.position.width),
                        height: parseMM(ellipse.position.height),
                    }),
                    transform,
                    fill: yield parseFill(ellipse.color, {
                        definitionTreeNode: options.definitionTreeNode,
                        itemBounds: bounds,
                        colorPalette: options.colorPalette,
                        fontRepositoryUrl: options.fontRepositoryUrl,
                    }),
                    overprints: parseOverprints(ellipse.overprints, options.colorPalette),
                    stroke: yield getLayoutStroke(ellipse.stroke, {
                        definitionTreeNode: options.definitionTreeNode,
                        itemBounds: bounds,
                        colorPalette: options.colorPalette,
                    }),
                },
            ],
            opacityMultiplier: (_d = ellipse.opacityMultiplier) !== null && _d !== void 0 ? _d : 1,
        };
        return {
            id: ellipse.id,
            status: { mode: "local" },
            measurementData: {
                boundingBox: (_e = clipPath === null || clipPath === void 0 ? void 0 : clipPath.boundingBox) !== null && _e !== void 0 ? _e : measurementDataResponse.measurementData.boundingBox,
                previewBox: (_f = clipPath === null || clipPath === void 0 ? void 0 : clipPath.boundingBox) !== null && _f !== void 0 ? _f : measurementDataResponse.measurementData.previewBox,
                layoutBox: (_g = clipPath === null || clipPath === void 0 ? void 0 : clipPath.boundingBox) !== null && _g !== void 0 ? _g : measurementDataResponse.measurementData.layoutBox,
            },
            renderingOperation,
        };
    });
}
