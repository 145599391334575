var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { boundingBoxFromPath } from "../../utils/boundingBox";
import { parsePathData } from "../../utils/parsePathData";
import { parseMM } from "../../utils/unitHelper";
import { getLayoutStroke } from "../helpers/Stroke";
import { buildTransform } from "../helpers/Transform";
import { parseFill, parseOverprints } from "../helpers/Paint";
import { getClip } from "../helpers/Clip";
import { curveLegacyToCurve } from "../../utils/curveLegacy";
import { getMeasurementData } from "../measurements/measurementData";
export function curveLayout(_a) {
    return __awaiter(this, arguments, void 0, function* ({ curve: curveItem, parentBounds, options, previewType, }) {
        var _b, _c, _d, _e, _f, _g;
        const curve = curveLegacyToCurve({ curve: curveItem });
        const { scaleX, scaleY, translateX, translateY } = calculateViewboxTransform(curve);
        // Use scale and translate to adjust position using the viewbox
        const [svgPath, path] = parsePathData({
            pathData: (_b = curve.svgPathData) !== null && _b !== void 0 ? _b : "",
            pixelSize: 1,
            svgPathDataUnit: (_c = curve.svgPathDataUnit) !== null && _c !== void 0 ? _c : "pt",
            closeBehavior: curve.closeBehavior,
            scaleX,
            scaleY,
            translateX,
            translateY,
        });
        const originalScaleTransform = curve.scale;
        const originalBoundingBox = boundingBoxFromPath({
            path: svgPath,
            stroke: curve.stroke,
        });
        const itemPreviewTransform = buildTransform({
            bounds: originalBoundingBox,
            skew: curve.skew,
            scale: curve.scale,
            rotationAngle: curve.rotationAngle,
            matrixTransform: curve.transform,
            itemTransforms: curve.transforms,
        });
        // Get bounding box from the scaled path data
        const boundingBox = boundingBoxFromPath({
            path: svgPath,
            stroke: curve.stroke,
        });
        let transform = buildTransform({
            bounds: boundingBox,
            skew: curve.skew,
            scale: curve.scale,
            rotationAngle: curve.rotationAngle,
            matrixTransform: curve.transform,
            itemTransforms: curve.transforms,
        });
        const clipTransform = buildTransform({
            bounds: boundingBox,
            rotationAngle: curve.rotationAngle,
            itemTransforms: curve.transforms,
            translateToBounds: true,
        });
        const clipPath = yield getClip(curve, parentBounds, clipTransform);
        const measurementDataResponse = getMeasurementData({
            boundingBox: boundingBox,
            tightBounds: boundingBox,
            transform: itemPreviewTransform,
            scaleTransform: originalScaleTransform,
            itemType: "shape",
        });
        if (previewType === "item") {
            transform = measurementDataResponse.itemPreviewTransform;
        }
        const renderingOperation = {
            type: "drawPaths",
            clip: clipPath,
            paths: [
                {
                    path,
                    transform,
                    fill: yield parseFill(curve.color, {
                        definitionTreeNode: options.definitionTreeNode,
                        itemBounds: boundingBox,
                        colorPalette: options.colorPalette,
                        fontRepositoryUrl: options.fontRepositoryUrl,
                    }),
                    fillRule: curve.windingType === "evenOdd" ? "evenodd" : "nonzero",
                    overprints: parseOverprints(curve.overprints, options.colorPalette),
                    stroke: yield getLayoutStroke(curve.stroke, {
                        definitionTreeNode: options.definitionTreeNode,
                        itemBounds: boundingBox,
                        colorPalette: options.colorPalette,
                    }),
                },
            ],
            opacityMultiplier: (_d = curve.opacityMultiplier) !== null && _d !== void 0 ? _d : 1,
        };
        return {
            id: curveItem.id,
            status: { mode: "local" },
            measurementData: {
                boundingBox: (_e = clipPath === null || clipPath === void 0 ? void 0 : clipPath.boundingBox) !== null && _e !== void 0 ? _e : measurementDataResponse.measurementData.boundingBox,
                previewBox: (_f = clipPath === null || clipPath === void 0 ? void 0 : clipPath.boundingBox) !== null && _f !== void 0 ? _f : measurementDataResponse.measurementData.previewBox,
                layoutBox: (_g = clipPath === null || clipPath === void 0 ? void 0 : clipPath.boundingBox) !== null && _g !== void 0 ? _g : measurementDataResponse.measurementData.layoutBox,
            },
            renderingOperation,
        };
    });
}
export function calculateViewboxTransform(curve) {
    if (!(curve.viewBox && curve.position)) {
        return { scaleX: 1, scaleY: 1, translateX: 0, translateY: 0 };
    }
    const width = parseMM(curve.position.width);
    const viewBoxWidth = parseMM(curve.viewBox.width);
    const height = parseMM(curve.position.height);
    const viewBoxHeight = parseMM(curve.viewBox.height);
    const scaleX = width / viewBoxWidth;
    const scaleY = height / viewBoxHeight;
    const translateX = parseMM(curve.position.x) - parseMM(curve.viewBox.x) * scaleX;
    const translateY = parseMM(curve.position.y) - parseMM(curve.viewBox.y) * scaleY;
    return { scaleX, scaleY, translateX, translateY };
}
