var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { parseMM } from "../../utils/unitHelper";
import { parseFill, parseOverprints } from "./Paint";
export function getLayoutStroke(stroke, options) {
    return __awaiter(this, void 0, void 0, function* () {
        var _a, _b;
        if (stroke) {
            return {
                fill: yield parseFill(stroke.color, options),
                overprints: parseOverprints(stroke.overprints, options.colorPalette),
                width: parseMM((_a = stroke.thickness) !== null && _a !== void 0 ? _a : "0mm"),
                lineJoin: parseLineJoin(stroke.lineJoin),
                lineCap: parseLineCap(stroke.lineCap),
                dashArray: (_b = stroke.dashPattern) === null || _b === void 0 ? void 0 : _b.segments.map((segment) => parseMM(segment.length)),
            };
        }
        return undefined;
    });
}
function parseLineCap(lineCap) {
    if (lineCap) {
        return lineCap.toLowerCase();
    }
    // Default
    return "round";
}
function parseLineJoin(lineJoin) {
    if (lineJoin) {
        return lineJoin.toLowerCase();
    }
    // Default
    return "round";
}
