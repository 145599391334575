var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { parseMM } from "../../utils/unitHelper";
import { buildInteractiveRequest, interpretTextArea } from "./RTextTranslation";
import { computeTextAreaBounds, expandBoundingBox, maximum } from "../../utils/boundingBox";
import { computeExtraBounds, getCaretLines, getGlyphRunOverrides, getMeasurements, getTextBoundaries } from "./TextMeasurements";
import { buildTransform } from "../helpers/Transform";
import { getClip } from "../helpers/Clip";
import { timestamp } from "../../utils/time";
import { log } from "../../utils/log";
import { initRText } from "./initRText";
import { buildRenderingOperations } from "./renderingOperations";
import { applyPathToCarets, applyPathToGlyphBounds, applyPathToText, getTransformedPiecewisePath } from "./textOnPath/textPathProcessor";
import { Matrix } from "../../utils/math/matrix";
import cloneDeep from "lodash.clonedeep";
import { getMeasurementData } from "../measurements/measurementData";
// This is the equivalent to the server side code Length.FromDtpPoints(1.25)
const PREVIEW_BOX_INFLATION = 1.25 * 0.352778;
export function textAreaLayout(_a) {
    return __awaiter(this, arguments, void 0, function* ({ textArea, textOptions, decorationTechnology, fontRepositoryUrl, parentBounds, trackTime, enableLog, options, previewType, }) {
        var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
        // Needs to be cloned deep because the properties may be modified
        textArea = cloneDeep(textArea);
        const fontFlavor = decorationTechnology.includes("embroidery") ? "embroidery" : "print";
        if (fontFlavor === "embroidery") {
            throw Error("Embroidered text cannot be rendered client-side!");
        }
        log({ message: "calling given initRText function", enabled: enableLog && !!(textOptions === null || textOptions === void 0 ? void 0 : textOptions.initRText) });
        const textEngine = (textOptions === null || textOptions === void 0 ? void 0 : textOptions.initRText) ? yield textOptions.initRText() : yield initRText(enableLog);
        const startTranslation = timestamp(trackTime);
        // Applying all the functionality around list handling
        const { listOrdinals, textFields, textBlocks, isNewParagraphTextBlock } = interpretTextArea(textArea, fontFlavor)
            // Remove the bew line characters in case of text along a path, and then remove blocks if empty.
            .reduce((outputItc, itc) => {
            outputItc.isNewParagraphTextBlock.push(itc.isNewParagraphTextBlock);
            outputItc.listOrdinals.push(itc.listOrdinal);
            outputItc.textBlocks.push(itc.textBlock);
            outputItc.textFields.push(itc.textField);
            return outputItc;
        }, { listOrdinals: [], textFields: [], textBlocks: [], isNewParagraphTextBlock: [] });
        let pieceWiseTransformedPath;
        if (textArea.textPath !== undefined) {
            pieceWiseTransformedPath = getTransformedPiecewisePath(textArea);
        }
        const request = buildInteractiveRequest(textArea, pieceWiseTransformedPath, textBlocks, fontFlavor, fontRepositoryUrl, textOptions);
        const endTranslation = timestamp(trackTime);
        const startRtext = timestamp(trackTime);
        log({ message: `calling rtext ${textArea.id}`, enabled: enableLog, objects: { request } });
        const engineResult = yield textEngine.process(request);
        try {
            const resultCollection = engineResult.to_obj();
            log({ message: `rtext result ${textArea.id}`, enabled: enableLog, objects: { result: resultCollection } });
            if (!resultCollection.results || resultCollection.results.length !== 1) {
                throw Error("Incorrect result from rtext");
            }
            const resultFonts = resultCollection.fonts;
            const result = resultCollection.results[0];
            // This will mutate text fields, replacing 'em' units in strokes with absolute measurement units
            convertEmUnits(textFields, result);
            const textAreaX = parseMM(textArea.position.x);
            const textAreaY = parseMM(textArea.position.y);
            let textPathData = undefined;
            if (pieceWiseTransformedPath !== undefined) {
                const glyphRunOutput = applyPathToText(textArea, textBlocks, pieceWiseTransformedPath, result, resultFonts);
                textPathData = {
                    glyphRuns: glyphRunOutput.glyphRuns,
                    piecewisePath: pieceWiseTransformedPath,
                    caretLines: (textOptions === null || textOptions === void 0 ? void 0 : textOptions.caretLines) ? applyPathToCarets(textArea, pieceWiseTransformedPath, result, glyphRunOutput.visibleGlyphs) : undefined,
                };
            }
            let itemPreviewTransform = Matrix.identity();
            const originalScaleTransform = textArea.scale;
            const boundingBox = computeTextAreaBounds({ textArea, engineResult: result });
            itemPreviewTransform = Matrix.multiply(itemPreviewTransform, buildTransform({
                bounds: boundingBox,
                skew: textArea.skew,
                scale: textArea.scale,
                rotationAngle: textArea.rotationAngle,
                matrixTransform: textArea.transform,
                itemTransforms: textArea.transforms,
            }));
            if (previewType === "item") {
                // This has to be set to undefined since in renderingOperations the scale, rotationAngle, and transform could be premultiplied in certain cases
                textArea.scale = undefined;
                textArea.rotationAngle = undefined;
                textArea.transform = undefined;
                textArea.transforms = undefined;
            }
            const endRtext = timestamp(trackTime);
            const startMeasurements = timestamp(trackTime);
            let textMeasurements;
            let previewBox;
            let backgroundPreviewBox;
            let pathInclusiveBox;
            let operations;
            let transform;
            let clipTransform;
            let filterBounds = [];
            let endMeasurements;
            let instructionStart;
            if (textArea.textPath === undefined) {
                instructionStart = timestamp(trackTime);
                ({ operations, transform, filterBounds, clipTransform, backgroundPreviewBox } = yield buildRenderingOperations({
                    textArea,
                    textFields,
                    result,
                    fonts: (_b = resultCollection.fonts) !== null && _b !== void 0 ? _b : [],
                    parentBounds,
                    options,
                }));
                textMeasurements = getMeasurements({
                    textFields,
                    textBlocks,
                    listOrdinals,
                    isNewParagraphTextBlock,
                    textArea,
                    result,
                    fonts: (_c = resultCollection.fonts) !== null && _c !== void 0 ? _c : [],
                    textOptions,
                    textOrientation: (_e = (_d = request.requests[0].textArea) === null || _d === void 0 ? void 0 : _d.textOrientation) !== null && _e !== void 0 ? _e : "horizontal",
                    textPathData,
                });
                const blackBoxBounds = result.blackBoxBounds;
                if (backgroundPreviewBox) {
                    previewBox = backgroundPreviewBox;
                }
                else {
                    previewBox = { left: blackBoxBounds.x + textAreaX, top: blackBoxBounds.y + textAreaY, width: blackBoxBounds.width, height: blackBoxBounds.height };
                }
                log({ message: `text measurements ${textArea.id}`, enabled: enableLog, objects: { textMeasurements } });
                endMeasurements = timestamp(trackTime);
                instructionStart = timestamp(trackTime);
            }
            else {
                endMeasurements = timestamp(trackTime);
                instructionStart = timestamp(trackTime);
                ({ operations, transform, filterBounds, clipTransform } = yield buildRenderingOperations({
                    textArea,
                    textFields,
                    result,
                    fonts: (_f = resultCollection.fonts) !== null && _f !== void 0 ? _f : [],
                    parentBounds,
                    textPathData,
                    options,
                }));
                if (!result.glyphRuns || !result.glyphRuns.length) {
                    previewBox = { left: 0, top: 0, width: 0, height: 0 };
                }
                else {
                    previewBox = getTextPathBoundingBox({
                        textArea: textArea,
                        path: pieceWiseTransformedPath,
                        resultFonts: resultFonts,
                        glyphRuns: result.glyphRuns,
                    });
                }
                previewBox = maximum([previewBox, ...filterBounds]);
                textMeasurements = {
                    actual: { width: boundingBox.width, height: boundingBox.height },
                    baselines: [],
                    snapBox: previewBox,
                    resizeFactor: result.resizeFactor,
                    caretLines: (textOptions === null || textOptions === void 0 ? void 0 : textOptions.caretLines) && textPathData && textPathData.caretLines
                        ? getCaretLines(textPathData.caretLines, textBlocks, listOrdinals, isNewParagraphTextBlock, textArea.blockFlowDirection)
                        : undefined,
                    textBoundaries: (textOptions === null || textOptions === void 0 ? void 0 : textOptions.requestTextBoundaries) ? getTextBoundaries((_g = result.textBoundaries) !== null && _g !== void 0 ? _g : [], textBlocks, listOrdinals) : undefined,
                    glyphRunOverrides: (textOptions === null || textOptions === void 0 ? void 0 : textOptions.glyphRunOverrides) && (textPathData === null || textPathData === void 0 ? void 0 : textPathData.glyphRuns)
                        ? getGlyphRunOverrides(result, textFields, (_h = resultCollection.fonts) !== null && _h !== void 0 ? _h : [], textPathData)
                        : undefined,
                };
            }
            let actualTransform = transform;
            let decorationBounds = [];
            if (textArea.textPath === undefined) {
                decorationBounds = computeExtraBounds({ textFields, result, fonts: (_j = resultCollection.fonts) !== null && _j !== void 0 ? _j : [], textAreaPosition: textArea.position });
            }
            const measurementDataResponse = getMeasurementData({
                boundingBox: boundingBox,
                scaleTransform: originalScaleTransform,
                tightBounds: previewBox,
                transform: itemPreviewTransform,
                stroke: getMaxStroke(textFields),
                extraBounds: [...filterBounds, ...decorationBounds],
                itemType: "textArea",
            });
            if (pieceWiseTransformedPath !== undefined) {
                const pathInclusiveBounds = pieceWiseTransformedPath.getPathInclusiveBounds(resultCollection, textArea.position);
                const pathInclusiveBoundsMeasurements = getMeasurementData({
                    boundingBox: boundingBox,
                    tightBounds: pathInclusiveBounds,
                    scaleTransform: originalScaleTransform,
                    transform: itemPreviewTransform,
                    itemType: "textArea",
                });
                pathInclusiveBox = pathInclusiveBoundsMeasurements.measurementData.previewBox;
                // Snap box of text along a path should be the path inclusive box, minus the position (so that it's consistent with normal text snap box)
                textMeasurements.snapBox = Object.assign({}, pathInclusiveBox);
                textMeasurements.snapBox.left -= textAreaX;
                textMeasurements.snapBox.top -= textAreaY;
            }
            if (previewType === "item") {
                actualTransform = measurementDataResponse.itemPreviewTransform;
                operations.forEach((op) => {
                    op.transform = Matrix.multiply(op.transform, actualTransform);
                });
            }
            const clipPath = yield getClip(textArea, parentBounds, clipTransform, fontRepositoryUrl);
            const instructionEnd = timestamp(trackTime);
            const layoutElement = Object.assign({ id: textArea.id, status: { mode: "local" }, measurementData: {
                    boundingBox: (_k = clipPath === null || clipPath === void 0 ? void 0 : clipPath.boundingBox) !== null && _k !== void 0 ? _k : measurementDataResponse.measurementData.boundingBox,
                    previewBox: (_l = clipPath === null || clipPath === void 0 ? void 0 : clipPath.boundingBox) !== null && _l !== void 0 ? _l : expandBoundingBox({ boundingBox: measurementDataResponse.measurementData.previewBox, amount: PREVIEW_BOX_INFLATION }),
                    layoutBox: (_m = clipPath === null || clipPath === void 0 ? void 0 : clipPath.boundingBox) !== null && _m !== void 0 ? _m : expandBoundingBox({ boundingBox: measurementDataResponse.measurementData.layoutBox, amount: PREVIEW_BOX_INFLATION }),
                    pathInclusiveBox,
                    textMeasurements,
                }, renderingOperation: { type: "drawPaths", paths: operations, clip: clipPath, opacityMultiplier: (_o = textArea.opacityMultiplier) !== null && _o !== void 0 ? _o : 1 } }, (trackTime &&
                endTranslation &&
                startTranslation &&
                endRtext &&
                startRtext &&
                instructionEnd &&
                instructionStart &&
                endMeasurements &&
                startMeasurements
                ? {
                    debugInfo: {
                        timers: {
                            translation: endTranslation - startTranslation,
                            rtext: endRtext - startRtext,
                            instructions: instructionEnd - instructionStart,
                            measurements: endMeasurements - startMeasurements,
                        },
                    },
                }
                : {}));
            return layoutElement;
        }
        finally {
            engineResult.free();
        }
    });
}
export function getTextPathBoundingBox({ textArea, path, resultFonts, glyphRuns, }) {
    const glyphBounds = applyPathToGlyphBounds(textArea, path, resultFonts, glyphRuns);
    if (glyphBounds.length === 0) {
        return { left: 0, top: 0, width: 0, height: 0 };
    }
    const boundingBox = maximum(glyphBounds);
    boundingBox.left += parseMM(textArea.position.x);
    boundingBox.top += parseMM(textArea.position.y);
    return boundingBox;
}
function getMaxStroke(textFields) {
    let maxStrokeThickness = 0;
    let maxStroke;
    let hasContent = false;
    if (textFields && textFields.length > 0) {
        textFields.forEach((field) => {
            var _a;
            if (field.content.length > 0) {
                hasContent = true;
            }
            if (((_a = field.stroke) === null || _a === void 0 ? void 0 : _a.thickness) !== undefined && parseMM(field.stroke.thickness) > maxStrokeThickness) {
                maxStrokeThickness = parseMM(field.stroke.thickness);
                maxStroke = field.stroke;
            }
        });
    }
    return hasContent ? maxStroke : undefined;
}
function convertEmUnits(textFields, resultCollection) {
    var _a, _b;
    const glyphRuns = resultCollection.glyphRuns;
    for (const glyphRun of glyphRuns) {
        const textField = textFields[glyphRun.textBlockIndex];
        if (((_b = (_a = textField === null || textField === void 0 ? void 0 : textField.stroke) === null || _a === void 0 ? void 0 : _a.thickness) === null || _b === void 0 ? void 0 : _b.endsWith("em")) === true) {
            // parseFloat('2em') -> 2
            const multiplier = parseFloat(textField.stroke.thickness);
            textField.stroke.thickness = `${(multiplier * glyphRun.fontSize)}mm`;
        }
    }
}
