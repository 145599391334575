import { parseMM } from "@rendering/plasma";
const EPSILON = 0.0001;
export const MAX_CANVAS_DIMENSION = 4000;
export const getLayoutBounds = (layoutResult) => {
    if (layoutResult.layoutType === "item") {
        return layoutResult.elements[0].measurementData.layoutBox;
    }
    return layoutResult.boundingBox;
};
export function computeDimensions({ pixelSize, layoutResult, ssr }) {
    const layoutBounds = getLayoutBounds(layoutResult);
    if (layoutBounds.width <= EPSILON || layoutBounds.height <= EPSILON) {
        throw Error("Layout bounds cannot be less than zero!");
    }
    const scalar = 1.0 / parseMM(pixelSize);
    // Compute the width and height
    const width = getCanvasDimension(layoutBounds.width * scalar, ssr);
    const height = getCanvasDimension(layoutBounds.height * scalar, ssr);
    // Ensure the canvas size is within the maximum allowed dimensions
    const widthScaleFactor = MAX_CANVAS_DIMENSION / width;
    const heightScaleFactor = MAX_CANVAS_DIMENSION / height;
    const minScaleFactor = Math.min(widthScaleFactor, heightScaleFactor);
    const targetScaleFactor = minScaleFactor < 1.0 ? minScaleFactor : 1;
    // Normalize the canvas size to maximum dimensions
    const outputWidth = Math.floor(width * targetScaleFactor);
    const outputHeight = Math.floor(height * targetScaleFactor);
    const outputScalar = scalar * targetScaleFactor;
    // Ensure the canvas is at least 1x1
    return { width: Math.max(outputWidth, 1), height: Math.max(outputHeight, 1), scalar: outputScalar };
}
const getCanvasDimension = (value, ssr) => {
    // Use the same bounds computation logic on the server side to guarantee output dimensions are the same for
    // baseline testing purposes. Note that this can cause single empty pixel gaps and shouldn't be used for production use-cases.
    if (ssr) {
        return Math.ceil(round(value, 3));
    }
    // Otherwise use a direct rounding method to ensure the canvas draws elements to the edges without gaps
    return Math.round(value);
};
const round = (value, places) => {
    const factor = Math.pow(10, places);
    return Math.round(value * factor) / factor;
};
