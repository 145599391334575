var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { fetchImage } from "../utils/api/image";
import { buildInstructionsRequest } from "../utils/api/instructionsClient";
import { buildRenderingRequest } from "../utils/api/renderingClient";
import { isWebPSupported } from "../layout/image/webP";
export function fetchServerFallback(_a) {
    return __awaiter(this, arguments, void 0, function* ({ document, pixelSize, instructionType, breakCache = false, overprint, referrer, scaleFactor, }) {
        // Build an instructions request from the document and preview type
        const instructionsRequest = buildInstructionsRequest({ document, previewType: instructionType });
        const renderingRequest = buildRenderingRequest({
            instructionsRequest,
            tenant: "fusion-fallback",
            pixelSize,
            format: (yield isWebPSupported()) ? "webp" : "png",
            breakCache,
            overprint,
            referrer,
            scaleFactor,
        });
        const imageInput = {
            url: renderingRequest.previewUri,
        };
        if (renderingRequest.instructionsPostData) {
            const formData = new FormData();
            formData.append("instructionsPostData", renderingRequest.instructionsPostData);
            imageInput.formData = formData;
        }
        const response = yield fetchImage(imageInput);
        if (response.type === "svg") {
            throw new Error("Server fallback failed. Unexpected SVG.");
        }
        return response;
    });
}
