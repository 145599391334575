import { toRadians } from "../unitHelper";
export class Matrix {
    constructor(a, b, c, d, x, y) {
        this.a = a;
        this.b = b;
        this.c = c;
        this.d = d;
        this.x = x;
        this.y = y;
    }
    isIdentity() {
        return this.a === 1 && this.b === 0 && this.c === 0 && this.d === 1 && this.x === 0 && this.y === 0;
    }
    copy() {
        return new Matrix(this.a, this.b, this.c, this.d, this.x, this.y);
    }
    static fromDOMMatrix(matrix) {
        return new Matrix(matrix.a, matrix.b, matrix.c, matrix.d, matrix.e, matrix.f);
    }
    static identity() {
        return new Matrix(1, 0, 0, 1, 0, 0);
    }
    static scale(scaleX, scaleY) {
        return new Matrix(scaleX, 0, 0, scaleY, 0, 0);
    }
    static scaleAboutPoint(scaleX, scaleY, point) {
        const translateToOrigin = Matrix.translate(-point.x, -point.y);
        const scale = Matrix.scale(scaleX, scaleY);
        const translateBack = Matrix.translate(point.x, point.y);
        return this.multiply(translateToOrigin, this.multiply(scale, translateBack));
    }
    static rotation(angle) {
        return new Matrix(Math.cos(angle), Math.sin(angle), -Math.sin(angle), Math.cos(angle), 0, 0);
    }
    static translate(x, y) {
        return new Matrix(1, 0, 0, 1, x, y);
    }
    static mirror(direction) {
        if (direction === "horizontal") {
            return new Matrix(-1, 0, 0, 1, 0, 0);
        }
        return new Matrix(1, 0, 0, -1, 0, 0);
    }
    static mirrorAboutPoint(direction, point) {
        const translateToOrigin = Matrix.translate(-point.x, -point.y);
        const mirror = Matrix.mirror(direction);
        const translateBack = Matrix.translate(point.x, point.y);
        return this.multiply(translateToOrigin, this.multiply(mirror, translateBack));
    }
    static multiply(m1, m2) {
        return new Matrix(m1.a * m2.a + m1.b * m2.c, m1.a * m2.b + m1.b * m2.d, m1.c * m2.a + m1.d * m2.c, m1.c * m2.b + m1.d * m2.d, m1.x * m2.a + m1.y * m2.c + m2.x, m1.x * m2.b + m1.y * m2.d + m2.y);
    }
    static rotateAboutCenter(angle, bounds) {
        const centerX = bounds.left + bounds.width / 2;
        const centerY = bounds.top + bounds.height / 2;
        return this.rotateAboutPoint(angle, centerX, centerY);
    }
    // https://math.stackexchange.com/questions/2093314/rotation-matrix-of-rotation-around-a-point-other-than-the-origin
    static rotateAboutPoint(angle, x, y) {
        const centerOrigin = this.translate(-x, -y);
        const rotationMatrix = this.rotation(angle);
        const translateOrigin = this.translate(x, y);
        const centeredRotation = this.multiply(centerOrigin, rotationMatrix);
        return this.multiply(centeredRotation, translateOrigin);
    }
    static skew(angle, axis) {
        const tan = Math.tan(angle);
        switch (axis) {
            case "y":
                return new Matrix(1, tan, 0, 1, 0, 0);
            case "x":
                return new Matrix(1, 0, tan, 1, 0, 0);
            default:
                throw Error("Invalid skew axis");
        }
    }
    static skewAboutPoint(x, y, point) {
        const centerOrigin = this.translate(-point.x, -point.y);
        const rotationMatrix = new Matrix(1, Math.tan(toRadians(y)), Math.tan(toRadians(x)), 1, 0, 0);
        const translateOrigin = this.translate(point.x, point.y);
        return this.multiply(centerOrigin, this.multiply(rotationMatrix, translateOrigin));
    }
    static decomposeAffineTransform(m) {
        const a = m.a;
        const b = m.b;
        const c = m.c;
        const d = m.d;
        const x = m.x;
        const y = m.y;
        const result = {
            rotation: 0,
            translation: {
                x: x,
                y: y,
            },
            scale: {
                x: 0,
                y: 0,
            },
            skew: {
                x: 0,
                y: 0,
            },
        };
        const delta = a * d - b * c;
        if (a != 0 || b != 0) {
            const r = Math.sqrt(a * a + b * b);
            result.rotation = b > 0 ? Math.acos(a / r) : -Math.acos(a / r);
            result.scale = { x: r, y: delta / r };
            result.skew = { x: Math.atan((a * c + b * d) / (r * r)), y: 0 };
        }
        else if (c != 0 || d != 0) {
            const s = Math.sqrt(c * c + d * d);
            result.rotation = Math.PI / 2 - (d > 0 ? Math.acos(-c / s) : -Math.acos(c / s));
            result.scale = { x: delta / s, y: s };
            result.skew = { x: 0, y: Math.atan((a * c + b * d) / (s * s)) };
        }
        return result;
    }
    toDOMMatrix() {
        return new DOMMatrix([this.a, this.b, this.c, this.d, this.x, this.y]);
    }
}
// TODO: Do we have matrix.b and matrix.c reversed?
export function transformPoint(matrix, point) {
    return {
        x: matrix.a * point.x + matrix.c * point.y + matrix.x,
        y: matrix.b * point.x + matrix.d * point.y + matrix.y,
    };
}
