var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { init } from "@mcp-artwork/rtext";
import once from "lodash.once";
import { log } from "../../utils/log";
export const initRText = once((logEnabled) => __awaiter(void 0, void 0, void 0, function* () {
    log({ message: "fetching rtext wasm resource", enabled: logEnabled });
    // Loads the mjs rtext wasm
    const textEngine = yield init("https://fusion.documents.cimpress.io/resources/rtext/8.0.1/rtext.wasm");
    log({ message: "done fetching rtext wasm resource", enabled: logEnabled });
    return textEngine;
}));
