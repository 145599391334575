var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getProjectionSpecification } from "../api/projectionClient";
import { mmToString } from "../unitHelper";
import { ERROR_MESSAGE_NO_PANEL_IN_PROJECTION, buildJsonProjector } from "./jsonProjector";
import { buildStaticProjector } from "./staticProjector";
export function getProjector(projectionId, pageNumber) {
    return __awaiter(this, void 0, void 0, function* () {
        switch (projectionId) {
            case undefined:
                return undefined;
            case "ROTATE_90":
            case "ROTATE_270":
            case "90_270_EVEN_ODD":
            case "90_270_ODD_EVEN":
            case "270_90_EVEN_ODD":
            case "270_90_ODD_EVEN":
                return buildStaticProjector(projectionId, pageNumber);
            default: {
                const specification = yield getProjectionSpecification(projectionId);
                return buildJsonProjector(specification, pageNumber);
            }
        }
    });
}
export const project = (projector, document) => {
    const matchingPanels = projector.getDesignSurfaces(document);
    if (matchingPanels.length === 0) {
        throw Error(ERROR_MESSAGE_NO_PANEL_IN_PROJECTION);
    }
    const targetSize = projector.determineTargetSize(matchingPanels);
    const name = projector.getTargetName(document);
    return {
        decorationTechnology: matchingPanels[0].decorationTechnology,
        id: name,
        name: name,
        width: mmToString(targetSize.width),
        height: mmToString(targetSize.height),
        subpanels: matchingPanels.map((info, i) => panelToSubpanel(projector, info, i, targetSize)),
    };
};
export const getPageSize = (projector, document) => {
    const matchingPanels = projector.getDesignSurfaces(document);
    if (matchingPanels.length === 0) {
        throw Error(ERROR_MESSAGE_NO_PANEL_IN_PROJECTION);
    }
    return projector.determineTargetSize(matchingPanels);
};
const panelToSubpanel = (projector, sourceSurface, i, targetSize) => {
    if (sourceSurface.clips && sourceSurface.clips.length > 0) {
        throw Error("Clips are not supported");
    }
    if (sourceSurface.simpleTextFields && sourceSurface.simpleTextFields.length > 0) {
        throw Error("SimpleTextFields are not supported");
    }
    const transforms = projector.getTransform(sourceSurface, i, targetSize);
    return {
        id: sourceSurface.id,
        position: { x: "0mm", y: "0mm" },
        images: sourceSurface.images,
        itemReferences: sourceSurface.itemReferences,
        name: sourceSurface.name,
        shapes: sourceSurface.shapes,
        textAreas: sourceSurface.textAreas,
        subpanels: sourceSurface.subpanels,
        videos: sourceSurface.videos,
        zIndex: i,
        transforms,
        clipping: projector.getClip(sourceSurface, i, transforms),
    };
};
