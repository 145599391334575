var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { fetchWithNetworkCache } from "../../cache/network";
import { imageLayout } from "../image/Layout";
import { parseMM } from "../../utils/unitHelper";
import { Matrix } from "../../utils/math/matrix";
import { getTransformedRotatedBoundingBox, maximum } from "../../utils/boundingBox";
export function ornamentLayout(_a) {
    return __awaiter(this, arguments, void 0, function* ({ ornament, parentBounds, previewType, referrer, imageOptions, colorPalette, }) {
        const ornamentConfig = yield fetchWithNetworkCache({
            url: `https://cdn.previews.cimpress.io/v2/ornaments/configs/${ornament.type}`,
            responseResolver: (response) => __awaiter(this, void 0, void 0, function* () { return (yield response.json()); }),
        });
        if (ornamentConfig.type === "Printed") {
            const imagesLayoutElements = yield Promise.all(ornament.positions.map((position, index) => imageLayout({
                image: {
                    id: `ornament-${ornament.type}-${index}`,
                    position: {
                        x: `${parseMM(position.x) - (ornamentConfig.widthCm * 10) / 2}mm`, // correct with center of origin
                        y: `${parseMM(position.y) - (ornamentConfig.heightCm * 10) / 2}mm`, // correct with center of origin
                        height: `${ornamentConfig.heightCm * 10}mm`,
                        width: `${ornamentConfig.widthCm * 10}mm`,
                    },
                    previewUrl: ornamentConfig.overlayUrl,
                    url: ornamentConfig.overlayUrl,
                },
                parentBounds,
                previewType,
                referrer,
                imageOptions,
                colorPalette,
            })));
            // Use the previewBox for all measurement data for now
            const previewBox = maximum(imagesLayoutElements.map((element) => getTransformedRotatedBoundingBox(element.measurementData.previewBox)));
            return {
                id: `ornaments-${ornament.type}`,
                renderingOperation: {
                    type: "group",
                    transform: Matrix.identity(),
                    contents: imagesLayoutElements,
                    opacityMultiplier: 1,
                },
                status: {
                    mode: "local",
                },
                measurementData: {
                    // Use the previewBox for all measurement data for now
                    boundingBox: previewBox,
                    previewBox,
                    layoutBox: previewBox,
                },
            };
        }
        // Skip other types of ornaments
        return undefined;
    });
}
