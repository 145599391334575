import { matrixToTransform } from "../../layout/helpers/Transform";
import { Matrix } from "../math/matrix";
import { parseMM } from "../unitHelper";
import { ERROR_MESSAGE_NO_PANEL_IN_PROJECTION } from "./jsonProjector";
export const buildStaticProjector = (specification, pageNumber) => {
    const rotationIsClockwise = shouldRotateClockwise(specification, pageNumber);
    const getDesignSurfaces = (document) => {
        var _a, _b;
        const panels = (_b = (_a = document.document.surfaces) !== null && _a !== void 0 ? _a : document.document.panels) !== null && _b !== void 0 ? _b : [];
        const targetPanel = panels[pageNumber - 1];
        if (!targetPanel) {
            throw new Error(ERROR_MESSAGE_NO_PANEL_IN_PROJECTION);
        }
        return [targetPanel];
    };
    return {
        getDesignSurfaces,
        determineTargetSize: (surfaces) => ({
            width: parseMM(surfaces[0].height),
            height: parseMM(surfaces[0].width),
        }),
        getTargetName: (document) => getDesignSurfaces(document)[0].name,
        getTransform: (sourceSurface, sourceIndex, targetSize) => {
            if (rotationIsClockwise) {
                return [matrixToTransform(new Matrix(0, 1, -1, 0, targetSize.width, 0))];
            }
            return [matrixToTransform(new Matrix(0, -1, 1, 0, 0, targetSize.height))];
        },
        getClip: () => undefined,
    };
};
function shouldRotateClockwise(specification, pageNumber) {
    switch (specification) {
        case "ROTATE_90":
            return true;
        case "ROTATE_270":
            return false;
        case "270_90_ODD_EVEN":
        case "90_270_EVEN_ODD":
            return pageNumber % 2 === 0;
        case "270_90_EVEN_ODD":
        case "90_270_ODD_EVEN":
            return pageNumber % 2 !== 0;
        default:
            throw Error("Unknown static projection");
    }
}
