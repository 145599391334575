/* vertex shader takes the vertices that represent a rectangular plane (2 triangles)
with the dimensions of the current image. It converts them to "clip space",
a representation of the same points in a space with dimensions between -1 and 1.
It also sets the v_texCoord variable to be used by the fragment shader.
*/
export const vertexShader = `
  attribute vec2 a_position;
  attribute vec2 a_texCoord;
  uniform vec2 u_resolution;
  varying vec2 v_texCoord;

  void main() {
    vec2 clipSpace = (a_position / u_resolution) * 2.0 - 1.0;
    gl_Position = vec4(clipSpace * vec2(1, -1), 0, 1);
    v_texCoord = a_texCoord;
  }
`;
/**
 * @filter      Color matrix
 * @description Multiplies the input color by a color matrix to generalize color manipulation methods
 * @param r     Floating point vector represent transforms to the red component
 * @param g     Floating point vector represent transforms to the green component
 * @param b     Floating point vector represent transforms to the blue component
 * @param a     Floating point vector represent transforms to the alpha component
 * @param t     Floating point vector represent translations to rgb
 */
export const colorMatrixShader = `
 precision mediump float;

 uniform vec4 r;
 uniform vec4 g;
 uniform vec4 b;
 uniform vec4 a;
 uniform vec4 t;

 uniform sampler2D u_image;
 varying vec2 v_texCoord;

 void main() {
   vec4 c = texture2D(u_image, v_texCoord);

   float rT = c.x * r.x + c.y * g.x + c.z * b.x + c.w * a.x + t.x;
   float gT = c.x * r.y + c.y * g.y + c.z * b.y + c.w * a.y + t.y;
   float bT = c.x * r.z + c.y * g.z + c.z * b.z + c.w * a.z + t.z;
   float aT = c.x * r.w + c.y * g.w + c.z * b.w + c.w * a.w + t.w;

   gl_FragColor = vec4(rT, gT, bT, aT);
 }
`;
/**
 * @filter        HSL filter
 * @description   Multiplies and offsets hue, saturation, and lightness components of the image
 * @param hMult   Floating point value for the hue multiplier
 * @param hOffset Floating point value for the hue offset
 * @param sMult   Floating point value for the saturation multiplier
 * @param sOffset Floating point value for the saturation offset
 * @param lMult   Floating point value for the lightness multiplier
 * @param lOffset Floating point value for the lightness offset
 */
export const hslShader = `
 precision mediump float;

 uniform float hMult;
 uniform float hOffset;
 uniform float sMult;
 uniform float sOffset;
 uniform float lMult;
 uniform float lOffset;

 uniform sampler2D u_image;
 varying vec2 v_texCoord;

 void hueToRgb(in float p, in float q, in float h, out float c);

 void main() {
   vec4 c = texture2D(u_image, v_texCoord);

   float maxChannel = max(c.r, max(c.g, c.b));
   float minChannel = min(c.r, min(c.g, c.b));

   float hue = 0.0;
   float saturation = 0.0;
   float lightness = (maxChannel + minChannel) / 2.0;

   // Convert RGB -> HSL
   if (maxChannel != minChannel)
   {
     float difference = maxChannel - minChannel;

     if (lightness > 0.5)
     {
      saturation = difference / (2.0 - maxChannel - minChannel);
     }
     else
     {
      saturation = difference / (maxChannel + minChannel);
     }

     if (c.r > c.g && c.r > c.b)
     {
      hue = (c.g - c.b) / difference + (c.g < c.b ? 6.0 : 0.0);
     }
     else if (c.g > c.b)
     {
      hue = (c.b - c.r) / difference + 2.0;
     }
     else
     {
      hue = (c.r - c.g) / difference + 4.0;
     }

     hue /= 6.0;
   }

   bool computeLightness = lOffset > 0.0 && (lightness == 0.0 || lMult == 0.0);

   // Determine if lightness must be computed in HSL space, otherwise defer it
   if (computeLightness)
   {
    lightness = clamp(lOffset, 0.0, 1.0);
   }

   // Apply hue and saturation shifts
   hue = hue * hMult + hOffset / 360.0;
   saturation = clamp(saturation * sMult + sOffset, 0.0, 1.0);

   // Convert back to RGB
   if (saturation == 0.0) // Achromatic
   {
     c.r = lightness; 
     c.g = lightness;
     c.b = lightness;
   }
   else
   {
     float q = lightness < 0.5 ? lightness * (1.0 + saturation) : lightness + saturation - lightness * saturation;
     float p = lightness * 2.0 - q;

     hueToRgb(p, q, hue + 1.0 / 3.0, c.r);
     hueToRgb(p, q, hue, c.g);
     hueToRgb(p, q, hue - 1.0 / 3.0, c.b);
   }

   // Apply the lightness multipliers and offsets in RGB space to avoid chroma issues
   if (!computeLightness)
   {
     c.r = clamp(c.r * lMult + lOffset, 0.0, 1.0);
     c.g = clamp(c.g * lMult + lOffset, 0.0, 1.0);
     c.b = clamp(c.b * lMult + lOffset, 0.0, 1.0);
   }

   gl_FragColor = c;
 }

 void hueToRgb(in float p, in float q, in float hue, out float c) 
 {
   float normalizedHue = hue;

   if (normalizedHue < 0.0)
   {
    normalizedHue += 1.0;
   }

   if (normalizedHue > 1.0)
   {
    normalizedHue -= 1.0;
   }

   if (normalizedHue < 1.0 / 6.0)
   {
     c = p + (q - p) * 6.0 * normalizedHue;
   }
   else if (normalizedHue < 0.5)
   {
     c = q;
   }
   else if (normalizedHue <  2.0 / 3.0)
   {
     c = p + (q - p) * (2.0 / 3.0 - normalizedHue) * 6.0;
   }
   else {
    c = p;
   }
 }
`;
/**
 * @filter      Triangle Blur
 * @description This is the most basic blur filter, which convolves the image with a
 *               pyramid filter. The pyramid filter is separable and is applied as two
 *               perpendicular triangle filters.
 * @param deltaX Horizontal blur
 * @param deltaY Vertical blur
 */
export const triangleBlurShader = `
 precision mediump float;

 uniform float deltaX;
 uniform float deltaY;

 uniform sampler2D u_image;
 varying vec2 v_texCoord;

 void main() {
   vec4 color = vec4(0.0);
   float total = 0.0;

   // Random number generator
   float offset = fract(sin(dot(gl_FragCoord.xyz, vec3(12.9898, 78.233, 151.7182))) * 43758.5453);

   for (float t = -30.0; t <= 30.0; t++) {
    float percent = (t + offset - 0.5) / 30.0;
    float weight = 1.0 - abs(percent);

    vec4 sample = texture2D(u_image, v_texCoord + vec2(deltaX, deltaY) * percent);
    sample.rgb *= sample.a;

    color += sample * weight;
    total += weight;
   }

   gl_FragColor = color / total;
   gl_FragColor.rgb /= gl_FragColor.a + 0.00001;
 }
`;
