import { imageValidation } from "./image/Validation";
import { itemReferenceValidation } from "./itemReference/Validation";
import { ornamentValidation } from "./ornament/Validation";
import { shapeValidation } from "./shapes/Validation";
import { subpanelValidation } from "./subpanel/Validation";
import { textAreaValidation } from "./text/Validation";
import { videoValidation } from "./video/Validation";
export function validateItem({ itemInfo, decoTech, experimentalOptions, textOptions, }) {
    const { itemType, item } = itemInfo;
    if (itemType === "image") {
        return imageValidation({ image: item, decoTech });
    }
    if (itemType === "itemReference") {
        return itemReferenceValidation({ itemReference: item });
    }
    if (itemType === "shape") {
        return shapeValidation({ shape: item, decoTech });
    }
    if (itemType === "subpanel") {
        return subpanelValidation({ subpanel: item });
    }
    if (itemType === "textArea") {
        return textAreaValidation({ textArea: item, decoTech, experimentalOptions, textOptions });
    }
    if (itemType === "video") {
        return videoValidation({ video: item });
    }
    if (itemType === "ornament") {
        return ornamentValidation({ ornament: item });
    }
    throw new Error(`Can't validate item of type ${itemType}`);
}
