/**
 * Vector format is [a, b]
 */
export class Vector2 {
    constructor(a, b) {
        this.a = a;
        this.b = b;
    }
    normalize() {
        const length = this.magnitude();
        return new Vector2(this.a / length, this.b / length);
    }
    dotProduct(other) {
        return this.a * other.a + this.b * other.b;
    }
    crossProduct(other) {
        return this.a * other.b - this.b * other.a;
    }
    magnitude() {
        return Math.sqrt(Math.pow(this.a, 2) + Math.pow(this.b, 2));
    }
}
