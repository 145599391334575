import { parseMM } from "../../utils/unitHelper";
import { BASE_INDENT_MULTIPLIER, containsUrl, translateFontStyle } from "./RTextTranslation";
const ORDINAL_TYPE = {
    number: 0,
    letter: 1,
    romanNumeral: 2,
};
const ROMAN_MAP = [
    { "1000": "M" },
    { "900": "CM" },
    { "500": "D" },
    { "400": "CD" },
    { "100": "C" },
    { "90": "XC" },
    { "50": "L" },
    { "40": "XL" },
    { "10": "X" },
    { "9": "IX" },
    { "5": "V" },
    { "4": "IV" },
    { "1": "I" },
];
const BULLET_GLYPH = "\u2022";
const BULLET_FONT = "https://fonts.documents.cimpress.io/shared/bulletfont";
const WHITE_SPACE = "";
const WHITE_SPACE_MULTIPLIER = 0.3;
const FIXED_INDENT_MULTIPLIER = 1.2;
export function getOrdinalCharacter(indentDepth, listDepth, uppercase) {
    // Determine the ordinal type from the depth of the current paragraph
    const ordinalType = (indentDepth - 1) % Object.entries(ORDINAL_TYPE).length;
    switch (ordinalType) {
        case ORDINAL_TYPE.number:
            return `${listDepth}.`;
        case ORDINAL_TYPE.letter:
            if (uppercase) {
                return `${String.fromCharCode(65 + ((listDepth % 26) - 1))}.`;
            }
            else {
                return `${String.fromCharCode(97 + ((listDepth % 26) - 1))}.`;
            }
        case ORDINAL_TYPE.romanNumeral:
            return `${getRomanNumeral(listDepth, uppercase)}.`;
    }
    throw Error("Unknown ordinal type");
}
export function getListOrdinal(listItem, parentFont, ordinalsPerDepth, depth) {
    var _a;
    const font = createChildFontInformation(listItem, parentFont);
    if (listItem.listMarkerStyle === undefined) {
        throw Error("List contents must have a listMarkerStyle!");
    }
    let targetOrdinal;
    // Determine the ordinal character for numbered and bulleted list
    switch (listItem.listMarkerStyle) {
        case "unordered": {
            targetOrdinal = BULLET_GLYPH;
            break;
        }
        case "ordered": {
            // Increment the depth of the current sub-list
            ordinalsPerDepth[depth - 1]++;
            // TODO figure out uppercase / lowercase detection
            targetOrdinal = getOrdinalCharacter(depth, ordinalsPerDepth[depth - 1], false);
            break;
        }
        default:
            throw Error(`Unknown list marker style: ${listItem.listMarkerStyle}!`);
    }
    if (font.size === undefined) {
        throw Error("A size must be present in list elements!");
    }
    const fontSize = parseMM(font.size);
    const baseIndent = fontSize * BASE_INDENT_MULTIPLIER;
    // Resetting the depth counts for all sub-lists
    for (let i = depth; i < ordinalsPerDepth.length; i++) {
        ordinalsPerDepth[i] = 0;
    }
    // First content containing the ordinal
    const interpretedTextContexts = [
        {
            listOrdinal: true,
            isNewParagraphTextBlock: false,
            textField: {
                id: "list ordinal",
                content: targetOrdinal,
                fontFamily: listItem.listMarkerStyle === "unordered" ? BULLET_FONT : font.family,
                fontStyle: listItem.listMarkerStyle === "unordered" ? "normal" : ((_a = font.style) !== null && _a !== void 0 ? _a : "normal"),
                fontSize: font.size,
                color: font.color,
                stroke: font.stroke,
            },
            textBlock: {
                content: targetOrdinal,
                fontSize: parseMM(font.size),
                fixed: {
                    width: fontSize * FIXED_INDENT_MULTIPLIER + baseIndent * (depth - 1),
                    textAlignment: "trailing",
                },
                paragraphCharacteristics: {
                    indent: baseIndent + baseIndent * (depth - 1),
                    paragraphIndent: -baseIndent - baseIndent * (depth - 1),
                },
                fontReferences: [buildOrdinalFontReference(listItem.listMarkerStyle, font)],
                whitespaceStripping: "none",
            },
        },
    ];
    // Add white space between ordinal and content
    interpretedTextContexts.push({
        listOrdinal: true,
        isNewParagraphTextBlock: false,
        textField: {
            id: "list whitespace",
            content: WHITE_SPACE,
            fontFamily: listItem.listMarkerStyle === "unordered" ? BULLET_FONT : font.family,
            fontStyle: "normal",
            fontSize: font.size,
            color: font.color,
        },
        textBlock: {
            content: WHITE_SPACE,
            fontSize: parseMM(font.size),
            fixed: {
                width: fontSize * WHITE_SPACE_MULTIPLIER,
                textAlignment: "leading",
            },
            fontReferences: [buildOrdinalFontReference(listItem.listMarkerStyle, font)],
            whitespaceStripping: "none",
        },
    });
    return interpretedTextContexts;
}
export function createChildFontInformation(listItem, parentFont) {
    var _a, _b, _c, _d, _e, _f;
    return {
        color: (_a = listItem.color) !== null && _a !== void 0 ? _a : parentFont.color,
        decorations: (_b = listItem.decorations) !== null && _b !== void 0 ? _b : parentFont.decorations,
        family: (_c = listItem.fontFamily) !== null && _c !== void 0 ? _c : parentFont.family,
        size: (_d = listItem.fontSize) !== null && _d !== void 0 ? _d : parentFont.size,
        stroke: (_e = listItem.stroke) !== null && _e !== void 0 ? _e : parentFont.stroke,
        style: (_f = listItem.fontStyle) !== null && _f !== void 0 ? _f : parentFont.style,
    };
}
function buildOrdinalFontReference(listMarkerStyle, parentFont) {
    const isUrl = containsUrl(parentFont.family);
    switch (listMarkerStyle) {
        case "unordered":
            return {
                url: BULLET_FONT,
            };
        case "ordered":
            return {
                url: isUrl ? parentFont.family : undefined,
                fontFamily: !isUrl ? parentFont.family : undefined,
                fontStyle: !isUrl ? translateFontStyle(parentFont.style) : undefined,
            };
        default:
            throw Error(`Unknown list marker style: ${listMarkerStyle}!`);
    }
}
function getRomanNumeral(listDepth, uppercase) {
    let result = "";
    while (listDepth > 0) {
        for (const numeral of ROMAN_MAP) {
            const numeralKey = Object.keys(numeral)[0];
            const numeralValue = parseInt(numeralKey);
            while (listDepth >= numeralValue) {
                result += numeral[numeralKey];
                listDepth -= numeralValue;
            }
        }
    }
    return uppercase ? result : result.toLocaleLowerCase();
}
