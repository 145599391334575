import memoize from "lodash.memoize";
// Only 5 units are supported in cimdoc:
// https://cimpress-support.atlassian.net/wiki/spaces/CI/pages/10267623744/Units+and+Measurements+CDIF
// Get number value and unit value
// Example: "12.5" cm returns 12.5 and cm
// eslint-disable-next-line
const regex = /(^[\+\-]?\d*\.?\d+(?:[Ee][\+\-]?\d+)?)\s?(mm|pt|in|cm|m)/;
export function getNumberAndUnitValue(value) {
    const regExResult = value.match(regex);
    if (regExResult && regExResult[1] && regExResult[2]) {
        return [Number(regExResult[1]), regExResult[2]];
    }
    else {
        throw Error(`Failed to parse measurement ${value}!`);
    }
}
export const parseMM = memoize((input) => {
    const [numberValue, unitValue] = getNumberAndUnitValue(input);
    return toMM(numberValue, unitValue);
}, (input) => input);
export function toMM(value, unit) {
    switch (unit) {
        case "mm":
            return value;
        case "pt":
            return value * 0.352778;
        case "in":
            return value * 25.4;
        case "cm":
            return value * 10;
        case "m":
            return value * 1000;
        default:
            throw Error(`Unsupported unit: ${unit}!`);
    }
}
export function toRadians(value) {
    const degrees = Number(value);
    if (isNaN(degrees)) {
        throw Error(`Cannot convert '${value}' to radians!`);
    }
    return degrees * 0.0174533;
}
export function toDegrees(value) {
    const radians = Number(value);
    if (isNaN(radians)) {
        throw Error(`Cannot convert '${value}' to degrees!`);
    }
    return radians / 0.0174533;
}
export function mmToString(valueInMM) {
    return `${valueInMM}mm`;
}
export function parsePercentage(value) {
    return Number(value.replace("%", "")) / 100.0;
}
