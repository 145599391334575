var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { parseColor } from "../../utils/paint/Color";
import { parsePaint } from "../../utils/paint/Paint";
export function parseFill(fillPaint, options) {
    return __awaiter(this, void 0, void 0, function* () {
        if (fillPaint === undefined || fillPaint.length === 0) {
            return undefined;
        }
        if (fillPaint.startsWith("paint")) {
            return yield parsePaint(fillPaint, options);
        }
        return parseColor(fillPaint, options === null || options === void 0 ? void 0 : options.colorPalette);
    });
}
export function parseOverprints(overprints, colorPalette) {
    if (overprints === undefined || overprints.length === 0) {
        return undefined;
    }
    return overprints.map((o) => parseColor(o, colorPalette));
}
