export function getFilters(surface) {
    if (surface.colorMode && surface.colorMode.toLowerCase().indexOf("grayscale") >= 0) {
        return [
            {
                type: "colorMatrix",
                matrix: [
                    [0.21, 0.21, 0.21, 0],
                    [0.72, 0.72, 0.72, 0],
                    [0.07, 0.07, 0.07, 0],
                    [0, 0, 0, 1],
                    [0, 0, 0, 0],
                ],
            },
        ];
    }
    return undefined;
}
