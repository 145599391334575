var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { parseMM, parsePercentage, toRadians } from "../unitHelper";
import { parseColor } from "./Color";
import { Matrix } from "../math/matrix";
import { layout } from "../..";
import { TileAugmenterFactory } from "./tiles/TileAugmenterFactory";
const PAINT_DEFINITION_REGEX = /paint\((.+)\)/;
function convertGradientPosition(position, itemBounds) {
    const layoutGradientPosition = { x: 0, y: 0 };
    if (position.x.endsWith("%")) {
        if (itemBounds === undefined) {
            throw Error("Cannot convert gradient percentage because there are no item bounds");
        }
        layoutGradientPosition.x = itemBounds.left + parsePercentage(position.x) * itemBounds.width;
    }
    else {
        layoutGradientPosition.x = parseMM(position.x);
    }
    if (position.y.endsWith("%")) {
        if (itemBounds === undefined) {
            throw Error("Cannot convert gradient percentage because there are no item bounds");
        }
        layoutGradientPosition.y = itemBounds.top + parsePercentage(position.y) * itemBounds.height;
    }
    else {
        layoutGradientPosition.y = parseMM(position.y);
    }
    return layoutGradientPosition;
}
function convertRadialGradientPosition(position, itemBounds) {
    if (position === undefined) {
        return convertGradientPosition({ x: "50%", y: "50%" }, itemBounds);
    }
    return convertGradientPosition(position, itemBounds);
}
function convertGradientStop(stop, colorPalette) {
    return {
        offset: parsePercentage(stop.offset),
        color: parseColor(stop.color, colorPalette),
    };
}
function convertRadialGradientRadius(radius, itemBounds) {
    if (itemBounds === undefined) {
        throw new Error("Item bounds was not defined");
    }
    const minDimension = Math.min(itemBounds.width, itemBounds.height);
    let percentage;
    if (radius === undefined) {
        percentage = 0.5;
    }
    else {
        percentage = parsePercentage(radius);
    }
    return percentage * minDimension;
}
export function parsePaint(rawValue, options) {
    return __awaiter(this, void 0, void 0, function* () {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        const result = PAINT_DEFINITION_REGEX.exec(rawValue);
        if (!result || result.length < 2) {
            throw new Error("Unable to parse paint definition");
        }
        const paintAlias = result[1];
        const definitionPaint = (_a = options.definitionTreeNode) === null || _a === void 0 ? void 0 : _a.getPaintRecursive(paintAlias);
        let transform = Matrix.identity();
        if (definitionPaint === undefined) {
            throw new Error(`Unable to find '${paintAlias}' in paint definitions.`);
        }
        if (definitionPaint.type === "linearGradient") {
            const linearGradient = definitionPaint;
            if (definitionPaint.transform) {
                const t = definitionPaint.transform;
                transform = Matrix.multiply(transform, new Matrix(t.a, t.b, t.c, t.d, parseMM(t.x), parseMM(t.y)));
            }
            return {
                type: "linearGradient",
                start: convertGradientPosition(linearGradient.start, options.itemBounds),
                end: convertGradientPosition(linearGradient.end, options.itemBounds),
                stops: linearGradient.stops.map((stop) => convertGradientStop(stop, options.colorPalette)),
                transform: transform,
            };
        }
        // Additional commentary on the behavior of radial gradients can be found in the documentation and
        // in the instructions service.
        else if (definitionPaint.type === "radialGradient") {
            if (options.itemBounds === undefined) {
                throw new Error("Expected item bounds for radial gradient");
            }
            const radialGradient = definitionPaint;
            const end = convertRadialGradientPosition(radialGradient.end, options.itemBounds);
            const endRadius = convertRadialGradientRadius(radialGradient.endRadius, options.itemBounds);
            const boundsWidth = options.itemBounds.width;
            const boundsHeight = options.itemBounds.height;
            if (boundsWidth > boundsHeight) {
                transform = Matrix.multiply(transform, Matrix.scaleAboutPoint(boundsWidth / boundsHeight, 1, end));
            }
            else if (boundsHeight > boundsWidth) {
                transform = Matrix.multiply(transform, Matrix.scaleAboutPoint(1, boundsHeight / boundsWidth, end));
            }
            if (definitionPaint.transform) {
                const t = definitionPaint.transform;
                transform = Matrix.multiply(transform, new Matrix(t.a, t.b, t.c, t.d, parseMM(t.x), parseMM(t.y)));
            }
            return {
                type: "radialGradient",
                start: end,
                end: end,
                startRadius: 0,
                endRadius: endRadius,
                stops: radialGradient.stops.map((stop) => convertGradientStop(stop, options.colorPalette)),
                transform: transform,
            };
        }
        else if (definitionPaint.type === "pattern") {
            const pattern = definitionPaint;
            let transform = Matrix.identity();
            if (pattern.transform !== undefined) {
                transform = Matrix.scale((_b = pattern.transform.scaleX) !== null && _b !== void 0 ? _b : 1, (_c = pattern.transform.scaleY) !== null && _c !== void 0 ? _c : 1);
                transform = Matrix.multiply(transform, Matrix.rotation(toRadians((_d = pattern.transform.rotationAngle) !== null && _d !== void 0 ? _d : 0)));
                transform = Matrix.multiply(transform, Matrix.translate(parseMM((_e = pattern.transform.translateX) !== null && _e !== void 0 ? _e : "0mm"), parseMM((_f = pattern.transform.translateY) !== null && _f !== void 0 ? _f : "0mm")));
            }
            const panelName = pattern.definedPanelName;
            let patternPanel = (_g = options.definitionTreeNode) === null || _g === void 0 ? void 0 : _g.getPanelRecursive(panelName);
            if (patternPanel === undefined) {
                throw Error(`Unable to find '${panelName}' in definition panels.`);
            }
            //if the tile is defined - replace it with tile augmentation
            if (definitionPaint.tile) {
                try {
                    const tileAugmenter = TileAugmenterFactory.getAugmenter(definitionPaint.tile, patternPanel);
                    patternPanel = tileAugmenter.augmentUniformTile();
                }
                catch (error) {
                    throw Error(`Could not process the tile definition type '${definitionPaint.tile.type}', error'${error}'`);
                }
            }
            const cimdoc = {
                document: { definitions: (_h = options.definitionTreeNode) === null || _h === void 0 ? void 0 : _h.definition, panels: [patternPanel] },
                fontRepositoryUrl: options.fontRepositoryUrl,
                version: "3",
            };
            const layoutInput = {
                document: cimdoc,
                selector: { type: "panel", id: patternPanel.id },
                // The pixelSize doesn't matter here. The real pixelSize is calculated in neon.
                pixelSize: "0.35277mm",
                referrer: "internal",
                textOptions: {
                    rtextEnabled: true,
                },
            };
            const layoutResult = yield layout(layoutInput);
            return {
                type: "pattern",
                layout: layoutResult,
                transform,
                repetition: "repeat",
            };
        }
        throw Error("Unknown paint type");
    });
}
