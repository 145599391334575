export function applyClip({ context, clip }) {
    if (clip !== undefined) {
        const untransformedClipPath = new Path2D(clip.path);
        const transform = clip.transform;
        const transformedClipPath = new Path2D();
        const matrix2 = document.createElementNS("http://www.w3.org/2000/svg", "svg").createSVGMatrix();
        matrix2.a = transform.a;
        matrix2.b = transform.b;
        matrix2.c = transform.c;
        matrix2.d = transform.d;
        matrix2.e = transform.x;
        matrix2.f = transform.y;
        transformedClipPath.addPath(untransformedClipPath, matrix2);
        context.clip(transformedClipPath, undefined);
    }
}
