import memoize from "lodash.memoize";
export const resizeImage = memoize(({ scalar, image }) => {
    // This function should not be called when createImageBitmap is not supported because HTMLImageElement is used higher up
    // eslint-disable-next-line compat/compat
    return createImageBitmap(image, {
        resizeHeight: image.height * scalar,
        resizeWidth: image.width * scalar,
        resizeQuality: "medium",
    });
}, ({ scalar, src, width, height }) => `${scalar}${src}${width}${height}`);
