// Ported from https://gitlab.com/Cimpress-Technology/DocDesign/Document/doc.instructions/-/blob/master/src/Doc.Instructions.Domain/Text/TextAlongAPath/TextPathProcessor.cs
import memoize from "lodash.memoize";
import { Matrix } from "../../../utils/math/matrix";
import { parsePathData } from "../../../utils/parsePathData";
import { PiecewisePath } from "./piecewisePath";
import { parseMM } from "../../../utils/unitHelper";
import { transformBoundingBox } from "../../..";
const getPiecewisePath = memoize((svg, unit) => {
    const [svgPath] = parsePathData({ pathData: svg, svgPathDataUnit: unit, pixelSize: 1 });
    const path = new PiecewisePath({ path: svgPath });
    return path;
}, (textPath, unit) => textPath + unit);
export const getTransformedPiecewisePath = (textArea) => {
    const textPath = textArea.textPath;
    if (textPath === undefined) {
        throw Error("No text path found");
    }
    if (textPath.pathSpecification.type !== "svgPath") {
        throw Error("Only svg paths can be text paths");
    }
    // if viewbox is specified, scale and translate the path
    let dataUnit;
    [textPath.pathSpecification.svgPathData, dataUnit] = applyViewboxTransformAndGetUnit(textPath.pathSpecification, textArea.position);
    const path = getPiecewisePath(textPath.pathSpecification.svgPathData, dataUnit);
    return path;
};
export const applyPathToText = (textArea, textBlocks, path, textAreaResult, resultFont) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
    const outputRuns = [];
    const startOffset = getStartOffset(textArea, path);
    const visibleGlyphs = new Set();
    const cumulativeContentLength = [];
    let totalLength = 0;
    for (const block of textBlocks) {
        cumulativeContentLength.push(totalLength);
        totalLength += (_b = (_a = block.content) === null || _a === void 0 ? void 0 : _a.length) !== null && _b !== void 0 ? _b : 0;
    }
    for (const glyphRun of (_c = textAreaResult.glyphRuns) !== null && _c !== void 0 ? _c : []) {
        const curveAlignmentTransform = getCurveAlignmentTransform((_d = textArea.textPath) === null || _d === void 0 ? void 0 : _d.curveAlignment, glyphRun.fontSize, resultFont[glyphRun.fontIndex]);
        for (const glyph of glyphRun.glyphs) {
            const midpoint = ((_e = glyph.xOffset) !== null && _e !== void 0 ? _e : 0) + startOffset + (((_f = glyph.xAdvance) !== null && _f !== void 0 ? _f : 0) - ((_g = glyph.letterSpacing) !== null && _g !== void 0 ? _g : 0)) / 2;
            const t = midpoint / path.length;
            // ignore out-of-path text
            if (t < 0 || t > 1) {
                continue;
            }
            visibleGlyphs.add(cumulativeContentLength[(_h = glyphRun.textBlockIndex) !== null && _h !== void 0 ? _h : 0] + ((_j = glyph.cluster) !== null && _j !== void 0 ? _j : 0));
            const pointOnPath = path.getPoint(t);
            // Since glyph rotation (via GlyphPivoting) starts at the beginning of the glyph (not at the center),
            // first assume that the glyph is positioned about the global coordinate (0,0).
            // 1. First, move the glyph so that the center is at (0, 0).
            // 2. Next, rotate the glyph
            // 3. Finally, move the glyph to the location on the path.
            let matrix = Matrix.translate(-(((_k = glyph.xAdvance) !== null && _k !== void 0 ? _k : 0) - ((_l = glyph.letterSpacing) !== null && _l !== void 0 ? _l : 0)) / 2, 0);
            matrix = Matrix.multiply(matrix, curveAlignmentTransform);
            matrix = Matrix.multiply(matrix, Matrix.rotation(pointOnPath.theta));
            matrix = Matrix.multiply(matrix, Matrix.translate(pointOnPath.point.x, pointOnPath.point.y));
            // Note (copied from .NET service): somewhere in the code, the offset (in this case, the text area position) gets added to the XOffset and
            // YOffset. We actually do want this for text along a path, so don't subtract the XOffset/YOffset by the position.
            const newGlyph = {
                cluster: glyph.cluster,
                index: glyph.index,
                xAdvance: glyph.xAdvance,
                yAdvance: glyph.yAdvance,
                xOffset: matrix.x,
                yOffset: matrix.y,
            };
            const newGlyphRun = {
                baseline: 0,
                orientation: "horizontal",
                glyphPivoting: (pointOnPath.theta * 180) / Math.PI,
                fontIndex: glyphRun.fontIndex,
                fontReference: glyphRun.fontReference,
                fontSize: glyphRun.fontSize,
                glyphs: [newGlyph],
                textBlockIndex: glyphRun.textBlockIndex,
            };
            outputRuns.push(newGlyphRun);
        }
    }
    return { glyphRuns: outputRuns, visibleGlyphs };
};
export const applyPathToGlyphBounds = (textArea, path, resultFonts, glyphRuns) => {
    const totalBounds = [];
    const startOffset = getStartOffset(textArea, path);
    if (glyphRuns !== undefined) {
        glyphRuns.forEach((glyphRun) => {
            var _a;
            const engineFont = resultFonts[glyphRun.fontIndex];
            const curveAlignmentTransform = getCurveAlignmentTransform((_a = textArea.textPath) === null || _a === void 0 ? void 0 : _a.curveAlignment, glyphRun.fontSize, engineFont);
            glyphRun.glyphs.forEach((glyph) => {
                var _a, _b;
                const blackBoxBounds = (_a = engineFont.glyphs) === null || _a === void 0 ? void 0 : _a[glyph.index].blackBoxBounds;
                const glyphBounds = {
                    left: ((_b = glyph.xOffset) !== null && _b !== void 0 ? _b : 0) + blackBoxBounds.x * glyphRun.fontSize,
                    top: blackBoxBounds.y * glyphRun.fontSize,
                    width: blackBoxBounds.width * glyphRun.fontSize,
                    height: blackBoxBounds.height * glyphRun.fontSize,
                };
                const midpoint = glyphBounds.left + startOffset + glyphBounds.width / 2;
                const t = midpoint / path.length;
                // ignore out-of-path text
                if (t < 0 || t > 1) {
                    return;
                }
                const point = path.getPoint(t);
                let matrix = Matrix.scale(1, -1);
                matrix = Matrix.multiply(matrix, Matrix.translate(-(glyphBounds.left + glyphBounds.width / 2), 0));
                matrix = Matrix.multiply(matrix, curveAlignmentTransform);
                matrix = Matrix.multiply(matrix, Matrix.rotation(point.theta));
                matrix = Matrix.multiply(matrix, Matrix.translate(point.point.x, point.point.y));
                const transformedAbsoluteGlyphBounds = transformBoundingBox(glyphBounds, matrix);
                totalBounds.push(transformedAbsoluteGlyphBounds);
            });
        });
    }
    return totalBounds;
};
export const applyPathToCarets = (textArea, path, textAreaResult, visibleGlyphs) => {
    var _a, _b;
    const outputCaretLine = { baseline: 0, spans: [] };
    const startOffset = getStartOffset(textArea, path);
    let lastCursor;
    let lastSpan;
    for (const caretLine of (_a = textAreaResult.lineInfos) !== null && _a !== void 0 ? _a : []) {
        for (const span of (_b = caretLine.spans) !== null && _b !== void 0 ? _b : []) {
            const newSpan = Object.assign(Object.assign({}, span), { caretPositions: [] });
            for (const caretPosition of span.caretPositions) {
                const caretX = caretPosition.x + startOffset;
                const t = caretX / path.length;
                const pointOnPath = path.getPoint(t < 0 ? 0 : t > 1 ? 1 : t);
                const cursor = Object.assign(Object.assign({}, caretPosition), { x: pointOnPath.point.x, y: pointOnPath.point.y, rotation: ((pointOnPath.theta * 180) / Math.PI + 270) % 360 });
                let isCursorVisible = false;
                // ignore out-of-path text
                if (caretPosition.glyphCluster !== undefined && visibleGlyphs.has(caretPosition.glyphCluster)) {
                    isCursorVisible = true;
                    lastCursor = undefined;
                    lastSpan = undefined;
                }
                else if (!lastCursor && !lastSpan) {
                    lastCursor = cursor;
                    lastSpan = newSpan;
                }
                if (isCursorVisible)
                    newSpan.caretPositions.push(cursor);
            }
            outputCaretLine.spans.push(newSpan);
        }
    }
    if (lastCursor && lastSpan)
        lastSpan.caretPositions.push(lastCursor);
    outputCaretLine.spans = outputCaretLine.spans.filter((span) => span.caretPositions.length);
    return [outputCaretLine];
};
export const getStartOffset = (textArea, piecewisePath) => {
    var _a;
    if (((_a = textArea.textPath) === null || _a === void 0 ? void 0 : _a.startOffset) !== undefined) {
        return textArea.textPath.startOffset * piecewisePath.length;
    }
    return 0;
};
export const convertTextAlignment = (textAlignment) => {
    switch (textAlignment) {
        case "start":
            return "leading";
        case "center":
            return "center";
        case "end":
            return "trailing";
    }
};
/**
 *
 * @param viewBox The SVG viewbox in the format "x y width height"
 * @returns a Position
 */
const viewBoxToPosition = (viewBox, unit) => {
    const parts = viewBox.split(" ");
    return { x: `${parts[0]}${unit}`, y: `${parts[1]}${unit}`, width: `${parts[2]}${unit}`, height: `${parts[3]}${unit}` };
};
/**
 * Transforms the SVG path by the viewbox transform. Note that we aren't using the SVG definition of
 * viewbox since there isn't any clipping. We are only interested in using a viewbox as a method of scaling.
 * This is also identical to the viewbox transform in curves, but they are similar.
 *
 * @param path the original svg path
 * @param viewBox Viewbox as a Position
 * @param textAreaPosition The TextArea Position
 *
 * @returns the transformed path
 */
export const applyViewboxTransformAndGetUnit = (pathSpec, textAreaPosition) => {
    if ("viewBox" in pathSpec && "svgPathDataUnit" in pathSpec) {
        throw new Error("Must specify either svgPathDataUnit or viewBox, but not both");
    }
    if ("viewBox" in pathSpec) {
        // The unit is completely arbitrary (though it can't be zero). In this case the arbitrary unit chosen is millimeters.
        const dataUnit = "mm";
        let transform = Matrix.identity();
        const viewBox = viewBoxToPosition(pathSpec.viewBox, dataUnit);
        const scaleX = parseMM(textAreaPosition.width) / parseMM(viewBox.width);
        const scaleY = parseMM(textAreaPosition.height) / parseMM(viewBox.height);
        transform = Matrix.multiply(transform, Matrix.translate(-parseMM(viewBox.x), -parseMM(viewBox.y)));
        // Scale the path
        transform = Matrix.multiply(transform, Matrix.scale(scaleX, scaleY));
        let [svgPath] = parsePathData({ pathData: pathSpec.svgPathData, pixelSize: 1, svgPathDataUnit: dataUnit, closeBehavior: "explicit" });
        // Now translate with the textArea position x/y, but this is done in separate code so don't do it here.
        svgPath = svgPath.matrix([transform.a, transform.b, transform.c, transform.d, transform.x, transform.y]);
        return [svgPath.toString(), dataUnit];
    }
    else if ("svgPathDataUnit" in pathSpec) {
        return [pathSpec.svgPathData, pathSpec.svgPathDataUnit];
    }
    else {
        throw new Error("Must specify one of viewBox or svgPathDataUnit");
    }
};
export const getCurveAlignmentTransform = (curveAlignment, fontSize, resultFont) => {
    let height = 0;
    const fontMetrics = resultFont.fontMetrics;
    switch (curveAlignment) {
        case "halfAscent":
            height = (fontMetrics.ascent * fontSize) / 2;
            break;
        case "xHeight":
            height = fontMetrics.xHeight * fontSize;
            break;
        case "capHeight":
            height = fontMetrics.capHeight * fontSize;
            break;
        case "halfCapHeight":
            height = (fontMetrics.capHeight * fontSize) / 2;
            break;
    }
    return Matrix.translate(0, height);
};
