// These easing functions mostly come from https://easings.net
const easeOutBounce = (t) => {
    const n1 = 7.5625;
    const d1 = 2.75;
    if (t < 1 / d1) {
        return n1 * t * t;
    }
    else if (t < 2 / d1) {
        return n1 * (t -= 1.5 / d1) * t + 0.75;
    }
    else if (t < 2.5 / d1) {
        return n1 * (t -= 2.25 / d1) * t + 0.9375;
    }
    else {
        return n1 * (t -= 2.625 / d1) * t + 0.984375;
    }
};
function easeInElastic(x) {
    const c4 = (2 * Math.PI) / 3;
    return x === 0 ? 0 : x === 1 ? 1 : -Math.pow(2, 10 * x - 10) * Math.sin((x * 10 - 10.75) * c4);
}
function easeOutElastic(x) {
    const c4 = (2 * Math.PI) / 3;
    return x === 0 ? 0 : x === 1 ? 1 : Math.pow(2, -10 * x) * Math.sin((x * 10 - 0.75) * c4) + 1;
}
function easeInOutElastic(x) {
    const c5 = (2 * Math.PI) / 4.5;
    return x === 0
        ? 0
        : x === 1
            ? 1
            : x < 0.5
                ? -(Math.pow(2, 20 * x - 10) * Math.sin((20 * x - 11.125) * c5)) / 2
                : (Math.pow(2, -20 * x + 10) * Math.sin((20 * x - 11.125) * c5)) / 2 + 1;
}
function easeInBack(x) {
    const c1 = 1.70158;
    const c3 = c1 + 1;
    return c3 * x * x * x - c1 * x * x;
}
function easeOutBack(x) {
    const c1 = 1.70158;
    const c3 = c1 + 1;
    return 1 + c3 * Math.pow(x - 1, 3) + c1 * Math.pow(x - 1, 2);
}
function easeInOutBack(x) {
    const c1 = 1.70158;
    const c2 = c1 * 1.525;
    return x < 0.5 ? (Math.pow(2 * x, 2) * ((c2 + 1) * 2 * x - c2)) / 2 : (Math.pow(2 * x - 2, 2) * ((c2 + 1) * (x * 2 - 2) + c2) + 2) / 2;
}
// These functions come from designeer
const EASINGS_SPIN_TOTAL_PERIODS = 8; // must be paired number
const EASINGS_SHAKE_TOTAL_PERIODS = 14;
const EASINGS_SHAKE_DEVIATION_PIXELS = 10; // element deviation in pixels
const EASINGS_SHAKE_DEVIATION_MM = (EASINGS_SHAKE_DEVIATION_PIXELS / 72) * 25.4;
const EASINGS_FLASH_TOTAL_PERIODS = 2;
function easingSpin(time) {
    return Math.cos(time * EASINGS_SPIN_TOTAL_PERIODS * Math.PI);
}
function easingShake(time) {
    return Math.sin(time * EASINGS_SHAKE_TOTAL_PERIODS * Math.PI) * EASINGS_SHAKE_DEVIATION_MM;
}
function easingFlash(time) {
    return Math.abs(Math.cos(time * EASINGS_FLASH_TOTAL_PERIODS * Math.PI));
}
export const Easings = {
    backEaseIn: easeInBack,
    backEaseOut: easeOutBack,
    backEaseInOut: easeInOutBack,
    elasticEaseIn: easeInElastic,
    elasticEaseOut: easeOutElastic,
    elasticEaseInOut: easeInOutElastic,
    bounceEaseIn: (t) => 1 - easeOutBounce(1 - t),
    bounceEaseOut: easeOutBounce,
    bounceEaseInOut: (t) => (t < 0.5 ? (1 - easeOutBounce(1 - 2 * t)) / 2 : (1 + easeOutBounce(2 * t - 1)) / 2),
    strongEaseIn: (t) => t * t * t * t,
    strongEaseOut: (t) => 1 - Math.pow(1 - t, 4),
    strongEaseInOut: (t) => (t < 0.5 ? 8 * t * t * t * t : 1 - Math.pow(-2 * t + 2, 4) / 2),
    easeIn: (t) => t * t,
    easeOut: (t) => 1 - (1 - t) * (1 - t),
    easeInOut: (t) => (t < 0.5 ? 2 * t * t : (-1 * Math.pow(-2 * t + 2, 2)) / 2),
    linear: (t) => t,
    flash: easingFlash,
    shake: easingShake,
    spin: easingSpin,
};
