var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { fetchImage, loadSvgAsImage } from "../../utils/api/image";
import { boundingBoxFromPath, computeBoundsFromPosition } from "../../utils/boundingBox";
import { parseColor, parseRgbaColorValues } from "../../utils/paint/Color";
import { resizeImage } from "../../utils/imageResizer";
import { Matrix } from "../../utils/math/matrix";
import { getClip, getClipPaths, getClipViewboxTransform, getClipWithViewbox } from "../helpers/Clip";
import { buildTransform } from "../helpers/Transform";
import { svgLayout } from "../svg/Layout";
import { isWebPSupported } from "./webP";
import { buildImageProxyUrl } from "./proxyUrl";
import { parseMM } from "../../utils/unitHelper";
import { getMeasurementData } from "../measurements/measurementData";
function loadSizes(imageSrc, image, imageOptions) {
    return __awaiter(this, void 0, void 0, function* () {
        if ((imageOptions === null || imageOptions === void 0 ? void 0 : imageOptions.skipMipMap) || image instanceof HTMLImageElement || !createImageBitmap) {
            return [image];
        }
        const scalar = 0.5;
        const images = [image];
        // Use step by step downscaling as described here: https://morioh.com/p/872a8ce21d61
        // Generate versions of the images in powers powers of two
        for (let i = 0; i < 4; i++) {
            // Ensure images have valid pixel dimensions before scaling
            const currentSmallestImage = images[i];
            if (currentSmallestImage && currentSmallestImage.width * scalar > 1 && currentSmallestImage.height * scalar > 1) {
                const resizedImage = yield resizeImage({
                    scalar,
                    image: currentSmallestImage,
                    src: imageSrc,
                    width: currentSmallestImage.width,
                    height: currentSmallestImage.height,
                });
                images.push(resizedImage);
            }
        }
        return images;
    });
}
export function imageLayout(_a) {
    return __awaiter(this, arguments, void 0, function* ({ image, parentBounds, experimentalOptions, overprints, imageOptions, referrer, previewType, colorPalette, fontRepositoryUrl, }) {
        var _b, _c, _d, _e, _f, _g, _h, _j, _k;
        const format = (yield isWebPSupported()) ? "webp" : "png";
        const images = [];
        const imageSrc = (_b = image.previewUrl) !== null && _b !== void 0 ? _b : image.printUrl;
        const originalScaleTransform = image.scale;
        const boundingBox = computeBoundsFromPosition({ position: image.position });
        if (imageSrc !== undefined) {
            const response = yield fetchImage({ url: buildImageProxyUrl({ sourceUrl: imageSrc, format, referrer }) });
            if (response.type === "svg" && (experimentalOptions === null || experimentalOptions === void 0 ? void 0 : experimentalOptions.svgInImagesSupport) && !(overprints === null || overprints === void 0 ? void 0 : overprints.length)) {
                const result = yield svgLayout({ image, parentBounds, svgData: response });
                return result;
            }
            if (response.type !== "bitmap" && response.type !== "HTMLImageElement") {
                throw Error("Failed to fetch image!");
            }
            images.push({
                images: yield loadSizes(buildImageProxyUrl({ sourceUrl: imageSrc, format, referrer }), response.image, imageOptions),
                overprint: undefined,
                filters: getFilters(image),
            });
        }
        // Pull down any overlay images
        // TODO in the future do this concurrently
        if (image.overlays) {
            for (const overlay of image.overlays) {
                const overlaySrc = (_c = overlay.previewUrl) !== null && _c !== void 0 ? _c : overlay.printUrl;
                const proxyOverlaySrc = buildImageProxyUrl({ sourceUrl: overlaySrc, format, referrer });
                const response = yield fetchImage({ url: proxyOverlaySrc });
                const imageToAdd = response.type === "svg" ? yield loadSvgAsImage({ svg: response.svg }) : response.image;
                // All overprints should be turned to alpha + white for compositing and knockout
                images.push({
                    images: yield loadSizes(proxyOverlaySrc, imageToAdd, imageOptions),
                    overprint: overlay.color,
                    filters: [
                        {
                            type: "colorMatrix",
                            matrix: [
                                [0, 0, 0, 0],
                                [0, 0, 0, 0],
                                [0, 0, 0, 0],
                                [0, 0, 0, 1],
                                [1, 1, 1, 0],
                            ],
                        },
                    ],
                });
            }
        }
        // TODO: Make this run concurrently if possible
        for (const overprint of overprints !== null && overprints !== void 0 ? overprints : []) {
            const overlay = (_d = image.overlays) === null || _d === void 0 ? void 0 : _d.find((o) => o.color && parseColor(o.color, colorPalette).name === overprint);
            if (overlay === undefined) {
                if (images.length === 0 || images[0].overprint !== undefined) {
                    throw Error("Base image and overprint are both missing URL's");
                }
                // If the image doesn't have a mask for this overprint,
                // then we want to remove from this overprint channel
                // every opaque part of the RGB image
                images.push({
                    images: images[0].images,
                    overprint,
                    filters: [
                        {
                            type: "colorMatrix",
                            matrix: [
                                [0, 0, 0, 0],
                                [0, 0, 0, 0],
                                [0, 0, 0, 0],
                                [0, 0, 0, 1],
                                [0, 0, 0, 0],
                            ],
                        },
                    ],
                });
            }
        }
        // If we didn't have a URL for the main image, try to draw one of the overprints,
        // if it has a visible color.
        // TODO: Do we need to handle cases where there are more than one,
        // or where both the overprint and the original image both have color?
        if (images.findIndex((im) => im.overprint === undefined) === -1) {
            for (const imageEntry of images) {
                if (imageEntry.overprint !== undefined) {
                    const parsedColor = parseColor(imageEntry.overprint, colorPalette);
                    if (parsedColor.display) {
                        const filters = (_e = getFilters(image)) !== null && _e !== void 0 ? _e : [];
                        const colorElements = parseRgbaColorValues(parsedColor.display);
                        if (colorElements) {
                            filters.splice(0, 0, {
                                type: "colorMatrix",
                                matrix: [
                                    [0, 0, 0, 0],
                                    [0, 0, 0, 0],
                                    [0, 0, 0, 0],
                                    [0, 0, 0, colorElements.a],
                                    [colorElements.r / 255, colorElements.g / 255, colorElements.b / 255, 0],
                                ],
                            });
                        }
                        images.push({ images: imageEntry.images, overprint: undefined, filters });
                        break;
                    }
                }
            }
        }
        let transform = buildTransform({
            bounds: boundingBox,
            skew: image.skew,
            scale: image.scale,
            imageAlignment: {
                horizontalAlignment: image.horizontalAlignment,
                verticalAlignment: image.verticalAlignment,
            },
            rotationAngle: image.rotationAngle,
            mirrorDirection: image.mirrorDirection,
            matrixTransform: image.transform,
            itemTransforms: image.transforms,
        });
        const panelTransform = transform.copy();
        const measurementDataResponse = getMeasurementData({
            boundingBox,
            tightBounds: boundingBox,
            transform,
            scaleTransform: originalScaleTransform,
            itemType: "image",
        });
        if (previewType === "item") {
            transform = (_f = measurementDataResponse.itemPreviewTransform) !== null && _f !== void 0 ? _f : Matrix.identity();
        }
        // Try catch to ignore already parsed color
        try {
            for (const entry of images) {
                if (entry.overprint) {
                    entry.overprint = parseColor(entry.overprint, colorPalette).name;
                }
            }
        }
        catch (_l) {
            // ignore
        }
        const clipBounds = ((_g = image.clipping) === null || _g === void 0 ? void 0 : _g.specification.origin) === "item"
            ? {
                left: parseMM(image.position.x),
                top: parseMM(image.position.y),
                width: parseMM(image.position.width),
                height: parseMM(image.position.height),
            }
            : parentBounds;
        // If the clip is relative to the item, the transform of the item must also be applied to the clip.
        // But the image's transform doesn't have the translation component of its position, so premultiply that
        // translate transform with the item's transform.
        const clipTransform = Matrix.multiply(Matrix.translate(boundingBox.left, boundingBox.top), transform);
        let clipPath;
        if (((_h = image.clipping) === null || _h === void 0 ? void 0 : _h.viewBox) !== undefined) {
            clipPath = yield getClipWithViewbox(image.clipping, boundingBox, transform, fontRepositoryUrl);
        }
        else {
            clipPath = yield getClip(image, clipBounds, clipTransform, fontRepositoryUrl);
        }
        let layoutBox = measurementDataResponse.measurementData.layoutBox;
        let previewBox = measurementDataResponse.measurementData.previewBox;
        // If a clip is defined, the measurement metadata and item preview changes
        if (clipPath !== undefined) {
            // The width/height of the item preview canvas is now the bounds of the clip
            layoutBox = clipPath.boundingBox;
            // If the clip is relative to the item, the previewBox property of the measurement metadata needs be transformed
            // along with the item. We have to recalculate the measurement data using the clip bounds as the preview box
            // don't do this if there is a viewbox, since the viewbox transforms the clip so that the bounding box of it is the
            // same as the position of this item.
            if (clipPath.isRelativeToItem && !clipPath.usesViewbox) {
                const clip = image.clipping;
                const { svgPath } = yield getClipPaths(clip, fontRepositoryUrl);
                let baseTransform = measurementDataResponse.itemPreviewTransform;
                if (((_j = image.clipping) === null || _j === void 0 ? void 0 : _j.viewBox) !== undefined) {
                    baseTransform = Matrix.multiply(baseTransform, getClipViewboxTransform(boundingBox, image.clipping.viewBox));
                }
                else {
                    baseTransform = Matrix.multiply(baseTransform, Matrix.translate(boundingBox.left, boundingBox.top));
                }
                const baseClipBounds = boundingBoxFromPath({ path: svgPath, transform: baseTransform });
                const clippingMeasurement = getMeasurementData({
                    itemType: "image",
                    boundingBox,
                    tightBounds: baseClipBounds,
                    transform: panelTransform,
                });
                previewBox = clippingMeasurement.measurementData.previewBox;
            }
        }
        else {
            if (previewType === "item") {
                // ITEM PREVIEWS ONLY: Need to translate image to origin before the item preview transform. Notice the pre-concatenation.
                // The reason why this is only done to images without clipping is because the bounding/preview box is the image itself,
                // whereas if there is a clip, the bounding/preview box is the clip, in which case we don't want to translate the image
                transform = Matrix.multiply(Matrix.translate(-boundingBox.left, -boundingBox.top), transform);
            }
        }
        return {
            id: image.id,
            status: { mode: "local" },
            measurementData: {
                layoutBox: layoutBox,
                boundingBox: measurementDataResponse.measurementData.boundingBox,
                previewBox: previewBox,
            },
            renderingOperation: {
                type: "drawImage",
                images,
                transform,
                crop: getCrop(image),
                clip: clipPath,
                boundingBox,
                opacityMultiplier: (_k = image.opacityMultiplier) !== null && _k !== void 0 ? _k : 1,
            },
        };
    });
}
function getCrop(image) {
    if (image.cropFractions) {
        return {
            bottom: parseFloat(image.cropFractions.bottom),
            top: parseFloat(image.cropFractions.top),
            left: parseFloat(image.cropFractions.left),
            right: parseFloat(image.cropFractions.right),
        };
    }
    return undefined;
}
function getFilters(image) {
    var _a, _b, _c, _d, _e, _f;
    const filters = [];
    // Only colorAdjustment or effects may exist, and the former will override the latter if both
    // exist. This is Rendering behavior
    if (image.colorAdjustment) {
        filters.push({
            type: "hslFilter",
            hueMultiplier: (_a = image.colorAdjustment.hueMultiplier) !== null && _a !== void 0 ? _a : 1,
            hueOffset: (_b = image.colorAdjustment.hueOffset) !== null && _b !== void 0 ? _b : 0,
            saturationMultiplier: (_c = image.colorAdjustment.saturationMultiplier) !== null && _c !== void 0 ? _c : 1,
            saturationOffset: (_d = image.colorAdjustment.saturationOffset) !== null && _d !== void 0 ? _d : 0,
            lightnessMultiplier: (_e = image.colorAdjustment.lightnessMultiplier) !== null && _e !== void 0 ? _e : 1,
            lightnessOffset: (_f = image.colorAdjustment.lightnessOffset) !== null && _f !== void 0 ? _f : 0,
        });
    }
    else if (image.effects) {
        for (const effect of image.effects) {
            if (effect.type === "colorMatrix") {
                // The color matrix is 4x5 but the shader is written to take in vec4's, so transpose it
                const transposedMatrix = Array.from({ length: 5 }, () => Array(4).fill(0));
                for (let row = 0; row < effect.values.length; row++) {
                    for (let col = 0; col < effect.values[0].length; col++) {
                        transposedMatrix[col][row] = effect.values[row][col];
                    }
                }
                filters.push({
                    type: "colorMatrix",
                    matrix: transposedMatrix,
                });
            }
        }
    }
    return filters;
}
