import { RectangularSvgPathCreator } from "./SvgPathCreator";
import { parseMM } from "../../unitHelper";
import { Matrix } from "../../math/matrix";
import { cloneDeep } from "lodash";
export class TileAugmenter {
    constructor(tile, definitionPanel) {
        this.BORDER_COLOR = "rgb(255,255,255)";
        this.DEFAULT_UNIT = "mm";
        // make it the highest number possible
        this.BORDER_ZINDEX = 200;
        // make it the lowest number possible
        this.BACKGROUND_ZINDEX = -999;
        this.NamePrefix = "tile-augmenter";
        this.tile = tile;
        this.definitionPanel = definitionPanel;
        this.defpanelWidth = parseMM(definitionPanel.width);
        this.defpanelHeight = parseMM(definitionPanel.height);
        this.elements = new TilePatternElements();
    }
    augmentUniformTile() {
        try {
            const convertedPanel = {
                id: `${this.NamePrefix}-panel`,
                name: `${this.NamePrefix}-panel`,
                width: this.withUnit(this.getTileWidth(), this.DEFAULT_UNIT),
                height: this.withUnit(this.getTileHeight(), this.DEFAULT_UNIT),
                subpanels: [this.createTileSubpanel()],
                decorationTechnology: this.definitionPanel.decorationTechnology,
            };
            return convertedPanel;
        }
        catch (error) {
            console.log(error);
            throw error;
        }
    }
    createTileSubpanel() {
        this.augmentTilePatterns();
        this.addTileBorder();
        this.addTileBackground();
        return this.createSubpanel();
    }
    augmentPattern(patternBound, scale = 1, direction, pathCreator) {
        if (this.definitionPanel.images) {
            //Revisit - layout in linear fashion for the clips to work properly
            this.definitionPanel.images.forEach((image) => {
                const patternImage = this.createImagePatternAndUpdateBound(patternBound, image, scale, direction, pathCreator);
                this.elements.addImage(patternImage);
            });
        }
    }
    createImagePatternAndUpdateBound(patternBound, image, scale, direction, pathCreator) {
        const patternImage = cloneDeep(image);
        if (null !== patternImage && this.isValidPosition(image.position)) {
            const imageHeight = parseMM(image.position.height) * scale;
            const imageWidth = parseMM(image.position.width) * scale;
            // centering image is not ideal when there can be multi elements in the panel
            // fit the best way aligned to the left top
            const positionTransform = Matrix.translate(patternBound.left, patternBound.top);
            const imagePoint = positionTransform.toDOMMatrix().transformPoint({ x: parseMM(image.position.x), y: parseMM(image.position.y) });
            const patternPosition = this.createPosition(imagePoint.x, imagePoint.y, imageWidth, imageHeight);
            patternImage.position = patternPosition;
            const clipX = imagePoint.x;
            const clipY = imagePoint.y;
            const clipWidth = patternBound.width > imageWidth ? imageWidth : patternBound.width;
            const clipHeight = patternBound.height > imageHeight ? imageHeight : patternBound.height;
            // clipping to crop without affecting the aspect ratio
            const clipBound = {
                left: clipX,
                top: clipY,
                width: clipWidth,
                height: clipHeight,
            };
            const svgCreator = pathCreator !== null && pathCreator !== void 0 ? pathCreator : new RectangularSvgPathCreator(clipBound);
            patternImage.clipping = this.createItemClip(svgCreator, patternPosition);
            if (null !== direction && undefined !== direction) {
                patternImage.mirrorDirection = direction;
            }
            return patternImage;
        }
    }
    isValidPosition(position) {
        return this.isValidString(position.x) && this.isValidString(position.y) && this.isValidString(position.width) && this.isValidString(position.height);
    }
    isValidString(dimension) {
        return null !== dimension && undefined !== dimension;
    }
    createItemClip(pathCreator, position) {
        const itemClip = {
            specification: {
                type: "svgPathData",
                origin: "panel",
                data: pathCreator.createSvgPath(),
            },
            viewBox: `${parseMM(position.x)} ${parseMM(position.y)} ${parseMM(position.width)} ${parseMM(position.height)}`,
        };
        return itemClip;
    }
    addTileBorder() {
        var _a;
        if (this.tile.border) {
            const border = {
                id: `${this.NamePrefix}-tileborder-shape`,
                type: "rectangle",
                zIndex: this.BORDER_ZINDEX,
                position: {
                    x: "0mm",
                    y: "0mm",
                    width: this.withUnit(this.getTileWidth(), this.DEFAULT_UNIT),
                    height: this.withUnit(this.getTileHeight(), this.DEFAULT_UNIT),
                },
                stroke: {
                    color: (_a = this.tile.borderColor) !== null && _a !== void 0 ? _a : this.BORDER_COLOR,
                    thickness: this.tile.border,
                },
            };
            this.elements.addShape(border);
        }
    }
    addTileBackground() {
        if (this.tile.backgroundColor) {
            const background = {
                id: `${this.NamePrefix}-tilebackground-shape`,
                type: "rectangle",
                zIndex: this.BACKGROUND_ZINDEX,
                position: this.createPosition(0, 0, this.getTileWidth(), this.getTileHeight()),
                color: this.tile.backgroundColor,
            };
            this.elements.addShape(background);
        }
    }
    createSubpanel() {
        const subpanel = {
            id: `${this.NamePrefix}-subpanel`,
            position: {
                x: "0mm",
                y: "0mm",
            },
            images: this.elements.images,
            textAreas: this.elements.textAreas,
            shapes: this.elements.shapes,
        };
        return subpanel;
    }
    createBounds(x, y, width, height) {
        const box = {
            left: x,
            top: y,
            width: width,
            height: height,
        };
        return box;
    }
    convertToBounds(position) {
        const box = {
            left: parseMM(position.x),
            top: parseMM(position.y),
            width: parseMM(position.width),
            height: parseMM(position.height),
        };
        return box;
    }
    createPosition(x, y, width, height) {
        const position = {
            x: this.withUnit(x, this.DEFAULT_UNIT),
            y: this.withUnit(y, this.DEFAULT_UNIT),
            width: this.withUnit(width, this.DEFAULT_UNIT),
            height: this.withUnit(height, this.DEFAULT_UNIT),
        };
        return position;
    }
    withUnit(quantity, unit) {
        return `${quantity}${unit}`;
    }
}
class TilePatternElements {
    constructor() {
        this.images = new Array();
        this.shapes = new Array();
        this.textAreas = new Array();
    }
    addImage(image) {
        if (image) {
            this.images.push(image);
        }
    }
    addShape(shape) {
        if (shape) {
            this.shapes.push(shape);
        }
    }
    addTextArea(textArea) {
        if (textArea) {
            this.textAreas.push(textArea);
        }
    }
}
