import { parseMM } from "../unitHelper";
const RENDERING_SERVICE = "https://rendering.documents.cimpress.io";
const MAX_DOCUMENT_LENGTH = 1700;
export function buildRenderingRequest({ instructionsRequest, tenant, pixelSize, quality, format, breakCache = false, overprint, referrer, scaleFactor, }) {
    const { instructionsUri, documentUri } = instructionsRequest;
    let instructionsPostData = undefined;
    const params = new URLSearchParams();
    // If the document is small enough to fit on the query string add it as a parameter
    if (documentUri.length < MAX_DOCUMENT_LENGTH) {
        const instructions = `${instructionsUri}?documentUri=${encodeURIComponent(documentUri)}`;
        params.set("instructions_uri", instructions);
    }
    else {
        // Otherwise set the instructions endpoint and return POST data containing the document
        params.set("instructions_uri", instructionsUri);
        instructionsPostData = `{"documentUri":"${documentUri}"}`;
    }
    // If an item needs to be scaled, request a larger image.
    let pixelSizemm = parseMM(pixelSize);
    // Pixel size represents how many millimeters equals a pixel on the screen. Lowering the millimeters-per-pixel
    // ratio means more pixels are required per each millimeter, therefore we divide.
    pixelSizemm /= scaleFactor;
    params.set("pixel", `${pixelSizemm}mm`);
    if (breakCache) {
        params.set("bc", `${Math.random()}`);
    }
    if (quality !== undefined) {
        if (quality > 0 && quality <= 100) {
            throw Error("Render quality must be between 0 and 100!");
        }
        params.set("quality", quality.toString());
    }
    if (format) {
        params.set("format", format);
    }
    if (overprint) {
        params.set("finish", overprint.replace("spot(", "").replace(")", ""));
        params.set("bgcolor", "00000000");
    }
    params.set("referrer", referrer);
    return {
        previewUri: `${RENDERING_SERVICE}/v1/${tenant}/preview?${params.toString()}`,
        instructionsPostData,
    };
}
