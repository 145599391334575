import { TileAugmenter } from "./TileAugmenter";
import { parseMM } from "../../unitHelper";
export class MultiStackAugmenter extends TileAugmenter {
    constructor(tile, definitionPanel) {
        super(tile, definitionPanel);
        this.stackSteps = tile.steps <= 0 ? 1 : tile.steps;
        this.stackBorder = tile.stackBorder ? parseMM(tile.stackBorder) : 0;
        this.stackOffset = tile.stackOffset;
        this.tileWidth = this.defpanelWidth + 2 * this.stackBorder;
        this.tileHeight = this.stackSteps * (this.defpanelHeight + 2 * this.stackBorder);
    }
    /// Multistack Tile will be created as follows for steps 3
    /// | border
    /// | border|---------------------------------pattern1----------------------------------------|border|
    /// |----------pattern2-left----|border|border|--------------------------pattern2-right--------------|
    /// |----------------pattern3-left-----------------------|border|border|-------pattern3-right--------|
    /// | border
    augmentTilePatterns() {
        const patternWidth = this.defpanelWidth;
        const patternHeight = this.defpanelHeight;
        const pattern1Position = this.createBounds(this.stackBorder, this.stackBorder, patternWidth, patternHeight);
        this.augmentPattern(pattern1Position);
        const stepXOffset = this.stackOffset ? parseMM(this.stackOffset) : patternWidth / this.stackSteps;
        for (let index = 1; index < this.stackSteps; index++) {
            const xOffset1 = -patternWidth + index * stepXOffset;
            const yOffset1 = index * patternHeight + (2 * index + 1) * this.stackBorder;
            const pattern2LeftStepPosition = this.createBounds(xOffset1, yOffset1, patternWidth, patternHeight);
            this.augmentPattern(pattern2LeftStepPosition);
            const xOffset2 = xOffset1 + patternWidth + 2 * this.stackBorder;
            const pattern2RightStepPosition = this.createBounds(xOffset2, yOffset1, patternWidth, patternHeight);
            this.augmentPattern(pattern2RightStepPosition);
        }
    }
    getTileWidth() {
        return this.tileWidth;
    }
    getTileHeight() {
        return this.tileHeight;
    }
}
