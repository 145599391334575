import { deflateSync } from "fflate";
import memoize from "lodash.memoize";
const getEncoder = memoize(() => new TextEncoder());
const INSTRUCTIONS_SERVICE = "https://instructions.documents.cimpress.io";
export function buildInstructionsRequest({ document, previewType }) {
    const documentUri = compressDocument({ document });
    if (previewType === "item") {
        return {
            instructionsUri: `${INSTRUCTIONS_SERVICE}/v3/instructions:item`,
            documentUri,
        };
    }
    return {
        instructionsUri: `${INSTRUCTIONS_SERVICE}/v3/instructions:preview`,
        documentUri,
    };
}
function compressDocument({ document }) {
    const documentJson = JSON.stringify(document);
    const encodedValue = getEncoder().encode(documentJson);
    const valueDeflated = deflateSync(encodedValue);
    if (typeof btoa === "undefined") {
        return Buffer.from(valueDeflated).toString("base64");
    }
    return btoa(String.fromCharCode(...valueDeflated));
}
