// Builds a program for a vertex/fragment shader
export function buildProgram(ctx, vertexSource, fragmentSource) {
    const program = ctx.createProgram();
    const vertexShader = ctx.createShader(ctx.VERTEX_SHADER);
    ctx.shaderSource(vertexShader, vertexSource);
    ctx.compileShader(vertexShader);
    const fragmentShader = ctx.createShader(ctx.FRAGMENT_SHADER);
    ctx.shaderSource(fragmentShader, fragmentSource);
    ctx.compileShader(fragmentShader);
    ctx.attachShader(program, vertexShader);
    ctx.attachShader(program, fragmentShader);
    ctx.linkProgram(program);
    return program;
}
export function initializeProgram(ctx, program, width, height, f1Uniforms, v4fUniforms) {
    // Add single floating point uniforms
    Object.keys(f1Uniforms).forEach((uniformName) => {
        const location = ctx.getUniformLocation(program, uniformName);
        ctx.uniform1f(location, f1Uniforms[uniformName]);
    });
    // Add vector 4 uniforms
    Object.keys(v4fUniforms).forEach((uniformName) => {
        const location = ctx.getUniformLocation(program, uniformName);
        ctx.uniform4fv(location, v4fUniforms[uniformName]);
    });
    // Size the vertex shader to the input width and height
    const resolutionLocation = ctx.getUniformLocation(program, "u_resolution");
    ctx.uniform2f(resolutionLocation, width, height);
    const positionLocation = ctx.getAttribLocation(program, "a_position");
    const vertexBuffer = ctx.createBuffer();
    ctx.bindBuffer(ctx.ARRAY_BUFFER, vertexBuffer);
    // Create two triangles that represent a plane with the input width and height
    ctx.bufferData(ctx.ARRAY_BUFFER, new Float32Array([0, 0, width, 0, 0, height, 0, height, width, 0, width, height]), ctx.STATIC_DRAW);
    ctx.enableVertexAttribArray(positionLocation);
    ctx.vertexAttribPointer(positionLocation, 2, ctx.FLOAT, false, 0, 0);
    const texCoordLocation = ctx.getAttribLocation(program, "a_texCoord");
    const fragmentBuffer = ctx.createBuffer();
    ctx.bindBuffer(ctx.ARRAY_BUFFER, fragmentBuffer);
    // Input UV coordinates for the two triangles that go from 0,0 - 1,1
    ctx.bufferData(ctx.ARRAY_BUFFER, new Float32Array([0.0, 0.0, 1.0, 0.0, 0.0, 1.0, 0.0, 1.0, 1.0, 0.0, 1.0, 1.0]), ctx.STATIC_DRAW);
    ctx.enableVertexAttribArray(texCoordLocation);
    ctx.vertexAttribPointer(texCoordLocation, 2, ctx.FLOAT, false, 0, 0);
    return { vertexBuffer, fragmentBuffer };
}
export function flushProgram(ctx, vertexBuffer, fragmentBuffer) {
    ctx.deleteBuffer(vertexBuffer);
    ctx.deleteBuffer(fragmentBuffer);
}
// Creates and binds a texture to the context
export function bindTexture(ctx) {
    const texture = ctx.createTexture();
    ctx.bindTexture(ctx.TEXTURE_2D, texture);
    ctx.texParameteri(ctx.TEXTURE_2D, ctx.TEXTURE_WRAP_S, ctx.CLAMP_TO_EDGE);
    ctx.texParameteri(ctx.TEXTURE_2D, ctx.TEXTURE_WRAP_T, ctx.CLAMP_TO_EDGE);
    ctx.texParameteri(ctx.TEXTURE_2D, ctx.TEXTURE_MIN_FILTER, ctx.NEAREST);
    ctx.texParameteri(ctx.TEXTURE_2D, ctx.TEXTURE_MAG_FILTER, ctx.NEAREST);
    return texture;
}
// Loads a texture from a canvas
export function loadCanvas(ctx, canvas) {
    ctx.viewport(0, 0, canvas.width, canvas.height);
    ctx.texImage2D(ctx.TEXTURE_2D, 0, ctx.RGBA, ctx.RGBA, ctx.UNSIGNED_BYTE, canvas);
}
export function executeShader(ctx) {
    // Draw the 6 points from two triangles
    ctx.drawArrays(ctx.TRIANGLES, 0, 6);
}
