export const selectItemFromSurface = ({ id, surface }) => {
    var _a, _b, _c, _d, _e;
    const itemReference = (_a = surface.itemReferences) === null || _a === void 0 ? void 0 : _a.find((item) => item.id === id);
    if (itemReference) {
        return { item: itemReference, itemType: "itemReference" };
    }
    const shape = (_b = surface.shapes) === null || _b === void 0 ? void 0 : _b.find((item) => item.id === id);
    if (shape) {
        return { item: shape, itemType: "shape" };
    }
    const image = (_c = surface.images) === null || _c === void 0 ? void 0 : _c.find((item) => item.id === id);
    if (image) {
        return { item: image, itemType: "image" };
    }
    const textArea = (_d = surface.textAreas) === null || _d === void 0 ? void 0 : _d.find((item) => item.id === id);
    if (textArea) {
        return { item: textArea, itemType: "textArea" };
    }
    const subpanel = (_e = surface.subpanels) === null || _e === void 0 ? void 0 : _e.find((item) => item.id === id);
    if (subpanel) {
        return { item: subpanel, itemType: "subpanel" };
    }
    throw Error(`No item was found for id: ${id}`);
};
