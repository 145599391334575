export function shapeValidation({ shape, decoTech }) {
    var _a;
    if (decoTech === "embroidery") {
        return { status: "fail", error: "Embroidery is not supported!" };
    }
    if (((_a = shape === null || shape === void 0 ? void 0 : shape.stroke) === null || _a === void 0 ? void 0 : _a.position) === "outer") {
        return { status: "fail", error: "Outer strokes are not supported." };
    }
    return { status: "pass" };
}
