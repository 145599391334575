var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { imageLayout } from "../image/Layout";
export function staticVideoLayout(_a) {
    return __awaiter(this, arguments, void 0, function* ({ video, parentBounds, experimentalOptions, overprints, referrer, previewType, colorPalette, }) {
        const image = convertVideoToStaticImage(video);
        return yield imageLayout({ image, parentBounds, experimentalOptions, overprints, referrer, previewType, colorPalette });
    });
}
function convertVideoToStaticImage(video) {
    return {
        id: video.id,
        position: video.position,
        printUrl: video.previewUrl,
        url: video.previewUrl,
        clipping: video.clipping,
        cropFractions: video.cropFractions,
        opacityMultiplier: video.opacityMultiplier,
        previewUrl: video.previewUrl,
        rotationAngle: video.rotationAngle,
        transforms: video.transforms,
    };
}
