import cloneDeep from "lodash.clonedeep";
import { Matrix } from "../../../utils/math/matrix";
import { parseColor } from "../../../utils/paint/Color";
import { parseMM, parsePercentage } from "../../../utils/unitHelper";
import { parseOverprints } from "../../helpers/Paint";
import { transformBoundingBox } from "../../helpers/Transform";
export function processFilters({ filterSpecification, operations, blackBox, originalPosition, colorPalette, }) {
    const state = new Map();
    state["SourceGraphic"] = {
        operations: operations,
        originalPosition: originalPosition,
        currentBlackBox: blackBox,
        currentTransform: Matrix.identity(),
    };
    filterSpecification.definitions.forEach((filter) => {
        let result;
        switch (filter.type) {
            case "blur":
                result = processBlur(filter, state);
                break;
            case "offset":
                result = processOffset(filter, state);
                break;
            case "dropShadow":
                result = processDropShadow(filter, state, colorPalette);
                break;
            case "scale":
                result = processScale(filter, state);
                break;
            case "mirror":
                result = processMirror(filter, state);
                break;
            default:
                throw new Error("Unsupported filter.");
        }
        state[filter.result] = result;
    });
    let totalOperations = [];
    let totalBounds = [];
    filterSpecification.output.result.forEach((alias) => {
        const outputOperations = state[alias].operations;
        // the transform that already exists within each operation is the standard glyph scale transform, that is to be applied
        // after the filters have taken effect.
        outputOperations.forEach((op) => {
            op.transform = Matrix.multiply(op.transform, state[alias].currentTransform);
        });
        totalOperations = totalOperations.concat(outputOperations);
        totalBounds = totalBounds.concat(state[alias].currentBlackBox);
    });
    return { operations: totalOperations, extraBounds: totalBounds };
}
function processBlur(filter, state) {
    var _a;
    const output = cloneDeep(state[filter.in]);
    const radius = parseMM((_a = filter.radius) !== null && _a !== void 0 ? _a : "0mm");
    output.operations.forEach((op) => {
        op.effects = [{ type: "blur", radius: radius }];
    });
    return output;
}
function processOffset(filter, state) {
    const output = cloneDeep(state[filter.in]);
    const dx = filter.dx === undefined ? 0 : parseMM(filter.dx);
    const dy = filter.dy === undefined ? 0 : parseMM(filter.dy);
    const translateMatrix = Matrix.translate(dx, dy);
    output.currentTransform = Matrix.multiply(output.currentTransform, translateMatrix);
    output.currentBlackBox = transformBoundingBox(output.currentBlackBox, translateMatrix);
    return output;
}
function processDropShadow(filter, state, colorPalette) {
    var _a, _b;
    const output = cloneDeep(state[filter.in]);
    const dx = filter.dx === undefined ? 0 : parseMM(filter.dx);
    const dy = filter.dy === undefined ? 0 : parseMM(filter.dy);
    const radius = parseMM((_a = filter.radius) !== null && _a !== void 0 ? _a : "0mm");
    const dropShadowFill = parseColor((_b = filter.color) !== null && _b !== void 0 ? _b : "", colorPalette);
    const overprints = parseOverprints(filter.overprints, colorPalette);
    const translateMatrix = Matrix.translate(dx, dy);
    const blurEffect = { type: "blur", radius: radius };
    output.operations.forEach((op) => {
        op.fill = dropShadowFill;
        op.overprints = overprints;
        op.stroke = undefined;
        op.effects = op.effects ? [...op.effects, blurEffect] : [blurEffect];
    });
    output.currentTransform = Matrix.multiply(output.currentTransform, translateMatrix);
    output.currentBlackBox = transformBoundingBox(output.currentBlackBox, translateMatrix);
    return output;
}
function processScale(filter, state) {
    var _a, _b;
    const output = cloneDeep(state[filter.in]);
    let refPoint = { x: 0, y: 0 };
    const xRelative = parsePercentage(filter.origin.x);
    const yRelative = parsePercentage(filter.origin.y);
    if (filter.origin.bounds === "blackbox") {
        refPoint = {
            x: output.currentBlackBox.left + output.currentBlackBox.width * xRelative,
            y: output.currentBlackBox.top + output.currentBlackBox.height * yRelative,
        };
    }
    const scaleMatrix = Matrix.scaleAboutPoint((_a = filter.scaleX) !== null && _a !== void 0 ? _a : 1, (_b = filter.scaleY) !== null && _b !== void 0 ? _b : 1, refPoint);
    output.currentTransform = Matrix.multiply(output.currentTransform, scaleMatrix);
    output.currentBlackBox = transformBoundingBox(output.currentBlackBox, scaleMatrix);
    return output;
}
function processMirror(filter, state) {
    const output = cloneDeep(state[filter.in]);
    let refPoint;
    if (filter.direction === "vertical") {
        refPoint = {
            x: output.currentBlackBox.left + output.currentBlackBox.width / 2,
            y: output.currentBlackBox.top + output.currentBlackBox.height,
        };
    }
    else {
        refPoint = {
            x: output.currentBlackBox.left + output.currentBlackBox.width,
            y: output.currentBlackBox.top + output.currentBlackBox.height / 2,
        };
    }
    const transform = Matrix.mirrorAboutPoint(filter.direction, refPoint);
    output.currentTransform = Matrix.multiply(output.currentTransform, transform);
    output.currentBlackBox = transformBoundingBox(output.currentBlackBox, transform);
    return output;
}
