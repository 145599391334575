export function textAreaValidation({ textArea, decoTech, textOptions, }) {
    if (decoTech === "embroidery") {
        return { status: "fail", error: "Embroidery is not supported!" };
    }
    if (!textOptions.rtextEnabled) {
        return { status: "fail", error: "rtext disabled through textoptions" };
    }
    if (textArea.curves !== undefined) {
        return { status: "fail", error: "Legacy-style text along a path is not supported!" };
    }
    if (textArea.effects !== undefined && textArea.effects.length > 0) {
        return { status: "fail", error: "Text effects are not supported" };
    }
    if (textArea.background !== undefined && !validateTextBackground(textArea.background)) {
        return { status: "fail", error: "Outer/Inner strokes are not supported on TextBackground." };
    }
    const textAreaWithContent = textArea;
    if (!textAreaWithContent) {
        const textAreaWithFields = textArea;
        for (const textField of textAreaWithFields.textFields) {
            if (!validateStroke(textField.stroke)) {
                return { status: "fail", error: "Outer strokes are not supported." };
            }
        }
    }
    else {
        for (const textContent of textAreaWithContent.content) {
            if (!validateTextContent(textContent)) {
                return { status: "fail", error: "Outer strokes are not supported." };
            }
        }
    }
    return { status: "pass" };
}
function validateTextBackground(textBackground) {
    if (textBackground.stroke !== undefined) {
        return validateStroke(textBackground.stroke);
    }
    return true;
}
function validateStroke(stroke) {
    if ((stroke === null || stroke === void 0 ? void 0 : stroke.position) === "outer" || (stroke === null || stroke === void 0 ? void 0 : stroke.position) === "inner") {
        return false;
    }
    return true;
}
function validateTextContent(textContent) {
    if (textContent) {
        if (textContent.type === undefined || textContent.type === "inline" || textContent.type === "listItem") {
            if (!validateStroke(textContent.stroke)) {
                return false;
            }
        }
        if (textContent.type === "list" || textContent.type === "listItem") {
            for (const listItem of textContent.content) {
                if (!validateTextContent(listItem)) {
                    return false;
                }
            }
        }
    }
    return true;
}
