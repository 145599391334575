export class HexagonCalculator {
    constructor(side, offset) {
        this.side = side;
        this.width = this.getHexWidth(side);
        this.height = this.getHexHeight(side);
        const xOffset = 0.75 * this.width + offset;
        const yOffset = this.height / 2 + offset / 2;
        const center = { x: xOffset, y: yOffset };
        this.centerPoints = [];
        this.centerPoints[0] = center; //center Hexagon
        this.centerPoints[1] = { x: center.x - xOffset, y: center.y - yOffset }; // top L 1/4 Hexagon
        this.centerPoints[2] = { x: center.x - xOffset, y: center.y + yOffset }; // bottom L 1/4 Hexagon
        this.centerPoints[3] = { x: center.x + xOffset, y: center.y - yOffset }; // top R 1/4 Hexagon
        this.centerPoints[4] = { x: center.x + xOffset, y: center.y + yOffset }; // bottom R 1/4 Hexagon
    }
    getHexHeight(side) {
        // h = 2 a cos30 = sqrt(3) a
        return side * HexagonCalculator.SquareRoot3;
    }
    getHexWidth(side) {
        return 2 * side;
    }
    getCenterPoints() {
        return this.centerPoints;
    }
}
// regular hexagon
HexagonCalculator.SquareRoot3 = Math.sqrt(3);
