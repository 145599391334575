export class RectangularSvgPathCreator {
    constructor(bound) {
        this.bound = bound;
    }
    createSvgPath() {
        return `M ${this.bound.left},${this.bound.top} h${this.bound.width} v${this.bound.height} h-${this.bound.width}`;
    }
}
export class HexagonSvgPathCreator {
    constructor(cX, cY, width, height) {
        const side = width / 2;
        const halfSide = side / 2;
        const halfHeight = height / 2;
        this.points = [];
        this.points.push({ x: cX + side, y: cY });
        this.points.push({ x: cX + halfSide, y: cY - halfHeight });
        this.points.push({ x: cX - halfSide, y: cY - halfHeight });
        this.points.push({ x: cX - side, y: cY });
        this.points.push({ x: cX - halfSide, y: cY + halfHeight });
        this.points.push({ x: cX + halfSide, y: cY + halfHeight });
    }
    createSvgPath() {
        let svgPath = `M ${this.points[0].x} ${this.points[0].y}  `;
        svgPath += `L ${this.points[1].x} ${this.points[1].y}  `;
        svgPath += `L ${this.points[2].x} ${this.points[2].y}  `;
        svgPath += `L ${this.points[3].x} ${this.points[3].y}  `;
        svgPath += `L ${this.points[4].x} ${this.points[4].y}  `;
        svgPath += `L ${this.points[5].x} ${this.points[5].y}  `;
        svgPath += "Z";
        return svgPath;
    }
}
