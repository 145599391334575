export function getDestinationAndSourceDimensions({ bounds, crop, image, }) {
    if (crop) {
        const cLeft = image.width * crop.left;
        const cTop = image.height * crop.top;
        const cRight = image.width * crop.right;
        const cBottom = image.height * crop.bottom;
        return {
            sx: cLeft,
            sy: cTop,
            sw: image.width - cRight - cLeft,
            sh: image.height - cBottom - cTop,
            dx: bounds.left,
            dy: bounds.top,
            dw: bounds.width,
            dh: bounds.height,
        };
    }
    return {
        sx: 0,
        sy: 0,
        sw: image.width,
        sh: image.height,
        dx: bounds.left,
        dy: bounds.top,
        dw: bounds.width,
        dh: bounds.height,
    };
}
