import { Matrix } from "../../utils/math/matrix";
import { transformBoundingBox } from "../helpers/Transform";
import { computeCenter, expandBoundingBox, getStrokeMultiplier, maximum } from "../../utils/boundingBox";
import { parseMM, toDegrees } from "../../utils/unitHelper";
const ROTATION_INT_THRESHOLD = 0.01;
/**
 *
 * @param param0 {
 *  boundingBox: the bounding box, also known as the selection box (in design stack terminology).
 *    Usually this is the same as tightBounds, with the exception for normal text areas.
 *  tightBounds: the tight bounds of the item in absolute coordinates.
 *  transform: This is the aggregated transform for an item for a document preview, not item preview.
 *  scaleTransform: The original explicit scale transform on an item
 * }
 * @returns
 *  itemPreviewTransform: The actual transform matrix that should be used for the item in an item preview.
 *  measurementData: The new measurementData components (To be explained later)
 */
export function getMeasurementData({ boundingBox, tightBounds, transform, scaleTransform, stroke, itemType, extraBounds, }) {
    var _a, _b;
    let augmentedTightBounds = Object.assign({}, tightBounds);
    if (stroke) {
        augmentedTightBounds = expandBoundingBox({ boundingBox: augmentedTightBounds, amount: parseMM((_a = stroke.thickness) !== null && _a !== void 0 ? _a : "0mm") / 2 });
    }
    augmentedTightBounds = maximum([augmentedTightBounds, ...(extraBounds !== null && extraBounds !== void 0 ? extraBounds : [])]);
    const baseCenter = computeCenter(boundingBox);
    baseCenter.x -= boundingBox.left;
    baseCenter.y -= boundingBox.top;
    // The matrix decomposition values. This is not very useful on its own but needs some extra work
    const decomposedValues = Matrix.decomposeAffineTransform(transform);
    // The signs of the scale values returned from decomposition does not always reflect the scale transform used in cimdoc items. So we will look at
    // the original scale transform to determine the sign.
    const previewScale = getCenterScaleValues(decomposedValues.scale, scaleTransform);
    // Due to how decomposition works, sometimes the scale sign and rotation angle may be 'swapped' (details omitted). This is to 'unswap' that behavior, by looking
    // at the original scale sign. previewScale does something similar.
    let previewBoxRotation = previewScale.x < 0 ? (toDegrees(decomposedValues.rotation) + 180) % 360 : toDegrees(decomposedValues.rotation);
    // for the sake of design experiences this should be rounded if possible
    if (Math.abs(Math.round(previewBoxRotation) - previewBoxRotation) >= ROTATION_INT_THRESHOLD) {
        previewBoxRotation = parseFloat(previewBoxRotation.toFixed(2));
    }
    else {
        previewBoxRotation = Math.round(previewBoxRotation);
    }
    // The final center of the bounding box, after transformation.
    const finalBoundingBoxCenter = getFinalLocationCenter(boundingBox, transform);
    const finalPreviewBoxCenter = getFinalLocationCenter(augmentedTightBounds, transform);
    // the transform that gets applied to the item preview. This contains the skew and scale components, and aligns the item to the top left.
    let itemPreviewTransform = getItemPreviewTransform(augmentedTightBounds, decomposedValues.skew, previewScale);
    if (itemType === "image") {
        const imageTightBounds = {
            left: 0,
            top: 0,
            width: tightBounds.width,
            height: tightBounds.height,
        };
        itemPreviewTransform = getItemPreviewTransform(imageTightBounds, decomposedValues.skew, previewScale);
    }
    const layoutBox = getPreviewLayoutBox(augmentedTightBounds, decomposedValues.skew, previewScale);
    // Note that the two below will be equivalent for certain items
    const boundingBoxRotated = getBoundingBox(boundingBox, finalBoundingBoxCenter, decomposedValues.skew, previewScale, previewBoxRotation);
    const previewBoxRotated = getPreviewBox(layoutBox, finalPreviewBoxCenter, previewBoxRotation);
    const strokePadding = parseMM((_b = stroke === null || stroke === void 0 ? void 0 : stroke.thickness) !== null && _b !== void 0 ? _b : "0mm") * getStrokeMultiplier(stroke, "curve");
    const cimdocSkew = {
        x: toDegrees(decomposedValues.skew.x),
        y: toDegrees(decomposedValues.skew.y),
    };
    return {
        itemPreviewTransform: itemPreviewTransform,
        measurementData: {
            layoutBox,
            boundingBox: boundingBoxRotated,
            previewBox: previewBoxRotated,
            skew: cimdocSkew,
            scale: previewScale,
            translation: {
                x: finalBoundingBoxCenter.x - (baseCenter.x - strokePadding),
                y: finalBoundingBoxCenter.y - (baseCenter.y - strokePadding),
            },
            rotation: parseFloat(toDegrees(decomposedValues.rotation).toFixed(5)),
            transform: decomposedValues.skew.x != 0 || decomposedValues.skew.y != 0
                ? getSkewTransformMatrix(finalBoundingBoxCenter, decomposedValues.rotation, decomposedValues.scale, decomposedValues.skew)
                : undefined,
        },
    };
}
export function getBoundingBox(boundingBox, finalCenter, skew, scale, rotation) {
    const boundingBoxCenter = computeCenter(boundingBox);
    let boundingBoxMatrix = Matrix.translate(-boundingBoxCenter.x, -boundingBoxCenter.y);
    boundingBoxMatrix = Matrix.multiply(boundingBoxMatrix, new Matrix(1, Math.tan(skew.y), Math.tan(skew.x), 1, 0, 0));
    boundingBoxMatrix = Matrix.multiply(boundingBoxMatrix, Matrix.scale(scale.x, scale.y));
    boundingBoxMatrix = Matrix.multiply(boundingBoxMatrix, Matrix.translate(boundingBoxCenter.x, boundingBoxCenter.y));
    const boundingBoxV2 = transformBoundingBox(boundingBox, boundingBoxMatrix);
    return {
        left: finalCenter.x - boundingBoxV2.width / 2,
        top: finalCenter.y - boundingBoxV2.height / 2,
        width: boundingBoxV2.width,
        height: boundingBoxV2.height,
        rotation: parseFloat(rotation.toFixed(5)),
    };
}
export function getPreviewBox(layoutBox, finalCenter, rotation) {
    const layoutBoxCenter = computeCenter(layoutBox);
    const previewBox = {
        left: layoutBox.left + (finalCenter.x - layoutBoxCenter.x),
        top: layoutBox.top + (finalCenter.y - layoutBoxCenter.y),
        width: layoutBox.width,
        height: layoutBox.height,
    };
    return Object.assign(Object.assign({}, previewBox), { rotation: parseFloat(rotation.toFixed(5)) });
}
export function getPreviewLayoutBox(tightBounds, skew, scale) {
    const itemPreviewTransform = getItemPreviewTransform(tightBounds, skew, scale);
    return Object.assign({}, transformBoundingBox(tightBounds, itemPreviewTransform));
}
/**
 * Compute the item preview transform which contains only the skew and scale components of the item's final transform.
 *
 * @param boundingBox the bounding box
 * @param skew skew component
 * @param scale scale component
 * @param finalCenter the center of the item (as in a document preview) after it has transformed
 * @returns
 */
export function getItemPreviewTransform(boundingBox, skew, scale) {
    const boundingBoxCenter = computeCenter(boundingBox);
    let boundingBoxMatrix = Matrix.translate(-boundingBoxCenter.x, -boundingBoxCenter.y);
    boundingBoxMatrix = Matrix.multiply(boundingBoxMatrix, new Matrix(1, Math.tan(skew.y), Math.tan(skew.x), 1, 0, 0));
    boundingBoxMatrix = Matrix.multiply(boundingBoxMatrix, Matrix.scale(scale.x, scale.y));
    boundingBoxMatrix = Matrix.multiply(boundingBoxMatrix, Matrix.translate(boundingBoxCenter.x, boundingBoxCenter.y));
    const boundingBoxV2 = transformBoundingBox(boundingBox, boundingBoxMatrix);
    boundingBoxMatrix = Matrix.multiply(boundingBoxMatrix, Matrix.translate(-boundingBoxV2.left, -boundingBoxV2.top));
    return boundingBoxMatrix;
}
export function getFinalLocationCenter(boundingBox, transform) {
    const transformedBox = transformBoundingBox(boundingBox, transform);
    return { x: transformedBox.left + transformedBox.width / 2, y: transformedBox.top + transformedBox.height / 2 };
}
export function getSkewTransformMatrix(finalCenter, rotation, scale, skew) {
    let skewMatrix = Matrix.identity();
    skewMatrix = Matrix.multiply(skewMatrix, Matrix.translate(-finalCenter.x, -finalCenter.y));
    skewMatrix = Matrix.multiply(skewMatrix, Matrix.rotation(-rotation));
    skewMatrix = Matrix.multiply(skewMatrix, Matrix.scale(1 / scale.x, 1 / scale.y));
    if (skew.x != 0) {
        skewMatrix = Matrix.multiply(skewMatrix, Matrix.skew(skew.x, "x"));
    }
    else if (skew.y != 0) {
        skewMatrix = Matrix.multiply(skewMatrix, Matrix.skew(skew.y, "y"));
    }
    skewMatrix = Matrix.multiply(skewMatrix, Matrix.scale(scale.x, scale.y));
    skewMatrix = Matrix.multiply(skewMatrix, Matrix.rotation(rotation));
    skewMatrix = Matrix.multiply(skewMatrix, Matrix.translate(finalCenter.x, finalCenter.y));
    return {
        a: skewMatrix.a,
        d: skewMatrix.d,
        c: skewMatrix.c,
        b: skewMatrix.b,
        x: `${skewMatrix.x}mm`,
        y: `${skewMatrix.y}mm`,
    };
}
function getCenterScaleValues(decomposedScale, scaleTransform) {
    if (!scaleTransform) {
        return decomposedScale;
    }
    return {
        x: scaleTransform.x < 0 ? -Math.abs(decomposedScale.x) : Math.abs(decomposedScale.x),
        y: scaleTransform.y < 0 ? -Math.abs(decomposedScale.y) : Math.abs(decomposedScale.y),
    };
}
