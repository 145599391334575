import { boundingBoxFromRectangle } from "../../utils/boundingBox";
import { mmToString, parseMM } from "../../utils/unitHelper";
export function getMeasurements({ textFields, textBlocks, listOrdinals, isNewParagraphTextBlock, textArea, result, fonts, textOptions, textOrientation, textPathData, }) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v;
    if (result.textBounds === null) {
        throw Error("No text bounds from the text engine!");
    }
    if (result.blackBoxBounds === null) {
        throw Error("No black box results from the text engine!");
    }
    const textBoundsX = (_b = (_a = result.textBounds) === null || _a === void 0 ? void 0 : _a.x) !== null && _b !== void 0 ? _b : 0;
    const textBoundsY = (_d = (_c = result.textBounds) === null || _c === void 0 ? void 0 : _c.y) !== null && _d !== void 0 ? _d : 0;
    // Build the initial black box from the engine
    const blackBox = {
        left: (_f = (_e = result.blackBoxBounds) === null || _e === void 0 ? void 0 : _e.x) !== null && _f !== void 0 ? _f : 0,
        top: (_h = (_g = result.blackBoxBounds) === null || _g === void 0 ? void 0 : _g.y) !== null && _h !== void 0 ? _h : 0,
        width: (_k = (_j = result.blackBoxBounds) === null || _j === void 0 ? void 0 : _j.width) !== null && _k !== void 0 ? _k : 0,
        height: (_m = (_l = result.blackBoxBounds) === null || _l === void 0 ? void 0 : _l.height) !== null && _m !== void 0 ? _m : 0,
    };
    return {
        actual: getActual(textOrientation, textArea.position, result.textBounds),
        snapBox: {
            left: blackBox.left - textBoundsX,
            top: blackBox.top - textBoundsY,
            width: (_p = (_o = result.textBounds) === null || _o === void 0 ? void 0 : _o.width) !== null && _p !== void 0 ? _p : 0,
            height: (_r = (_q = result.textBounds) === null || _q === void 0 ? void 0 : _q.height) !== null && _r !== void 0 ? _r : 0,
        },
        baselines: (_t = (_s = result.lineInfos) === null || _s === void 0 ? void 0 : _s.map((li) => li.baseline)) !== null && _t !== void 0 ? _t : [],
        caretLines: (textOptions === null || textOptions === void 0 ? void 0 : textOptions.caretLines) ? getCaretLines((_u = result.lineInfos) !== null && _u !== void 0 ? _u : [], textBlocks, listOrdinals, isNewParagraphTextBlock) : undefined,
        textBoundaries: (textOptions === null || textOptions === void 0 ? void 0 : textOptions.requestTextBoundaries) ? getTextBoundaries((_v = result.textBoundaries) !== null && _v !== void 0 ? _v : [], textBlocks, listOrdinals) : undefined,
        glyphRunOverrides: (textOptions === null || textOptions === void 0 ? void 0 : textOptions.glyphRunOverrides) ? getGlyphRunOverrides(result, textFields, fonts, textPathData) : undefined,
        rtextResult: (textOptions === null || textOptions === void 0 ? void 0 : textOptions.rtextResult) ? result : undefined,
        resizeFactor: result.resizeFactor,
        textBounds: result.textBounds ? boundingBoxFromRectangle(result.textBounds) : undefined,
        blackBoxBounds: result.blackBoxBounds ? boundingBoxFromRectangle(result.blackBoxBounds) : undefined,
    };
}
export function getActual(textOrientation, position, textBounds) {
    var _a, _b, _c, _d;
    const actual = { width: 0, height: 0 };
    if (textOrientation === "vertical") {
        actual.width = (_a = textBounds === null || textBounds === void 0 ? void 0 : textBounds.width) !== null && _a !== void 0 ? _a : 0;
        actual.height = parseMM(position.height);
    }
    else {
        actual.width = parseMM(position.width);
        actual.height = (_b = textBounds === null || textBounds === void 0 ? void 0 : textBounds.height) !== null && _b !== void 0 ? _b : 0;
    }
    // Actual dimensions should never be 0. In those cases, replace them with the appropriate dimensions from the text bounds.
    if (actual.width === 0) {
        actual.width = (_c = textBounds === null || textBounds === void 0 ? void 0 : textBounds.width) !== null && _c !== void 0 ? _c : 0;
    }
    if (actual.height === 0) {
        actual.height = (_d = textBounds === null || textBounds === void 0 ? void 0 : textBounds.height) !== null && _d !== void 0 ? _d : 0;
    }
    return actual;
}
export function getTextBoundaries(textBoundaries, textBlocks, listOrdinals) {
    var _a, _b, _c, _d;
    let contentLength = 0;
    // stores information about the ordinals and their lengths.
    const ordinalInfo = [];
    for (let i = 0; i < textBlocks.length; i++) {
        if (listOrdinals[i] && textBlocks[i].content && ((_a = textBlocks[i].content) === null || _a === void 0 ? void 0 : _a.length)) {
            ordinalInfo.push({
                index: contentLength,
                length: ((_b = textBlocks[i].content) !== null && _b !== void 0 ? _b : "").length,
            });
        }
        contentLength += (_d = (_c = textBlocks[i].content) === null || _c === void 0 ? void 0 : _c.length) !== null && _d !== void 0 ? _d : 0;
    }
    const outputTextBoundaries = [];
    for (let i = 0; i < textBoundaries.length; i++) {
        const ranges = textBoundaries[i].ranges;
        const outputRanges = [];
        let currentOrdinalIndex = 0;
        let ordinalLength = 0;
        for (let j = 0; j < ranges.length; j++) {
            const range = ranges[j];
            let start, end;
            // The boundaries after the last ordinal.
            if (!ordinalInfo[currentOrdinalIndex]) {
                start = range.start - ordinalLength;
                end = range.end - ordinalLength;
                outputRanges.push({ start, end });
            }
            // The boundaries within the ordinal - should be ignored.
            else if (range.start >= ordinalInfo[currentOrdinalIndex].index &&
                range.end <= ordinalInfo[currentOrdinalIndex].index + ordinalInfo[currentOrdinalIndex].length) {
                continue;
            }
            // Here we handle all cases, as boundaries could span multiple ordinals(probably?)
            else {
                if (range.start < ordinalInfo[currentOrdinalIndex].index) {
                    start = range.start - ordinalLength;
                }
                else if (range.start < ordinalInfo[currentOrdinalIndex].index + ordinalInfo[currentOrdinalIndex].length) {
                    start = ordinalInfo[currentOrdinalIndex].index - ordinalLength;
                }
                else {
                    start = range.start - ordinalLength - ordinalInfo[currentOrdinalIndex].length;
                }
                while (ordinalInfo[currentOrdinalIndex] && range.end > ordinalInfo[currentOrdinalIndex].index + ordinalInfo[currentOrdinalIndex].length) {
                    ordinalLength += ordinalInfo[currentOrdinalIndex].length;
                    currentOrdinalIndex++;
                }
                if (!ordinalInfo[currentOrdinalIndex] || range.end < ordinalInfo[currentOrdinalIndex].index) {
                    end = range.end - ordinalLength;
                }
                else {
                    end = ordinalInfo[currentOrdinalIndex].index - ordinalLength;
                }
                outputRanges.push({ start, end });
            }
        }
        outputTextBoundaries.push({
            type: textBoundaries[i].type,
            ranges: outputRanges,
        });
    }
    return outputTextBoundaries;
}
export function getCaretLines(caretPositions, textBlocks, listOrdinals, isNewParagraphTextBlock, blockFlowDirection) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    const caretLines = [];
    // Quick check if no text blocks exists
    if (textBlocks.length === 0)
        return caretLines;
    // Find first non-empty textBlock index
    let blockIndex = 0;
    let currentIndex = 0;
    let blockTextLength = (_b = (_a = textBlocks[blockIndex].content) === null || _a === void 0 ? void 0 : _a.length) !== null && _b !== void 0 ? _b : 0;
    // Track ordinals
    let ordinalBlockCount = 0;
    let paragraphTextBlockCount = 0;
    let ordinalCount = 0;
    let paragraphLength = 0;
    // Carets from rtext are given per line
    for (const line of caretPositions) {
        const spans = [];
        for (const span of (_c = line.spans) !== null && _c !== void 0 ? _c : []) {
            const carets = [];
            for (const caret of span.caretPositions) {
                const isLastCaretInTextBlock = caret.index - currentIndex - paragraphLength === blockTextLength;
                const isEmptyTextBlock = blockTextLength === 0;
                const isFirstCaret = caret.index === 0;
                const isLastTextBlock = blockIndex + 1 >= textBlocks.length;
                // Check if the caret is in the next text block
                if ((isLastCaretInTextBlock || isEmptyTextBlock) && !isFirstCaret && !isLastTextBlock) {
                    if (listOrdinals[blockIndex]) {
                        ordinalBlockCount++;
                    }
                    if (isNewParagraphTextBlock[blockIndex + 1]) {
                        paragraphTextBlockCount++;
                        paragraphLength += blockTextLength;
                    }
                    else if (isNewParagraphTextBlock[blockIndex]) {
                        currentIndex += paragraphLength + blockTextLength;
                        paragraphLength = 0;
                    }
                    else {
                        currentIndex += blockTextLength;
                    }
                    blockIndex++;
                    blockTextLength = (_e = (_d = textBlocks[blockIndex].content) === null || _d === void 0 ? void 0 : _d.length) !== null && _e !== void 0 ? _e : 0;
                    if (listOrdinals[blockIndex] && listOrdinals[blockIndex - 1]) {
                        while (blockIndex + 1 < textBlocks.length && listOrdinals[blockIndex]) {
                            ordinalBlockCount++;
                            currentIndex += blockTextLength;
                            blockIndex++;
                            blockTextLength = (_g = (_f = textBlocks[blockIndex].content) === null || _f === void 0 ? void 0 : _f.length) !== null && _g !== void 0 ? _g : 0;
                        }
                    }
                    else if (isEmptyTextBlock) {
                        while (blockIndex + 1 < textBlocks.length && blockTextLength === 0) {
                            if (isNewParagraphTextBlock[blockIndex + 1]) {
                                paragraphTextBlockCount++;
                            }
                            blockIndex++;
                            blockTextLength = (_j = (_h = textBlocks[blockIndex].content) === null || _h === void 0 ? void 0 : _h.length) !== null && _j !== void 0 ? _j : 0;
                        }
                        if (blockIndex + 1 < textBlocks.length && caret.index - currentIndex - paragraphLength === blockTextLength) {
                            if (isNewParagraphTextBlock[blockIndex + 1]) {
                                paragraphTextBlockCount++;
                                paragraphLength += blockTextLength;
                            }
                            else if (isNewParagraphTextBlock[blockIndex]) {
                                currentIndex += paragraphLength + blockTextLength;
                                paragraphLength = 0;
                            }
                            else {
                                currentIndex += blockTextLength;
                            }
                            blockIndex++;
                            blockTextLength = (_l = (_k = textBlocks[blockIndex].content) === null || _k === void 0 ? void 0 : _k.length) !== null && _l !== void 0 ? _l : 0;
                        }
                    }
                }
                // Assume last textBlock of ordinal is empty white space and return it as a normal caret to start the line
                const isOrdinal = listOrdinals[blockIndex] && blockTextLength !== 0;
                carets.push({
                    position: { x: caret.x, y: caret.y },
                    contentIndex: blockIndex - ordinalBlockCount - paragraphTextBlockCount,
                    isOrdinal,
                    relativeCharacterIndex: caret.index - currentIndex,
                    absoluteCharacterIndex: caret.index - ordinalCount,
                    rotation: (_m = caret.rotation) !== null && _m !== void 0 ? _m : (blockFlowDirection && blockFlowDirection !== "horizontal-tb" ? 0 : 270),
                });
                if (isOrdinal) {
                    ordinalCount++;
                }
            }
            spans.push({
                ascent: span.ascent,
                descent: span.descent,
                carets: carets,
            });
        }
        caretLines.push({
            baseline: line.baseline,
            spans,
        });
    }
    return caretLines;
}
export function getGlyphRunOverrides(result, textFields, fonts, textPathData) {
    var _a, _b, _c, _d;
    const sourceGlyphRuns = textPathData === undefined ? result.glyphRuns : textPathData.glyphRuns;
    const glyphRuns = [];
    for (const glyphRun of sourceGlyphRuns !== null && sourceGlyphRuns !== void 0 ? sourceGlyphRuns : []) {
        const textField = getTextFieldByBlockIndex(textFields, (_a = glyphRun.textBlockIndex) !== null && _a !== void 0 ? _a : 0);
        const font = fonts[(_b = glyphRun.fontIndex) !== null && _b !== void 0 ? _b : 0];
        glyphRuns.push({
            baseline: mmToString(glyphRun.baseline),
            color: textField.color,
            fontFamily: (_c = font.fontReference.fontFamily) !== null && _c !== void 0 ? _c : font.fontReference.url,
            fontSize: mmToString(glyphRun.fontSize),
            fontStyle: getFontStyle(textField, font.fontReference.fontStyle),
            isSideways: glyphRun.orientation === "vertical",
            overprints: textField.overprints,
            rotationAngle: `${glyphRun.glyphPivoting}`, // TODO change to number
            stroke: textField.stroke,
            fontVersion: font.fontReference.fontVersion,
            effects: textField.effects,
            decorations: textField.decorations,
            textBlockIndex: (_d = glyphRun.textBlockIndex) !== null && _d !== void 0 ? _d : -1, // This will always be present.
            glyphs: glyphRun.glyphs.map((g) => {
                var _a, _b, _c, _d;
                return {
                    index: g.index,
                    xOffset: mmToString((_a = g.xOffset) !== null && _a !== void 0 ? _a : 0),
                    yOffset: mmToString((_b = g.yOffset) !== null && _b !== void 0 ? _b : 0),
                    width: mmToString((_c = g.xAdvance) !== null && _c !== void 0 ? _c : 0),
                    letterSpacing: mmToString((_d = g.letterSpacing) !== null && _d !== void 0 ? _d : 0),
                };
            }),
        });
    }
    return glyphRuns;
}
function getFontStyle(textField, grStyle) {
    const isUnderline = textField.fontStyle.toLowerCase().includes("underline");
    const isStrikeout = textField.fontStyle.toLowerCase().includes("strikeout");
    let style = grStyle !== null && grStyle !== void 0 ? grStyle : "";
    if (isUnderline) {
        style += ",underline";
    }
    if (isStrikeout) {
        style += ",strikeout";
    }
    return style;
}
export function getDecorationBounds({ type, metrics, glyphRun, textAreaPosition, }) {
    var _a, _b, _c, _d, _e, _f;
    const baseline = glyphRun.baseline;
    const fontSize = glyphRun.fontSize;
    const lastGlyph = glyphRun.glyphs[glyphRun.glyphs.length - 1];
    const firstGlyphX = (_a = glyphRun.glyphs[0].xOffset) !== null && _a !== void 0 ? _a : 0;
    const lastGlyphX = ((_b = lastGlyph.xOffset) !== null && _b !== void 0 ? _b : 0) + ((_c = lastGlyph.xAdvance) !== null && _c !== void 0 ? _c : 0);
    const firstGlyphY = (_d = glyphRun.glyphs[0].yOffset) !== null && _d !== void 0 ? _d : 0;
    const glyphRunLength = lastGlyphX - firstGlyphX;
    const positionX = parseMM(textAreaPosition.x);
    const positionY = parseMM(textAreaPosition.y);
    if (type === "underline") {
        const thickness = getUnderlineThickness(metrics) * fontSize;
        const decorationStartY = -getUnderlineHeight(metrics) * fontSize + baseline - firstGlyphY;
        return {
            left: positionX + firstGlyphX - thickness / 2,
            top: positionY + decorationStartY - thickness / 2,
            width: glyphRunLength + thickness,
            height: thickness,
        };
    }
    else {
        const thickness = ((_e = metrics.strikeoutThickness) !== null && _e !== void 0 ? _e : 0) * fontSize;
        const decorationStartY = -((_f = metrics.strikeoutPosition) !== null && _f !== void 0 ? _f : 0) * fontSize + baseline - firstGlyphY;
        return {
            left: positionX + firstGlyphX - thickness / 2,
            top: positionY + decorationStartY - thickness / 2,
            width: glyphRunLength + thickness,
            height: thickness,
        };
    }
}
// Fonts can be missing underline data and need a default
export function getUnderlineHeight(metrics) {
    if (metrics.underlinePosition && metrics.underlinePosition !== 0) {
        return metrics.underlinePosition;
    }
    return -0.1;
}
// Fonts can be missing underline data and need a default
export function getUnderlineThickness(metrics) {
    if (metrics.underlineThickness && metrics.underlineThickness !== 0) {
        return metrics.underlineThickness;
    }
    return 0.1;
}
export function getTextFieldByBlockIndex(textFields, blockIndex) {
    if (blockIndex < textFields.length) {
        return textFields[blockIndex];
    }
    throw Error(`Text area does not contain block index: ${blockIndex}!`);
}
// Gets the extra bounding boxes from font metrics like strikeout or underline.
// Also contains augmented bounds for glyphs which have a stroke
export function computeExtraBounds({ textFields, result, fonts, textAreaPosition, }) {
    var _a, _b, _c, _d, _e;
    const extraBounds = [];
    for (const glyphRun of (_a = result.glyphRuns) !== null && _a !== void 0 ? _a : []) {
        const textField = getTextFieldByBlockIndex(textFields, (_b = glyphRun.textBlockIndex) !== null && _b !== void 0 ? _b : 0);
        const font = fonts[(_c = glyphRun.fontIndex) !== null && _c !== void 0 ? _c : 0];
        // Possible if requestOutlines = false (not sure why requestOutlines would ever be false since we always set it to true, unlike in the server)
        if (font.glyphs === undefined || glyphRun.glyphs.length === 0 || font.unitsPerEm === undefined)
            continue;
        // If the font contains metrics compute additional bounds information
        if (font.fontMetrics) {
            const metrics = font.fontMetrics;
            let decorations = [];
            if (textField.decorations !== undefined && textField.decorations.length > 0) {
                decorations = textField.decorations.slice();
            }
            // Treat legacy underline/strikeout as new definition
            else {
                if (((_d = textField.fontStyle) === null || _d === void 0 ? void 0 : _d.indexOf("strikeout")) >= 0 && metrics.strikeoutPosition !== undefined && metrics.strikeoutThickness !== undefined) {
                    decorations.push({ type: "strikeout" });
                }
                // Add underline
                if (((_e = textField.fontStyle) === null || _e === void 0 ? void 0 : _e.indexOf("underline")) >= 0) {
                    decorations.push({ type: "underline" });
                }
            }
            // Newer method of specifying decorations
            decorations.forEach((decoration) => {
                extraBounds.push(getDecorationBounds({ type: decoration.type, metrics, glyphRun, textAreaPosition }));
            });
        }
    }
    return extraBounds;
}
