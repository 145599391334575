const hasTextElements = (document) => {
    var _a;
    const surfaces = document.document.surfaces;
    const panels = document.document.panels;
    return ((_a = ((surfaces === null || surfaces === void 0 ? void 0 : surfaces.some((surface) => { var _a; return (_a = surface.simpleTextFields) === null || _a === void 0 ? void 0 : _a.length; })) ||
        (panels === null || panels === void 0 ? void 0 : panels.some((panel) => { var _a; return (_a = panel.simpleTextFields) === null || _a === void 0 ? void 0 : _a.length; })) ||
        (surfaces === null || surfaces === void 0 ? void 0 : surfaces.some((surface) => { var _a; return (_a = surface.textAreas) === null || _a === void 0 ? void 0 : _a.length; })) ||
        (panels === null || panels === void 0 ? void 0 : panels.some((panel) => { var _a; return (_a = panel.textAreas) === null || _a === void 0 ? void 0 : _a.length; })))) !== null && _a !== void 0 ? _a : false);
};
export function validateDocument({ document }) {
    var _a, _b;
    if (!((_a = document.document.surfaces) === null || _a === void 0 ? void 0 : _a.length) && !((_b = document.document.panels) === null || _b === void 0 ? void 0 : _b.length)) {
        return { status: "fail", error: "Input document must contain at least one surface or panel!" };
    }
    if (document.fontRepositoryUrl === undefined && hasTextElements(document)) {
        return { status: "fail", error: "A font repository must be defined!" };
    }
    if (document.sku !== undefined) {
        return { status: "fail", error: "Skus are not supported!" };
    }
    return { status: "pass" };
}
export function validateSurface({ surface }) {
    var _a, _b;
    if ((_a = surface.clips) === null || _a === void 0 ? void 0 : _a.length) {
        return { status: "fail", error: "Clips are not supported!" };
    }
    if ((_b = surface.simpleTextFields) === null || _b === void 0 ? void 0 : _b.length) {
        return { status: "fail", error: "Simple text fields are not supported!" };
    }
    return { status: "pass" };
}
