var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import svgpath from "svgpath";
import { computeBoundsFromPosition } from "../../utils/boundingBox";
import { parseMM } from "../../utils/unitHelper";
import { getClip } from "../helpers/Clip";
import { parseFill, parseOverprints } from "../helpers/Paint";
import { getLayoutStroke } from "../helpers/Stroke";
import { buildTransform } from "../helpers/Transform";
import { getMeasurementData } from "../measurements/measurementData";
const rectangleToPath = ({ x, y, width, height, cornerRadius }) => {
    let basePath;
    if (cornerRadius) {
        basePath = `M${cornerRadius},0 L${width - cornerRadius}, 0 A${cornerRadius} ${cornerRadius} 0 0 1 ${width} ${cornerRadius} L${width},${height - cornerRadius} A${cornerRadius} ${cornerRadius} 0 0 1 ${width - cornerRadius} ${height} L${cornerRadius}, ${height} A${cornerRadius} ${cornerRadius} 0 0 1 0 ${height - cornerRadius} L0,${cornerRadius} A${cornerRadius} ${cornerRadius} 0 0 1 ${cornerRadius} 0 Z`;
    }
    else {
        basePath = `M0,0H${width}V${height}H0Z`;
    }
    const translatedPath = svgpath(basePath).translate(x, y);
    return translatedPath.toString();
};
export function rectangleLayout(_a) {
    return __awaiter(this, arguments, void 0, function* ({ rectangle, parentBounds, options, previewType, }) {
        var _b, _c, _d, _e, _f, _g;
        const strokePadding = parseMM((_c = (_b = rectangle.stroke) === null || _b === void 0 ? void 0 : _b.thickness) !== null && _c !== void 0 ? _c : "0mm") / 2;
        const originalScaleTransform = rectangle.scale;
        const originalBoundingBox = computeBoundsFromPosition({ position: rectangle.position, strokePadding });
        const itemPreviewTransform = buildTransform({
            bounds: originalBoundingBox,
            skew: rectangle.skew,
            scale: rectangle.scale,
            rotationAngle: rectangle.rotationAngle,
            matrixTransform: rectangle.transform,
            itemTransforms: rectangle.transforms,
        });
        const bounds = computeBoundsFromPosition({ position: rectangle.position, strokePadding });
        let transform = buildTransform({
            bounds: bounds,
            skew: rectangle.skew,
            scale: rectangle.scale,
            rotationAngle: rectangle.rotationAngle,
            matrixTransform: rectangle.transform,
            itemTransforms: rectangle.transforms,
        });
        const clipTransform = buildTransform({ bounds, rotationAngle: rectangle.rotationAngle, itemTransforms: rectangle.transforms, translateToBounds: true });
        const clipPath = yield getClip(rectangle, parentBounds, clipTransform);
        const measurementDataResponse = getMeasurementData({
            boundingBox: bounds,
            tightBounds: bounds,
            transform: itemPreviewTransform,
            scaleTransform: originalScaleTransform,
            itemType: "shape",
        });
        if (previewType === "item") {
            transform = measurementDataResponse.itemPreviewTransform;
        }
        const renderingOperation = {
            type: "drawPaths",
            clip: clipPath,
            paths: [
                {
                    path: rectangleToPath({
                        x: parseMM(rectangle.position.x),
                        y: parseMM(rectangle.position.y),
                        width: parseMM(rectangle.position.width),
                        height: parseMM(rectangle.position.height),
                        cornerRadius: rectangle.cornerRadius ? parseMM(rectangle.cornerRadius) : 0,
                    }),
                    transform,
                    fill: yield parseFill(rectangle.color, {
                        definitionTreeNode: options.definitionTreeNode,
                        itemBounds: bounds,
                        colorPalette: options.colorPalette,
                        fontRepositoryUrl: options.fontRepositoryUrl,
                    }),
                    overprints: parseOverprints(rectangle.overprints, options.colorPalette),
                    stroke: yield getLayoutStroke(rectangle.stroke, {
                        definitionTreeNode: options.definitionTreeNode,
                        itemBounds: bounds,
                        colorPalette: options.colorPalette,
                    }),
                },
            ],
            opacityMultiplier: (_d = rectangle.opacityMultiplier) !== null && _d !== void 0 ? _d : 1,
        };
        return {
            id: rectangle.id,
            status: { mode: "local" },
            measurementData: {
                boundingBox: (_e = clipPath === null || clipPath === void 0 ? void 0 : clipPath.boundingBox) !== null && _e !== void 0 ? _e : measurementDataResponse.measurementData.boundingBox,
                previewBox: (_f = clipPath === null || clipPath === void 0 ? void 0 : clipPath.boundingBox) !== null && _f !== void 0 ? _f : measurementDataResponse.measurementData.previewBox,
                layoutBox: (_g = clipPath === null || clipPath === void 0 ? void 0 : clipPath.boundingBox) !== null && _g !== void 0 ? _g : measurementDataResponse.measurementData.layoutBox,
            },
            renderingOperation,
        };
    });
}
