var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { fetchWithNetworkCache } from "../../cache/network";
import { parseMM } from "../unitHelper";
import { fetchHTMLImageElement } from "./fetchImage";
const stringToBoolean = (value) => {
    if (value === "true") {
        return true;
    }
    if (value === "false") {
        return false;
    }
    return undefined;
};
const responseResolver = (r) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    const response = r;
    const blob = yield response.clone().blob();
    const headers = response.headers;
    if ((_a = headers.get("content-type")) === null || _a === void 0 ? void 0 : _a.includes("svg")) {
        const svg = yield blob.text();
        return {
            status: 200,
            svg,
            xRenderMetadataHeader: JSON.parse(headers.get("x-render-metadata")),
            xItemReferenceMetadataHeader: JSON.parse(headers.get("x-itemreference-metadata")),
            xItemReferenceUseSlotDimensionsHeader: stringToBoolean(headers.get("x-itemreference-useslotdimensions")),
            type: "svg",
        };
    }
    // createImageBitmap is not supported in all browsers
    if (typeof createImageBitmap !== "undefined") {
        const image = yield createImageBitmap(blob);
        return {
            status: 200,
            image,
            xRenderMetadataHeader: JSON.parse(headers.get("x-render-metadata")),
            xItemReferenceMetadataHeader: JSON.parse(headers.get("x-itemreference-metadata")),
            xItemReferenceUseSlotDimensionsHeader: stringToBoolean(headers.get("x-itemreference-useslotdimensions")),
            type: "bitmap",
        };
    }
    // create local url to make sure the same request url is not fetched again with different headers
    const objectURL = URL.createObjectURL(blob);
    const image = yield fetchHTMLImageElement({ url: objectURL });
    URL.revokeObjectURL(objectURL);
    return {
        status: 200,
        image,
        xRenderMetadataHeader: JSON.parse(headers.get("x-render-metadata")),
        xItemReferenceMetadataHeader: JSON.parse(headers.get("x-itemreference-metadata")),
        xItemReferenceUseSlotDimensionsHeader: stringToBoolean(headers.get("x-itemreference-useslotdimensions")),
        type: "HTMLImageElement",
    };
});
export const fetchImage = (_a) => __awaiter(void 0, [_a], void 0, function* ({ url, formData }) {
    // Local image processing (use native browser image layout)
    if (!url.startsWith("http")) {
        const img = yield fetchWithNetworkCache({ url, fetchOptions: { fetchAsImage: true } });
        return {
            status: 200,
            image: img,
            type: "HTMLImageElement",
        };
    }
    return formData
        ? yield fetchWithNetworkCache({ url, init: { method: "POST", body: formData }, responseResolver })
        : yield fetchWithNetworkCache({ url, responseResolver });
});
export const loadSvgAsImage = (_a) => __awaiter(void 0, [_a], void 0, function* ({ svg }) {
    const localUrl = URL.createObjectURL(new Blob([svg], { type: "image/svg+xml" }));
    const image = yield fetchHTMLImageElement({ url: localUrl });
    URL.revokeObjectURL(localUrl);
    return image;
});
export const fallbackBoxToBoundingBox = (box) => {
    return {
        left: parseMM(box.left),
        top: parseMM(box.top),
        width: parseMM(box.width),
        height: parseMM(box.height),
    };
};
