// Ported from https://gitlab.com/Cimpress-Technology/DocDesign/Document/doc.instructions/-/blob/master/src/Doc.Instructions.Domain/Text/TextAlongAPath/PiecewisePath.cs
import { distance } from "../../../utils/math/geometry";
import { binarySearch } from "./binarySearch";
import { parseMM } from "../../..";
import { maximum } from "../../../utils/boundingBox";
import { Vector2 } from "../../../utils/math/Vector2";
export class PiecewisePath {
    getPoint(t) {
        if (t < 0 || t > 1) {
            throw Error("invalid t value");
        }
        let index = binarySearch(this.positionedVectors, t, (i, v) => i - v.position);
        if (index < 0) {
            index = -index - 2;
        }
        const nextOffset = index + 1 === this.positionedVectors.length ? 1 : this.positionedVectors[index + 1].position;
        const tDifference = t - this.positionedVectors[index].position;
        const segment = this.positionedVectors[index];
        const point = getPointOnSegment(segment, tDifference / (nextOffset - this.positionedVectors[index].position));
        return { point, theta: vectorTheta(segment) };
    }
    getPathInclusiveBounds(calculationResultCollection, position) {
        const augmentedBounds = [];
        const results = calculationResultCollection.results;
        let maxAscent = Number.MIN_VALUE;
        let minDescent = Number.MAX_VALUE;
        results.forEach((result) => {
            var _a;
            ((_a = result.lineInfos) !== null && _a !== void 0 ? _a : []).forEach((lineInfo) => {
                const baseline = lineInfo.baseline;
                const textBounds = lineInfo.textBounds;
                maxAscent = Math.max(maxAscent, baseline - textBounds.y);
                minDescent = Math.min(minDescent, baseline - textBounds.height - textBounds.y);
            });
        });
        this.positionedVectors.forEach((vector) => {
            const theta = vectorTheta(vector) + Math.PI / 2;
            const unitVector = new Vector2(Math.cos(theta), Math.sin(theta));
            const start = vector.start;
            const end = vector.end;
            const startAscent = {
                x: start.x - unitVector.a * maxAscent,
                y: start.y - unitVector.b * maxAscent,
            };
            const startDescent = {
                x: start.x - unitVector.a * minDescent,
                y: start.y - unitVector.b * minDescent,
            };
            const endAscent = {
                x: end.x - unitVector.a * maxAscent,
                y: end.y - unitVector.b * maxAscent,
            };
            const endDescent = {
                x: end.x - unitVector.a * minDescent,
                y: end.y - unitVector.b * minDescent,
            };
            const points = [startAscent, startDescent, endAscent, endDescent];
            const left = Math.min(...points.map((p) => p.x));
            const top = Math.min(...points.map((p) => p.y));
            const right = Math.max(...points.map((p) => p.x));
            const bottom = Math.max(...points.map((p) => p.y));
            const boundingBox = {
                left: left + parseMM(position.x),
                top: top + parseMM(position.y),
                width: right - left,
                height: bottom - top,
            };
            augmentedBounds.push(boundingBox);
        });
        return maximum(augmentedBounds);
    }
    constructor({ path }) {
        const vectors = segmentCurves({ path });
        let totalLength = 0;
        const positionedVectors0 = vectors.map((vector) => {
            const result = Object.assign(Object.assign({}, vector), { position: totalLength });
            totalLength += vector.length;
            return result;
        });
        this.positionedVectors = positionedVectors0.map((vector) => (Object.assign(Object.assign({}, vector), { position: vector.position / totalLength })));
        this.length = totalLength;
    }
}
const vectorTheta = (vector) => {
    const dbl = Math.atan2(vector.end.y - vector.start.y, vector.end.x - vector.start.x);
    return modulo(dbl, Math.PI * 2);
};
const modulo = (a, b) => a - b * Math.floor(a / b);
const getPointOnSegment = (segment, t) => {
    const x = segment.start.x * (1 - t) + segment.end.x * t;
    const y = segment.start.y * (1 - t) + segment.end.y * t;
    return { x, y };
};
const CURVE_SEGMENT_COUNT = 200;
const CURVE_SEGMENT_IDS = [...Array(CURVE_SEGMENT_COUNT).keys()].map((i) => i + 1);
const segmentCurves = ({ path }) => {
    let lastStartingPosition = { x: 0, y: 0 };
    const output = [];
    path
        .abs() // Replace all relative (lower case) path commands with absolute (upper case) path commands
        .unarc() // Replace all arch path commands (A) with curves (C or Q)
        .unshort() // Replace all shortcut command (T and S) with curves (C or Q)
        .iterate((segments, _, x, y) => {
        switch (segments[0]) {
            case "M": {
                lastStartingPosition = { x, y };
                break;
            }
            case "Z": {
                const endPoint = lastStartingPosition;
                output.push(makePathSegment({ x, y }, endPoint));
                break;
            }
            case "L": {
                const endPoint = { x: segments[1], y: segments[2] };
                output.push(makePathSegment({ x, y }, endPoint));
                break;
            }
            case "V": {
                const endPoint = { x, y: segments[1] };
                output.push(makePathSegment({ x, y }, endPoint));
                break;
            }
            case "H": {
                const endPoint = { x: segments[1], y };
                output.push(makePathSegment({ x, y }, endPoint));
                break;
            }
            case "C": {
                const curveStartPoint = { x, y };
                let currentPoint = curveStartPoint;
                output.push(...CURVE_SEGMENT_IDS.map((i) => {
                    const t = i / CURVE_SEGMENT_COUNT;
                    const x1 = getPositionOnCubicCurve(t, curveStartPoint.x, segments[1], segments[3], segments[5]);
                    const y1 = getPositionOnCubicCurve(t, curveStartPoint.y, segments[2], segments[4], segments[6]);
                    const nextPoint = { x: x1, y: y1 };
                    const result = makePathSegment(currentPoint, nextPoint);
                    currentPoint = nextPoint;
                    return result;
                }));
                break;
            }
            case "Q": {
                const curveStartPoint = { x, y };
                let currentPoint = curveStartPoint;
                output.push(...CURVE_SEGMENT_IDS.map((i) => {
                    const t = i / CURVE_SEGMENT_COUNT;
                    const x1 = getPositionOnQuadraticCurve(t, curveStartPoint.x, segments[1], segments[3]);
                    const y1 = getPositionOnQuadraticCurve(t, curveStartPoint.y, segments[2], segments[4]);
                    const nextPoint = { x: x1, y: y1 };
                    const result = makePathSegment(currentPoint, nextPoint);
                    currentPoint = nextPoint;
                    return result;
                }));
                break;
            }
            default:
                throw Error("Invalid SVG path character");
        }
    });
    return output;
};
const getPositionOnCubicCurve = (t, start, control1, control2, end) => {
    if (t < 0 || t > 1) {
        throw new Error("Invalid t for cubic curve");
    }
    const k = 1 - t;
    return start * k * k * k + 3.0 * control1 * k * k * t + 3.0 * control2 * k * t * t + end * t * t * t;
};
const getPositionOnQuadraticCurve = (t, start, control, end) => {
    if (t < 0 || t > 1) {
        throw new Error("Invalid t for quadratic curve");
    }
    const k = 1 - t;
    return start * k * k + 2.0 * control * k * t + end * t * t;
};
const makePathSegment = (start, end) => {
    const length = distance(end, start);
    return { start, end, length };
};
