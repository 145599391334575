var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { selectSurface } from "./selectors/selectSurface";
import { parseMM } from "./utils/unitHelper";
export function determinePixelSizeByDimension(_a) {
    return __awaiter(this, arguments, void 0, function* ({ dimension, selector, cimDoc }) {
        const surface = yield selectSurface({ selector, document: cimDoc });
        const surfaceWidth = parseMM(surface.width);
        const surfaceHeight = parseMM(surface.height);
        return `${Math.max(surfaceWidth, surfaceHeight) / dimension}mm`;
    });
}
export function determinePixelSizeByWidth(_a) {
    return __awaiter(this, arguments, void 0, function* ({ width, selector, cimDoc }) {
        const surface = yield selectSurface({ selector, document: cimDoc });
        const surfaceWidth = parseMM(surface.width);
        return `${surfaceWidth / width}mm`;
    });
}
export function determinePixelSizeByHeight(_a) {
    return __awaiter(this, arguments, void 0, function* ({ height, selector, cimDoc }) {
        const surface = yield selectSurface({ selector, document: cimDoc });
        const surfaceHeight = parseMM(surface.height);
        return `${surfaceHeight / height}mm`;
    });
}
