var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { curveLayout } from "./Curve";
import { ellipseLayout } from "./Ellipse";
import { lineLayout } from "./Line";
import { rectangleLayout } from "./Rectangle";
export function shapeLayout(_a) {
    return __awaiter(this, arguments, void 0, function* ({ shape, parentBounds, options, previewType, }) {
        switch (shape.type) {
            case "rectangle":
                return yield rectangleLayout({ rectangle: shape, parentBounds, options, previewType });
            case "ellipse":
                return yield ellipseLayout({ ellipse: shape, parentBounds, options, previewType });
            case "line":
                return yield lineLayout({ line: shape, parentBounds, options, previewType });
            case "curve":
                return yield curveLayout({ curve: shape, parentBounds, options, previewType });
        }
    });
}
