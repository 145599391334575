import { fallbackBoxToBoundingBox } from "../../utils/api/image";
import { parseMM } from "../../utils/unitHelper";
export function parsePreviewMetadata(xRenderMetadataHeader) {
    const serverPreviewBox = {
        left: parseMM(xRenderMetadataHeader.previewBox.left),
        height: parseMM(xRenderMetadataHeader.previewBox.height),
        top: parseMM(xRenderMetadataHeader.previewBox.top),
        width: parseMM(xRenderMetadataHeader.previewBox.width),
    };
    let actual, pathInclusiveBox;
    if (xRenderMetadataHeader.actual !== undefined) {
        actual = {
            width: parseMM(xRenderMetadataHeader.actual.width),
            height: parseMM(xRenderMetadataHeader.actual.height),
        };
    }
    if (xRenderMetadataHeader.pathInclusiveBox !== undefined) {
        pathInclusiveBox = fallbackBoxToBoundingBox(xRenderMetadataHeader.pathInclusiveBox);
    }
    const previewMetadata = {
        previewBox: serverPreviewBox,
        pathInclusiveBox,
        actual,
    };
    return previewMetadata;
}
export function parseTextMeasurements(xRenderMetadataHeader) {
    var _a;
    // Normal text always has non-empty baselines, actual, snapBox, and may have resizeFactor, but text along a path will always have null baselines
    // and potentially a resizeFactor. No other cimdoc item besides text has a resizeFactor, so only text will pass this condition.
    if (xRenderMetadataHeader.baselines || xRenderMetadataHeader.resizeFactor) {
        const textMeasurements = {};
        if (xRenderMetadataHeader.actual) {
            textMeasurements.actual = {
                width: parseMM(xRenderMetadataHeader.actual.width),
                height: parseMM(xRenderMetadataHeader.actual.height),
            };
        }
        if (xRenderMetadataHeader.snapBox) {
            textMeasurements.snapBox = {
                left: parseMM(xRenderMetadataHeader.snapBox.left),
                top: parseMM(xRenderMetadataHeader.snapBox.top),
                width: parseMM(xRenderMetadataHeader.snapBox.width),
                height: parseMM(xRenderMetadataHeader.snapBox.height),
            };
        }
        textMeasurements.textBounds = fallbackBoxToBoundingBox(xRenderMetadataHeader.textBounds);
        textMeasurements.blackBoxBounds = fallbackBoxToBoundingBox(xRenderMetadataHeader.blackBoxBounds);
        textMeasurements.baselines = (_a = xRenderMetadataHeader.baselines) === null || _a === void 0 ? void 0 : _a.map((b) => parseMM(b));
        textMeasurements.resizeFactor = xRenderMetadataHeader.resizeFactor;
        return textMeasurements;
    }
}
